import '../OZCheckbox/OZCheckbox.css';

type Props = {
	id: any;
	label: string;
	checked: boolean | undefined;
	item?: any;
	onChange: any;
	group?: string;
	disabled?: boolean | false;
};

export default function OZCheckbox({ id, checked, label, onChange, item, group, disabled }: Props) {
	const handleChange = (data: any) => {
		onChange(data);
	};
	return (
		<div className="custom-checkbox">
			<input
				type="checkbox"
				id={group ? group + id : id}
				checked={checked}
				disabled={disabled}
				onChange={(e) => {
					handleChange({
						target: {
							value: { ...item, id, label, group },
							checked: e.target.checked
						}
					});
				}}
			/>
			<label htmlFor={group ? group + id : id}>{label}</label>
		</div>
	);
}
