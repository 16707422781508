import { Formik } from "formik";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import { ScrollToFieldError } from "../../../components/admin/common/ScrollToFieldError";
import * as yup from 'yup';
import OZInput from "../../../components/admin/common/OZInput";
import { addsubadmin, getDialerMappingList, getMapToServerList, getPartnerLevelDetails, getPartnerLevelForUser, getPlans, getSipBargeExtensionDropdown, gettimezoneDropdown, getUserLoggedIn, updatesubadminById } from "../../../services/api-service";
import { useEffect, useMemo, useState } from "react";
import { showErrorNotification, showSuccessNotification, showWarningNotification } from "../../../components/admin/common/NotiService";
import OZSelect from "../../../components/admin/common/OZSelect";
import OZFCheckbox from "../../../components/admin/common/OZFCheckbox";
import OZAutocompletes from "../../../components/admin/common/AutoComplete/OZAutocompleteSubadmin";
import { checkRoles } from "../../../utils/util";
import SimpleSwitch from "../../../components/admin/common/OZSwitch/SimpleSwitch";

const AddUser = (props) => {
  let styleFieldHeader = useMemo(
    () => ({ fontSize: "14px", fontWeigth: 400, marginLeft: '1%', marginTop: '-0.5%' }),
    []);

  const [loading, setLoading] = useState(false);
  const [disableForm, setDisableForm] = useState(props?.mode != ADMIN_FORM_MODES.add || false);
  const [isUpdatePassword, setIsUpdatePassword] = useState(false);
  let [updatedRowData, setUpdatedRowData] = useState({});
  const displayMode = [{ screenPopMode: 1, displayCRMIn: "Popup Mode" }, { screenPopMode: 2, displayCRMIn: 'Embed Mode' }, { screenPopMode: 3, displayCRMIn: "Open in New Tab" }]


  async function setUpdatedData(data) {
    let updatedData = structuredClone(data);
    if (props?.mode !== ADMIN_FORM_MODES.add) {
      updatedData.confirmPassword = data.password
      if (data.userTimezone) {
        updatedData.userTimezone = { name: data.userTimezone }
      }
      if (data?.appPlanUser?.appPlan) {
        updatedData.appPlanUser = data?.appPlanUser?.appPlan
      }
      displayMode.forEach(item => {
        if (item.screenPopMode === data.screenPopMode) {
          updatedData.screenPopMode = item;
        }
      });
      const partnerLevelData = await getPartnerLevelForUser(data.username);
      updatedData.partnerLevel = 'L' + String(partnerLevelData?.Data[0]?.partnerLevel || 0);
      updatedData.partnerName = partnerLevelData?.Data[0]?.parentAccount?.accountName || data?.account?.accountName;
      setUpdatedRowData(updatedData);
    }
  };

  useEffect(() => {
    setUpdatedData(props.rowData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowData]);

  if (updatedRowData) {
    displayMode.forEach(item => {
      if (item.screenPopMode === updatedRowData.screenPopMode) {
        updatedRowData.screenPopMode = item;
      }
    });
  };

  const DataForm = (props) => {
    const [valid, setValid] = useState(true);
    const [l1Partner, setL1Partner] = useState(null);
    const [l2Partner, setL2Partner] = useState(null);
    const [l3Partner, setL3Partner] = useState(null);
    const [partnerLevel, setPartnerLevel] = useState('L1');
    const [l1PartnerData, setL1PartnerData] = useState([]);
    const [l2PartnerData, setL2PartnerData] = useState([]);
    const [l3PartnerData, setL3PartnerData] = useState([]);
    const [planListData, setPlanListData] = useState([]);
    const [dialerMapData, setDialerMapData] = useState([]);
    const [mapToServerData, setMapToServerData] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [sipBargeExtensionData, setSipBargeExtensionData] = useState([]);

    const displayMode = [{ screenPopMode: 1, displayCRMIn: "Popup Mode" }, { screenPopMode: 2, displayCRMIn: 'Embed Mode' }, { screenPopMode: 3, displayCRMIn: "Open in New Tab" }]


    useEffect(() => {
      getPlanListData();
      getTimeZone();
      getMapToServerData();
      getDialerMappingData();
      getPartnerLevel();
      if (checkRoles("ROLE_ADMIN")) {
        getSipBargeExtensionOptions();
      }
    }, [])

    async function getPlanListData() {
      try {
        getPlans()
          .then((resp) => {
            setPlanListData(resp.Data);
          })
          .catch((e) => {
            setPlanListData([]);
          });
      } catch (e) {
        setPlanListData([]);
        showErrorNotification("Error in profile list");
      }
    };

    async function getSipBargeExtensionOptions() {
      try {
        getSipBargeExtensionDropdown()
          .then((resp) => {
            const extractedValues = resp.Data.map(item => item.name);
            setSipBargeExtensionData(extractedValues);
          })
          .catch((e) => {
            setSipBargeExtensionData([]);
          });
      } catch (e) {
        showErrorNotification("Error in Sip Barge Extension list");
      }
    }

    async function getMapToServerData() {
      try {
        getMapToServerList()
          .then((resp) => {
            setMapToServerData(resp.Data);
          })
          .catch((e) => {
            setMapToServerData([]);
          });
      } catch (e) {
        setMapToServerData([]);
        showErrorNotification("Error in map to server list");
      }
    };

    async function getDialerMappingData() {
      try {
        getDialerMappingList()
          .then((resp) => {
            const keysArray = Object.keys(resp?.Data) || [];
            setDialerMapData(keysArray || []);
          })
          .catch((e) => {
            setDialerMapData([]);
          });
      } catch (e) {
        setDialerMapData([]);
        showErrorNotification("Error in Dialer Mapping list");
      }
    };

    async function getTimeZone() {
      try {
        gettimezoneDropdown()
          .then((resp) => {
            setCountryList(resp.Data);
          })
          .catch((e) => {
            setCountryList([]);
          });
      } catch (e) {
        showErrorNotification("Error in timezone Dropdown list");
      }
    };

    async function getPartnerLevel(accountId, level) {
      let partnerLevelResp = [];
      let accountIdInternal = accountId;
      try {
        if (!accountId) {
          accountIdInternal = await getUserLoggedIn().then((resp) => {
            return resp?.Data[0]?.id;
          });
        }

        const resp1 = {
          "Status": "Success",
          "Message": "Success",
          "Data": [
            {
              "accountId": 3,
              "accountName": "preprodl2",
              "partnerLevel": 2,
              "email": "manish@ozonetel.com",
              "imageLogo": null,
              "parentAccount": {
                "accountId": 2,
                "accountName": "preprodl1",
                "partnerLevel": 1
              },
              "favIcon": null,
              "partnerHost": null,
              "primaryColor": null,
              "secondaryColor": null
            }
          ]
        };
        partnerLevelResp = resp1?.Data || [];
        partnerLevelResp = await getPartnerLevelDetails(accountIdInternal)
          .then((resp) => {
            const extractedValues = resp?.Data || [];
            return extractedValues;
          })
          .catch((e) => {
            return [];
        });
      } catch (e) {
        showErrorNotification("Error in Partner Level list");
      }

      if (!accountId) {
        setL1PartnerData(partnerLevelResp);
      } else {
        if (level === 'L2') {
          setL2PartnerData(partnerLevelResp);
        } else if (level === 'L3') {
          setL3PartnerData(partnerLevelResp);
        }
      }
    };

    async function addUser(data) {
      setLoading(true);
      try {
        let finalData = structuredClone(data);
        switch (finalData.screenPopMode) {
          case "Popup Mode":
            finalData.screenPopMode = 1
            break;
          case "Embed Mode":
            finalData.screenPopMode = 2
            break;
          case "Open in New Tab":
            finalData.screenPopMode = 3
            break;
          default: showErrorNotification(`screenPopMode isn't selected`);
        }
        finalData.userTimezone = data.userTimezone.name;
        if (data.appPlanUser) {
          // Removing `createdDate` and `updatedDate` from `appPlan`
          delete data.appPlanUser.createdDate;
          delete data.appPlanUser.updatedDate;
        }
        finalData.appPlanUser = { appPlan: data.appPlanUser, id: 1 };
        finalData.accountUser = data.partner;

        let accountData = data.l1partner;
        switch (partnerLevel) { 
          case 'L3':
            accountData = data.l2partner;
            break;
          case 'L4':
            accountData = data.l3partner;
            break;
          default:
            break;
        }
        finalData.account = accountData;
        addsubadmin(finalData)
          .then((resp) => {
            if (resp.Status === "Success") {
              showSuccessNotification(`User ${finalData.username} Created Successfully`);
              props.onClose();
              props.updateFunction();
              setLoading(false);
              // console.log(JSON.stringify(finalData));
            }
            else {
              showWarningNotification(resp.Message.allowedIp || resp.Message.username || resp.Message.firstName || resp.Message.lastName || resp.Message.email || resp.Message.screenPopMode || resp.Message.chatApiKey || resp.Message.callsApiKey || resp.Message.extension || resp.Message.phoneNumber ||
                resp.Message.apiKey || resp.Message.city || resp.Message.province || resp.Message.postalCode || resp.Message.country || resp.Message.userTimezone || resp.Message.urlMap || resp.Message.serverTimezone || resp.Message.dialerMapping || resp.Message.roles || resp.Message);
            }
          })
          .catch((e) => {
            showErrorNotification("Error adding user");
          });
      } catch (e) {
        showErrorNotification("Error adding user");
      }
      setLoading(false);
    };

    async function updateUser(data) {
      setLoading(true);
      try {
        let finalData = structuredClone(data);
        finalData.screenPopMode = data.screenPopMode.screenPopMode;
        finalData.userTimezone = data.userTimezone.name;
        let isPasswordUpdate = false;
        if (props?.rowData?.password != data?.password) {
          isPasswordUpdate = true;
        }
        finalData.is_update = isPasswordUpdate;
        if (data.appPlanUser) {
          // Removing `createdDate` and `updatedDate` from `appPlan`
          delete data.appPlanUser.createdDate;
          delete data.appPlanUser.updatedDate;
        }
        if (!props?.rowData?.appPlanUser) {
          finalData.appPlanUser = { appPlan: data.appPlanUser, id: 1 };
        } else {
          finalData.appPlanUser = props.rowData.appPlanUser;
          finalData.appPlanUser.appPlan = data.appPlanUser;
        }
        updatesubadminById(finalData)
          .then((resp) => {
            if (resp.Status === "Success") {
              showSuccessNotification(`User ${finalData.username} Updated Successfully`);
              props.onClose();
              props.updateFunction();
            }
            else {
              showWarningNotification(resp.Message.allowedIp || resp.Message.username || resp.Message.firstName || resp.Message.lastName || resp.Message.email || resp.Message.screenPopMode || resp.Message.chatApiKey || resp.Message.callsApiKey || resp.Message.extension || resp.Message.phoneNumber ||
                resp.Message.apiKey || resp.Message.city || resp.Message.province || resp.Message.postalCode || resp.Message.country || resp.Message.userTimezone || resp.Message.urlMap || resp.Message.serverTimezone || resp.Message.dialerMapping || resp.Message.roles || resp.Message);
            }
          })
          .catch((e) => {
            showErrorNotification("Error updating user");
          });
      }
      catch (e) {
        showErrorNotification("Error updating user");
      }
      setLoading(false);
    };

    const onClickEdit = () => {
      setDisableForm(false);
    }

    const onClickDiscardEdit = () => {
      setDisableForm(true);
    }

    let initData = (props?.mode != ADMIN_FORM_MODES.add && updatedRowData) || {
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      website: '',
      extension: '',
      phoneNumber: '',
      appPlanUser: props?.appPlanUser?.appPlan || null,
      accountUser: false,
      callsApiKey: '',
      apiKey: '',
      userTimezone: { name: "Asia/Kolkata" },
      serverTimezone: 'Asia/Kolkata',
      allowedIp: "",
      screenPopMode: "Embed Mode",
      callbackUrl: '',
      password: '',
      confirmPassword: '',
      passwordHint: '',
      accountLocked: false,
      enabled: true,
      accountExpired: false,
      credentialsExpired: false,
      dialerMapping: '',
      urlMap: null,
      partner: false,
      partnerName: '',
      l1partner: null,
      l2partner: null,
      l3partner: null,
      continueBarge: false,

      //below fields are present in normal user itself but in design they have not given these fields


      sipLocationExtension: '',
      sipBargeExtension: '',
      userCampaigns: [],
      appModules: [],

      userLocation: "AddressData",//NotRequired Field
      city: "kakinada",//required Field
      province: "sttate",//required Field
      postalCode: "533001",//required Field
      country: "IN",//required Field

      //NotRequired Field

      //below fields are present in superAdmin only

      // apiKey: "123568",//NotRequired Field but required in super admin
      // callsApiKey: "1235678",//NotRequired Field but required in super admin
      // chatApiKey: "135668",//NotRequired Field but required in super admin

      // urlMap: {
      //     name: "MDL_22",
      //     id: 10
      // },//NotRequired Field but required in super admin
      // dialerMapping: "mdl_1",//NotRequired Field but required in super admin

      //not found in both user as well as SuperAdmin

      // roles: [
      //     {
      //         name: "ROLE_OUTBOUND",
      //         id: 11
      //     },
      //     {
      //         name: "ROLE_TICKET",
      //         id: 21
      //     }
      // ],//NotRequired Field

    }

    const validationSchema = useMemo(() => (yup.object({
      username: yup
        .string('Enter Username')
        .test("maxDigits",
          "Username should be between 3 to 50 alphanumeric characters long and allows special characters like .,@,_,-",
          (number) => String(number).length >= 3 && String(number).length <= 50)
        .matches(/^([A-Za-z\d]((\.|@|_|\-)?[A-Za-z\d])+)$/, "Sub Username should be between 3 to 50 alphanumeric characters long and allows special characters like .,@,_,-")
        .required('Username is required'),
      firstName: yup
        .string("Enter First Name")
        .test("maxDigits",
          "First Name should be between 3 to 50 alphanumeric characters long and allows special characters like _",
          (number) => String(number).length >= 3 && String(number).length <= 50)
        .matches(/^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/, "First Name should be between 3 to 50 alphanumeric characters long and allows special characters like _")
        .required('First Name is required'),
      lastName: yup
        .string("Enter Last Name")
        .test("maxDigits",
          "Last Name should be between 3 to 50 alphanumeric characters long and allows special characters like _",
          (number) => String(number).length >= 3 && String(number).length <= 50)
        .matches(/^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/, "Last Name should be between 3 to 50 alphanumeric characters long and allows special characters like _")
        .required('Last Name is required'),
      email: yup
        .string("Enter Email")
        .email('enter valid email')
        .test("maxDigits",
          "E-Mail should be a valid email.",
          (number) => String(number).length >= 5 && String(number).length <= 50)
        .required('Email is required'),
      phoneNumber: yup
        .string("Enter Phone Number")
        .required('Phone Number is required'),
      extension: yup
        .string("Enter Extension")
        .required('Extension is required'),
      callsApiKey: yup
        .string("Enter Calls API Key")
        .required('Calls API Key is required'),
      apiKey: yup
        .string("Enter API Key")
        .required('API Key is required'),
      password: props?.mode === ADMIN_FORM_MODES.add
        ? yup
          .string("Enter Password")
          .required('Password is required')
          .test(
            "maxDigits",
            "The password must not contain spaces. The password must be 7 to 50 characters, including an uppercase letter, a lowercase letter, and alphanumeric characters.",
            (value) => String(value).length >= 7 && String(value).length <= 50
          )
          .matches(
            /^((?!.*[\s])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-_!@#$%^&*()+{}[\]:;<>,.?~]).{7,50})$/,
            "The password must not contain spaces. The password must be 7 to 50 characters, including an uppercase letter, a lowercase letter, and alphanumeric characters."
          )
        : yup
          .string()
          .required('Password is required')
          .test(
            'password-valid',
            'The password must be 7 to 50 characters, including an uppercase letter, a lowercase letter, and alphanumeric characters.',
            (value) => {
              if (value === props?.rowData?.password) return true;
              if (!value) return false;

              // Apply regex validation for the password
              const regex = /^((?!.*[\s])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-_!@#$%^&*()+{}[\]:;<>,.?~]).{7,50})$/;
              return regex.test(value);
            }
          ),
      confirmPassword: yup
        .string("Enter Confirm Password")
        .required('Confirm Password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'), // Added this validation to check if confirmPassword matches the password field
      passwordHint: yup
        .string("Enter Password Hint")
        .required('Password Hint is required'),
      urlMap: yup
        .object()
        .nullable()
        .required("Map to Server is required."),
      appPlanUser: yup
        .object()
        .nullable()
        .required("Plan Selection is required."),
      dialerMapping: yup
        .string("Please Select")
        .required('Dialer Mapping is required'),
    })), []);

    function SwitchRenderer(switchProps) {
      return (
        <div>
          <Typography>
            {switchProps.name}
          </Typography>
          <div className={switchProps.switchDivClassName}>
            <SimpleSwitch checked={switchProps.value} />
          </div>
        </div>
      );
    };


    return (
      <div className="ozAddSubAdminForm">
        <Formik
          initialValues={initData}
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={(data) => {
            console.log("saved data", data)
            if (data.allowedIp === "") {
              data.allowedIp = null;
            }

            if (props?.mode === ADMIN_FORM_MODES.add) {
              addUser(data);
            } else {
              if (valid === true) {
                updateUser(data);
              }
            }
          }}
        >
          {(propsForm) => (
            <form
              onSubmit={propsForm.handleSubmit}
              id="userForm"
              className="ozcw-scrollbar"
            >
              <Grid container={true} display="flex">
                <div
                  style={{
                    marginLeft: "80px",
                  }}
                >
                  <Typography
                    className="oz-page-header"
                    onClick={props.onClose}
                  >
                    {" "}
                    Users / Account Creation{" "}
                  </Typography>
                  <Typography className="oz-page-heading">
                    {" "}
                    {props?.mode === ADMIN_FORM_MODES.add ? "Account Creation" : props?.rowData?.username}{" "}
                  </Typography>
                  <div
                    style={{
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                      width: "81vw",
                    }}
                  >
                    <Grid
                      className="fieldsStyle"
                      container
                      spacing="20px"
                    >
                      {props?.mode != ADMIN_FORM_MODES.add && (
                        <>
                          <Typography
                            style={{ fontSize: '14px', marginLeft: 'auto', marginRight: '2%', color: disableForm ? '#536580' : '#3D8BF8' }}
                            className="oz-page-header"
                            onClick={() => {
                              if (disableForm) {
                                onClickEdit();
                              } else {
                                propsForm.resetForm({ values: props?.rowData });
                                onClickDiscardEdit();
                              }
                            }}
                          >
                            {" "}
                            {disableForm ? 'Edit' : 'Discard Edit'}{" "}
                          </Typography>
                        </>
                      )}
                      <Grid
                        className="fieldsStyle"
                        container
                        spacing="20px"
                      >
                        <Grid item xs={5} style={{ marginTop: 2 }}>
                          <OZInput
                            name="username"
                            label="Username *"
                            placeholder="username"
                            disabled={props?.mode != ADMIN_FORM_MODES.add}
                          />
                        </Grid>
                        {props?.mode === ADMIN_FORM_MODES.add ? (
                          <>
                            <Grid item xs={5} style={{ marginTop: '3%' }}>
                              <OZFCheckbox
                                name='partner'
                                label='Partner'
                                labelPosition="end"
                                disabled={disableForm}
                              />
                            </Grid>
                            {propsForm?.values?.partner && (
                              <Grid
                                className="fieldsStyle"
                                container
                                spacing="20px"
                              >
                                <Grid item xs={5}>
                                  <OZInput
                                    name="partnerLevel"
                                    label="Partner Level *"
                                    value={partnerLevel}
                                    disabled
                                  />
                                </Grid>
                              </Grid>
                            )}
                            {l1PartnerData?.length > 0 && (
                              <Grid
                                className="fieldsStyle"
                                container
                                spacing="20px"
                              >
                                <Grid item xs={5}>
                                  <OZSelect
                                    name="l1partner"
                                    label={propsForm?.values?.l1partner !== null ? "L1 Partner *" : "L1 Partner"}
                                    options={l1PartnerData}
                                    optionLabel='accountName'
                                    placeholder="L1 Partner"
                                    disabled={disableForm}
                                  />
                                </Grid>
                              </Grid>
                            )}
                            {propsForm?.values?.l1partner !== l1Partner && (() => {
                              setL1Partner(propsForm?.values?.l1partner);
                              getPartnerLevel(propsForm?.values?.l1partner?.accountId, 'L2');
                              setPartnerLevel('L2');
                              return null;
                            })()}
                            {l2PartnerData?.length > 0 && (
                              <Grid
                                className="fieldsStyle"
                                container
                                spacing="20px"
                              >
                                <Grid item xs={5}>
                                  <OZSelect
                                    name="l2partner"
                                    label={propsForm?.values?.l2partner !== null ? "L2 Partner *" : "L2 Partner"}
                                    options={l2PartnerData}
                                    optionLabel='accountName'
                                    placeholder="L2 Partner"
                                    disabled={disableForm}
                                  />
                                </Grid>
                              </Grid>
                            )}
                            {propsForm?.values?.l2partner !== l2Partner && (() => {
                              setL2Partner(propsForm?.values?.l2partner);
                              getPartnerLevel(propsForm?.values?.l2partner?.accountId, 'L3');
                              setPartnerLevel('L3');
                              return null;
                            })()}
                            {l3PartnerData?.length > 0 && (
                              <Grid
                                className="fieldsStyle"
                                container
                                spacing="20px"
                              >
                                <Grid item xs={5}>
                                  <OZSelect
                                    name="l3partner"
                                    label={propsForm?.values?.l3partner !== null ? "L3 Partner *" : "L3 Partner"}
                                    options={l3PartnerData}
                                    optionLabel='accountName'
                                    placeholder="L3 Partner"
                                    disabled={disableForm}
                                  />
                                </Grid>
                              </Grid>
                            )}
                            {propsForm?.values?.l3partner !== l3Partner && (() => {
                              setL3Partner(propsForm?.values?.l3partner);
                              setPartnerLevel('L4');
                              return null;
                            })()}
                          </>)
                          : (
                            <>
                              {propsForm?.values?.partnerLevel != 'L0' && (
                                <Grid
                                  className="fieldsStyle"
                                  container
                                  spacing="20px"
                                >
                                  <Grid item xs={5}>
                                    <OZInput
                                      name="partnerLevel"
                                      label="Partner Level *"
                                      disabled
                                    />
                                  </Grid>
                                </Grid>
                              )}
                              <Grid
                                className="fieldsStyle"
                                container
                                spacing="20px"
                              >
                                {propsForm?.values?.partnerName && (
                                  <Grid item xs={5}>
                                    <OZInput
                                      name="partnerName"
                                      label="Partner Name *"
                                      placeholder="Partner Name"
                                      disabled={true}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </>
                          )}

                        <Grid item xs={5}>
                          <OZInput
                            name="firstName"
                            label="First Name *"
                            placeholder="First Name"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <OZInput
                            name="lastName"
                            label="Last Name *"
                            placeholder="Last Name"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <OZInput
                            name="email"
                            label="Email *"
                            placeholder="Email Address"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <OZInput
                            name="website"
                            label="Website"
                            placeholder="Website"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <OZInput
                            name="phoneNumber"
                            label="Phone Number *"
                            placeholder="***********"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <OZInput
                            name="extension"
                            label="Extension *"
                            placeholder="Extension"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid
                          className="fieldsStyle"
                          container
                          spacing="20px"
                        >
                          <Grid item xs={5}>
                            <OZSelect
                              name="appPlanUser"
                              label="Choose Plan *"
                              options={planListData}
                              optionLabel="name"
                              placeholder="Choose Plan"
                              disabled={disableForm}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={5}>
                          <OZInput
                            name="callsApiKey"
                            label="Calls API Key *"
                            placeholder="Calls API Key"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <OZInput
                            name="apiKey"
                            label="API Key *"
                            placeholder="API Key"
                            disabled={disableForm}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        className="fieldsStyle"
                        container
                        spacing="20px"
                      >
                        <Grid item xs={3.33}>
                          <OZInput
                            name="password"
                            label="Password *"
                            placeholder="Password"
                            type="password"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={3.33}>
                          <OZInput
                            name="confirmPassword"
                            label="Confirm Password *"
                            placeholder="ConfirmPassword"
                            type="password"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={3.34}>
                          <OZInput
                            name="passwordHint"
                            label="Password Hint *"
                            placeholder="PasswordHint"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid
                          className="fieldsStyle"
                          container
                          spacing="20px"
                        >
                          <Grid item xs={5} style={{ marginLeft: '0.5%' }}>
                            {checkRoles("ROLE_CONTINUE_BARGE") ? (
                              <div className='oz-user-form-view-sub' style={{ marginLeft: '0% ! important' }}>
                                <div>
                                  <SimpleSwitch name='continueBarge' disabled={disableForm} checked={propsForm?.values?.continueBarge}
                                    onChange={(e) => { propsForm?.setFieldValue('continueBarge', e.target.checked) }}
                                  />
                                </div>
                                <Typography sx={styleFieldHeader} color={disableForm ? '#99A0A8' : '#212121'}>
                                  Continuous Barge-in
                                </Typography>
                              </div>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                      <div
                        style={{
                          paddingTop: "32px",
                          color: "#212121",
                          fontSize: "18px",
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "20px",
                          lineHeight: "21px",
                          fontWeight: 400,
                        }}
                      >
                        <label>Account Settings</label>
                        <label style={{ color: "#4a5363", fontSize: "12px" }}>Configure the required acces to the user</label>
                      </div>
                      <Divider style={{ borderStyle: 'dotted' }} />
                      <Grid
                        className="fieldsStyle"
                        container
                        spacing="20px"
                      >
                        <Grid item xs={1.1}>
                          <OZFCheckbox
                            name='enabled'
                            label='Enabled'
                            labelPosition="end"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={1.1}>
                          <OZFCheckbox
                            name='accountExpired'
                            label='Expired'
                            labelPosition="end"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={1.1}>
                          <OZFCheckbox
                            name='accountLocked'
                            label='Locked'
                            labelPosition="end"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <OZFCheckbox
                            name='credentialsExpired'
                            label='Password Expired'
                            labelPosition="end"
                            disabled={disableForm}
                          />
                        </Grid>
                      </Grid>
                      <div
                        style={{
                          paddingTop: "32px",
                          color: "#212121",
                          fontSize: "18px",
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "20px",
                          lineHeight: "21px",
                          fontWeight: 400,
                        }}
                      >
                        <label>System Settings</label>
                        <label style={{ color: "#4a5363", fontSize: "12px" }}>Configure the required acces to the user</label>
                      </div>
                      <Divider style={{ borderStyle: 'dotted' }} />
                      <Grid
                        className="fieldsStyle"
                        container
                        spacing="20px"
                      >
                        <Grid item xs={5}>
                          <OZSelect
                            name="urlMap"
                            label="Map to Server *"
                            options={mapToServerData}
                            optionLabel="name"
                            placeholder="Map to server"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <OZSelect
                            name="dialerMapping"
                            label="Dialer Mapping *"
                            options={dialerMapData}
                            optionLabel="name"
                            placeholder="Dialer Mapping"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <OZInput
                            name="serverTimezone"
                            label="Server Time Zone *"
                            placeholder="GMT +5:30"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <Stack>
                            <Typography className="oz-userTimeZone-label" color={disableForm ? '#99A0A8' : '#212121'}>
                              User Time Zone *
                            </Typography>
                            <OZAutocompletes
                              name="userTimezone"
                              label
                              PlaceHolder={"GMT+5:30"}
                              Datas={countryList}
                              disabled={disableForm}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={5}>
                          <OZInput
                            name="allowedIp"
                            label="Allowed IPs"
                            placeholder="Enter IPs"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          {props?.mode === ADMIN_FORM_MODES.add && (
                            <>
                              <OZSelect
                                name="screenPopMode"
                                label="Display CRM In *"
                                options={["Popup Mode", "Embed Mode", "Open in New Tab"]}
                                placeholder="Please select mode"
                                disabled={disableForm}
                              />
                            </>
                          )}
                          {props?.mode != ADMIN_FORM_MODES.add && (
                            <>
                              <OZSelect
                                name="screenPopMode"
                                label="Display CRM In *"
                                optionLabel="displayCRMIn"
                                options={[
                                  { screenPopMode: 1, displayCRMIn: "Popup Mode" },
                                  { screenPopMode: 2, displayCRMIn: "Embed Mode" },
                                  { screenPopMode: 3, displayCRMIn: "Open in New Tab" },
                                ]}
                                placeholder="Please select mode"
                                disabled={disableForm}
                              />
                            </>
                          )}
                        </Grid>
                        <Grid item xs={5}>
                          <OZInput
                            name="callbackUrl"
                            label="Call Back URL"
                            placeholder="Enter URL"
                            disabled={disableForm}
                          />
                        </Grid>
                        {checkRoles("ROLE_ADMIN") ? (
                          <Grid item xs={5}>
                            <OZSelect
                              disabled={disableForm}
                              name="sipBargeExtension"
                              label="SIP Barge Extension"
                              optionLabel="displayCRMIn"
                              options={sipBargeExtensionData}
                              placeholder="Enter Extension URL"
                            />
                          </Grid>
                        ) : (
                          <></>
                        )}
                        {checkRoles("ROLE_ADMIN") ? (
                          <Grid item xs={5}>
                            <OZInput
                              disabled={disableForm}
                              name="sipLocationExtension"
                              label="SIP Location Extension"
                              placeholder="Enter Location"
                            />
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{ paddingTop: "43px", paddingRight: "20px" }}
                      justifyContent="end"
                    >
                      <Grid item>
                        <Button
                          onClick={props?.onClose}
                          variant="outlined"
                          className="cancelButton"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => {
                            propsForm.handleSubmit();
                          }}
                          variant="contained"
                          className="nextButton"
                          disabled={disableForm}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <ScrollToFieldError />
            </form>
          )}
        </Formik>
      </div>
    );
  }

  return (
    <div>
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
            <LoadingScreen />
          </div>
        ) :
        (
          <div>
            <DataForm {...props} />
          </div>
        )
      }
    </div>
  );
};

export default AddUser;