import { Button, Grid, ListItemButton, Menu, Typography, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import { useMemo, useState, useRef } from "react";
import * as yup from "yup";
import OZDrawer from "../../../components/admin/common/OZDrawer/OZDrawer";
import OZFCheckbox from "../../../components/admin/common/OZFCheckbox";
import OZInput from "../../../components/admin/common/OZInput";
import { addTranferNumbers, addTranferNumbersById, deleteTranferNumbersById } from "../../../services/api-service";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import OZDialog from "../../../components/admin/common/OZDialog";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import "./manageNumber.css"
import { CustomViewCheckBox } from "./ManageNumbers";
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";

function ViewTransferNumberForm(viewTransferNumberProps) {
  let styleFieldHeader = useMemo(
    () => ({
      fontSize: "0.75rem",
      height: '0.875rem',
      color: '#99A0A8',
      lineHeight: "0.875rem",
    }),
    []
  );

  let styleFieldValue = useMemo(
    () => ({
      height: '1rem',
      fontWeight: 400,
      fontSize: "0.875rem",
      color: '#212121',
      lineHeight: "1rem",
    }),
    []
  );

  return (
    <div>
      <Grid container={true} paddingTop="1.5rem" paddingLeft="0.625rem" >
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ ...styleFieldHeader, width: "5.5625rem", }}>
          Transfer Number *
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ ...styleFieldValue, width: "6.625rem", marginTop: '0.5rem' }} >
          {viewTransferNumberProps.viewData.transferNumber}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ ...styleFieldHeader, marginTop: '1.1875rem', marginLeft: "2px" }} >
          <CustomViewCheckBox checked={viewTransferNumberProps.viewData.sip || false} label="SIP" />
        </Grid>
      </Grid>
    </div>
  );
}

const AddTransferNumberForm = (transferNumberFormProps) => {

  let initMode = transferNumberFormProps.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add;
  const [openTransferNumberForm, setOpenTransferNumberFormState] = useState(true);
  const [transferNumberFormMode, setTransferNumberFormMode] = useState(initMode);
  const [viewMoreVertMenuAnchor, setViewMoreVertMenuAnchor] = useState(false);
  const [openDelete, setopenDelete] = useState(false)
  const anchorRef = useRef();
  const [valid, setValid] = useState(true);

  const handleCloseMoreVertMenu = () => {
    setViewMoreVertMenuAnchor(false);
  };

  function onClickDelete() {
    setViewMoreVertMenuAnchor(false);
    setopenDelete(true)
  }

  const onClickMoreVertIcon = () => {
    setTimeout(() => setViewMoreVertMenuAnchor(anchorRef?.current), 1);
  };

  const styleListItemButton = useMemo(
    () => ({
      "&.MuiButtonBase-root": {
        color: '#212121',
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: 400,
        height: '18px',
        lineHeight: "14px",
      },
      '&:hover': {
        borderRadius: "8px",
      },
    }),
    []
  );

  let buttonStyle = useMemo(
    () => ({
      borderRadius: "0.5rem",
      height: "2.75rem",
      fontSize: "0.875rem",
      fontWeight: 400,
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      yup.object({
        // transferName: yup
        //   .string("Enter Name")
        //   .required("Numberis required"),
        // transferNumber: yup
        //   .string("Enter Number ")
        //   .required("Numberis required"),
        transferName: yup
          .string("Transfer Name is required.")
          .min(2, "Transfer Name should be between 2 to 50 alphanumeric characters long allows prefix + and special characters like .,@,_,space,- and Name cannot start and end with Special characters.")
          .max(50, "Transfer Name should be between 2 to 50 alphanumeric characters long allows prefix + and special characters like .,@,_,space,- and Name cannot start and end with Special characters.")
          .matches(/^([+A-Za-z0-9]((\\.|@|_|-|\\s)?[A-Za-z0-9])+)$/, 'Transfer Name should be between 2 to 50 alphanumeric characters long allows prefix + and special characters like .,@,_,space,- and Name cannot start and end with Special characters.')
          .required("Transfer Name is required."),
        transferNumber: yup
          .string()
          .test("maxDigits",
            "Transfer Number should be between 3 to 16 characters.",
            (number) => String(number).length >= 2 && String(number).length <= 16)
          .matches(/^(?:\+\d{2,16}|\d{3,16})$/, "Transfer Number should be between 3 to 16 digits long and allows prefix +")
          .required("Transfer Number is required."),
        sip: false,
      }),
    []
  );

  const closeTransferNumberForm = () => {
    transferNumberFormProps.onClose();
    setOpenTransferNumberFormState(false);
  };

  async function addTransferNumber(formData) {
    try {
      addTranferNumbers(formData)
        .then((resp) => {
          if (resp.Status === "Success") {
            closeTransferNumberForm();
            if (transferNumberFormProps.currentTab === '3') {
              transferNumberFormProps.updateTransferNumbers();
            }
            showSuccessNotification(`TransferNumber: ${formData.transferNumber} Added Successfully`)
          }
          else {
            // showErrorNotification(JSON.stringify(resp.Message));
            showErrorNotification(resp?.Message?.transferName || resp?.Message?.transferNumber || resp?.Message);
          }
        })
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
  }

  async function updateTransferNumber(formData) {
    formData.id = transferNumberFormProps.data.id;
    try {
      addTranferNumbersById(formData)
        .then((resp) => {
          if (resp.Status === "Success") {
            transferNumberFormProps.updateTransferNumber();
            closeTransferNumberForm();
            showSuccessNotification(`TransferNumber: ${formData.transferNumber} Updated Successfully`)
          }
          else {
            showErrorNotification(resp?.Message?.transferNumber || resp?.Message?.transferName || resp?.Message);
          }
        })
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
  }

  async function deleteTransferNumberById() {
    try {
      deleteTranferNumbersById(transferNumberFormProps.data.id)
        .then((resp) => {
          if (resp.Status === "Success") {
            closeTransferNumberForm();
            transferNumberFormProps.updateTransferNumber();
            showSuccessNotification(resp.Message)
          }
          else {
            showErrorNotification(JSON.stringify(resp.Message));
          }
        })
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));

        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
    setopenDelete(false)
  }

  const getValidationInViewMode = (e, value) => {
    transferNumberFormProps.data.transferName = value;
    const regex = /^([+A-Za-z0-9]((\\.|@|_|-|\\s)?[A-Za-z0-9])+)$/;
    const isMatch = regex.test(value);

    const digitCount = value.length;

    // Check the conditions for validation
    const isValidLength = value.length >= 2 && value.length <= 50;
    const isValidDigitCount = digitCount >= 2 && digitCount <= 50;

    // Final validation check
    if (isMatch && isValidLength && isValidDigitCount) {
      setValid(true);
    } else {
      setValid(false);
    }
  }


  const TransferNumberForm = (formProps) => {

    let initData = formProps.editData || {
      transferName: "",
      transferNumber: "",
      sip: false,
    };

    return (
      <div className="ozTransferNumberAddForm">
        <Formik
          initialValues={initData}
          validationSchema={validationSchema}
          onSubmit={(formValues) => {
            if (transferNumberFormMode === ADMIN_FORM_MODES.add) {
              addTransferNumber(formValues);
            }
            else {
              if (valid === true) {
                updateTransferNumber(formValues);
              }
            }
          }}>
          {(formik) => (
            <form
              onSubmit={formik.handleSubmit}
              id="transferNumberForm"
              className={"ozcwScrollbar oz-add-phone-number-form-container " + (transferNumberFormMode === ADMIN_FORM_MODES.edit ? "oz-add-phone-number-form-edit-container" : "oz-add-phone-number-form-add-container")}>
              <Grid container={true} spacing={2}>
                {(transferNumberFormMode === ADMIN_FORM_MODES.add) &&
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <OZInput
                      name="transferName"
                      label="Transfer Name *"
                      placeholder="Enter Name" />
                  </Grid>}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <OZInput
                    name="transferNumber"
                    label="Transfer Number *"
                    placeholder="Enter Number" />
                </Grid>
                <Grid sx={{ marginLeft: "2px" }} item xs={12} sm={12} md={12} lg={12}>
                  <OZFCheckbox labelPosition="end" name="sip" label="SIP" />
                </Grid>
              </Grid>
              {transferNumberFormMode === ADMIN_FORM_MODES.edit && (
                <div className="oz-add-phone-number-form-edit-button-container">
                  <Button
                    variant="outlined"
                    sx={{ ...buttonStyle, marginRight: '1.25rem' }}
                    onClick={closeTransferNumberForm}>
                    Cancel
                  </Button>
                  <Button variant="contained" sx={buttonStyle} type="submit">
                    Save
                  </Button>
                </div>
              )}
            </form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <div>
      {transferNumberFormMode === ADMIN_FORM_MODES.add ? (
        <OZDialog
          title="Add Transfer Number"
          saveButtonProps={{
            form: 'transferNumberForm',
            type: 'submit'
          }}
          open={openTransferNumberForm}
          mode={transferNumberFormMode}
          onClose={closeTransferNumberForm}>
          {transferNumberFormMode === ADMIN_FORM_MODES.add && <TransferNumberForm />}
        </OZDialog>
      ) : (
        <OZDrawer
          open={openTransferNumberForm}
          title={
            <div className={"oz-add-phone-number-form-drawer-header-container " + (transferNumberFormMode === "View" ? "oz-add-phone-number-form-drawer-header-view-container" : "oz-add-phone-number-form-drawer-header-edit-container")}>
              {/* {authPage(AdminPages.TRANSFER_NUMBERS_EDIT) && transferNumberFormMode === "View" && <img ref={anchorRef} className="oz-add-phone-number-form-drawer-header-morevert" */}
              {(newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full')) && transferNumberFormMode === "View" && <img ref={anchorRef} className="oz-add-phone-number-form-drawer-header-morevert"
                src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                alt="edit"
                onClick={transferNumberFormMode === "View" ? onClickMoreVertIcon : null}
              ></img>}
              <Menu
                anchorEl={viewMoreVertMenuAnchor}
                open={Boolean(viewMoreVertMenuAnchor)}
                onClose={handleCloseMoreVertMenu}
                sx={{
                  maxHeight: "70vh",
                  "& .MuiPaper-root": {
                    backgroundColor: "white",
                    width: "160px",
                  },
                  "& .MuiMenu-paper": {
                    backgroundColor: "white",
                    boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                    border: "0.0625rem solid #E6E5E6",
                    borderRadius: "12px",
                    marginLeft: "-10.5625rem",
                    marginTop: '-1.5rem'
                  },
                }}
              >
                <ListItemButton
                  onClick={onClickDelete}
                  disabled={newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit')}
                  sx={styleListItemButton}>
                  Delete
                </ListItemButton>
              </Menu>
              {openDelete && <DeleteDialog
                open={openDelete}
                onClose={() => { setopenDelete(false) }}
                title="Delete Transfer Number"
                content={`This will delete Transfer Number:${transferNumberFormProps.data.transferNumber} permanently. You cannot undo this action.`}
                deleteFunction={deleteTransferNumberById} />}
              {transferNumberFormMode === "View" ? <Tooltip title={transferNumberFormProps.data.transferName}><div
                className="oz-managenumbers-drawer-title-heading"
                sx={{
                  fontWeight: 600,
                  fontSize: "1.125rem",
                  width: "11rem",
                  marginTop: '1px',
                  // marginLeft: authPage(AdminPages.TRANSFER_NUMBERS_EDIT) ? "" : "10px",
                  marginLeft: (newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full')) ? "" : "10px",
                }}
                flexGrow={1}>
                {transferNumberFormProps.data.transferName}
              </div></Tooltip> :
                <TextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      backgroundColor: '#ffff',
                      fontSize: '18px',
                      color: "#212121",
                      height: '44px',
                      '&:hover': {
                        border: "none"
                      },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #D6D6D7'
                    },
                    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                      // backgroundColor: '#D6D6D7', //TODO
                      border: '1px solid #D6D6D7'
                    }
                  }}
                  fullWidth
                  defaultValue={transferNumberFormProps.data.transferName}
                  // onChange={(e) => { transferNumberFormProps.data.transferName = e.target.value }}
                  onChange={(e) => getValidationInViewMode(e, e.target.value)}
                  error={!valid}
                  helperText={!valid ? "Transfer Name should be between 2 to 50 alphanumeric characters long allows prefix + and special characters like .,@,_,space,- and Name cannot start and end with Special characters." : ""}
                >
                </TextField>}

              {transferNumberFormMode === "Edit" &&
                <Typography
                  sx={{
                    fontSize: '0.875rem', marginRight: "-4px", paddingLeft: "8px", fontWeight: 400, color: "#3D8BF8", cursor: "pointer"
                  }}
                  onClick={() => setTransferNumberFormMode('View')}>
                  Discard
                </Typography>
              }
            </div>
          }
          onClose={closeTransferNumberForm}
          onEdit={() => setTransferNumberFormMode(ADMIN_FORM_MODES.edit)}
          // hideEditIcon={!authPage(AdminPages.TRANSFER_NUMBERS_EDIT)}
          hideEditIcon={(!newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'View') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'data'))}
          mode={transferNumberFormMode}>
          {transferNumberFormMode === ADMIN_FORM_MODES.view && (
            <ViewTransferNumberForm viewData={transferNumberFormProps.data} />
          )}
          {transferNumberFormMode === ADMIN_FORM_MODES.edit && <TransferNumberForm editData={transferNumberFormProps.data} />}
        </OZDrawer>
      )}
    </div >
  );
};

export default AddTransferNumberForm;
