import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import { Formik } from 'formik';
import OZDialog from '../../../components/admin/common/OZDialog';
import { Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack } from '@mui/material';
import OZInput from '../../../components/admin/common/OZInput';
import OZSelect from '../../../components/admin/common/OZSelect';
import { getAvailableIntegration, saveCloudStorage } from '../../../services/api-service';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from '../../../components/admin/common/NotiService';

const CloudStorage = (mainprops) => {

    console.log(mainprops);

    const [availableIntegrations, setAvailableIntegrations] = useState([])
    const [firstScreen, setFirstScreen] = useState(true);
    const [secondScreen, setSecondScreen] = useState(false);
    const namesWithData = [];
    const [alreadyIntegratedData, setAlreadyIntegratedData] = useState(null)
    console.log(alreadyIntegratedData)
    const [loading, setLoading] = useState(false);
    const [provider, setProvider] = useState(null);
    const [type, setType] = useState(null);
    let providersToShowInNewConnection = ["AWS", "Google Cloud Storage", "Azure"]
    let typesToShowInNewConnection = ["Key", "ARN"]

    if (mainprops.awskey !== undefined && mainprops.awskey !== null) {
        namesWithData.push({
            name: mainprops.awskey.name,
            date: mainprops.awskey.date || ""
        })
        typesToShowInNewConnection = typesToShowInNewConnection.filter(type => type !== "Key");
    }
    if (mainprops.awsArn !== undefined && mainprops.awsArn !== null) {
        namesWithData.push({
            name: mainprops.awsArn.name,
            date: mainprops.awsArn.date || ""
        })
        typesToShowInNewConnection = typesToShowInNewConnection.filter(type => type !== "ARN");
    }
    if (mainprops.gcp !== undefined && mainprops.gcp !== null) {
        namesWithData.push({
            name: mainprops.gcp.name,
            date: mainprops.gcp.date || ""
        })
        providersToShowInNewConnection = providersToShowInNewConnection.filter(provider => provider !== "Google Cloud Storage");
    }
    if (mainprops.azure !== undefined && mainprops.azure !== null) {
        namesWithData.push({
            name: mainprops.azure.name,
            date: mainprops.azure.date || ""
        })
        providersToShowInNewConnection = providersToShowInNewConnection.filter(provider => provider !== "Azure");
    }
    if (mainprops.awskey !== undefined && mainprops.awskey !== null && mainprops.awsArn !== undefined && mainprops.awsArn !== null) {
        providersToShowInNewConnection = providersToShowInNewConnection.filter(provider => provider !== "AWS");
    }

    useEffect(() => {
        getAvailableIntegrations();
    }, [])

    async function getAvailableIntegrations() {
        try {
            getAvailableIntegration()
                .then((resp) => {
                    if (resp.Status === "Success") {
                        setAvailableIntegrations(resp.Data)
                    }
                    else {
                        showWarningNotification(resp.Message);
                    }
                })
                .catch((e) => {
                    setAvailableIntegrations([])
                });
        } catch (e) {
            showErrorNotification("Error in integrations list");
        }
    };

    const validationSchema1 = useMemo(
        () =>
            yup.object({
                accessKey: yup
                    .string()
                    .required("AccessKey is Required"),
                secretKey: yup
                    .string()
                    .required("SecretKey is Required"),
                bucketName: yup
                    .string()
                    .required("BucketName is Required"),
                folderName: yup
                    .string()
                    .required("FolderName is Required"),
                isZip: yup
                    .string()
                    .required("IsZip is Required"),
            }),
        []
    );

    const validationSchema2 = useMemo(
        () =>
            yup.object({
                bucketName: yup
                    .string()
                    .required("BucketName is Required"),
                folderName: yup
                    .string()
                    .required("FolderName is Required"),
                isZip: yup
                    .string()
                    .required("IsZip is Required"),
            }),
        []
    );

    const validationSchema3 = useMemo(
        () =>
            yup.object({
                gcptype: yup
                    .string()
                    .required("GCP Type is Required"),
                projectId: yup
                    .string()
                    .required("ProjectId is Required"),
                PrivateId: yup
                    .string()
                    .required("PrivateId is Required"),
                PrivateKey: yup
                    .string()
                    .required("PrivateKey is Required"),
                clientEmail: yup
                    .string()
                    .required("ClientEmail is Required"),
                clientId: yup
                    .string()
                    .required("ClientId is Required"),
                authUrl: yup
                    .string()
                    .required("AuthUrl is Required"),
                tokenUrl: yup
                    .string()
                    .required("TokenUrl is Required"),
                auth_provider_x509_curt_url: yup
                    .string()
                    .required("auth_provider_x509_curt_url is Required"),
                client_provider_x509_curt_url: yup
                    .string()
                    .required("client_provider_x509_curt_url is Required"),
            }),
        []
    );

    useEffect(() => {
        setProvider(alreadyIntegratedData !== null ? alreadyIntegratedData?.provider : providersToShowInNewConnection.length > 0 ? providersToShowInNewConnection[0] : null);
        setType(alreadyIntegratedData !== null ? alreadyIntegratedData?.type : typesToShowInNewConnection.length > 0 ? typesToShowInNewConnection[0] : null)
    }, [alreadyIntegratedData])

    const handleProviderChange = (event) => {
        if (alreadyIntegratedData === null) {
            setProvider(event.target.value);
        }
    };

    const handleTypeChange = (event) => {
        if (alreadyIntegratedData === null) {
            setType(event.target.value);
        }
    };

    const onClickNewConnectionButton = () => {
        setFirstScreen(false);
        setSecondScreen(true);
        setAlreadyIntegratedData(null)
    }

    const onClickCancelOnCloudStorageScecondScreen = () => {
        setFirstScreen(true);
        setSecondScreen(false);
    }

    const onClickName = (name) => {
        setFirstScreen(false);
        setSecondScreen(true);
        if (name === "AWS-Key") {
            setAlreadyIntegratedData(mainprops.awskey)
        }
        if (name === "AWS-ARN") {
            setAlreadyIntegratedData(mainprops.awsArn)
        }
        if (name === "GCP") {
            setAlreadyIntegratedData(mainprops.gcp)
        }
        if (name === "Azure") {
            setAlreadyIntegratedData(mainprops.azure)
        }
    }

    function convertToRequiredDateString(dateStr) {
        const date = new Date(dateStr);
        const day = date.getUTCDate();
        const month = date.getUTCMonth() + 1;
        const year = date.getUTCFullYear();
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;
        const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
        return formattedDate;
    }

    const DataForm = () => {
        let initData = {}
        if ((provider === "AWS" && type === "Key") || provider === "Azure") {
            initData = {
                accessKey: alreadyIntegratedData !== null ? alreadyIntegratedData?.accessKey : '',
                secretKey: alreadyIntegratedData !== null ? alreadyIntegratedData?.secretKey : '',
                bucketName: alreadyIntegratedData !== null ? alreadyIntegratedData?.bucketName : '',
                folderName: alreadyIntegratedData !== null ? alreadyIntegratedData?.folderName : '',
                isZip: alreadyIntegratedData !== null ? alreadyIntegratedData?.isZip : '',
            }
        }
        if (provider === "AWS" && type === "ARN") {
            initData = {
                bucketName: alreadyIntegratedData !== null ? alreadyIntegratedData?.bucketName : '',
                folderName: alreadyIntegratedData !== null ? alreadyIntegratedData?.folderName : '',
                isZip: alreadyIntegratedData !== null ? alreadyIntegratedData?.isZip : '',
            }
        }
        if (provider === "Google Cloud Storage") {
            initData = {
                gcptype: alreadyIntegratedData !== null ? alreadyIntegratedData?.gcptype : '',
                projectId: alreadyIntegratedData !== null ? alreadyIntegratedData?.projectId : '',
                PrivateId: alreadyIntegratedData !== null ? alreadyIntegratedData?.PrivateId : '',
                PrivateKey: alreadyIntegratedData !== null ? alreadyIntegratedData?.PrivateKey : '',
                clientEmail: alreadyIntegratedData !== null ? alreadyIntegratedData?.clientEmail : '',
                clientId: alreadyIntegratedData !== null ? alreadyIntegratedData?.clientId : '',
                authUrl: alreadyIntegratedData !== null ? alreadyIntegratedData?.authUrl : '',
                tokenUrl: alreadyIntegratedData !== null ? alreadyIntegratedData?.tokenUrl : '',
                auth_provider_x509_curt_url: alreadyIntegratedData !== null ? alreadyIntegratedData?.auth_provider_x509_curt_url : '',
                client_provider_x509_curt_url: alreadyIntegratedData !== null ? alreadyIntegratedData?.client_provider_x509_curt_url : ''
            }
        }


        const saveCloudstorage = async (values) => {
            setLoading(true);

            const nameToFind = (provider === "Google Cloud Storage" ? "GCP" : provider === "Azure" ? "Azure" : (provider === "AWS" && type === "Key") ? "AWS-Key" : (provider === "AWS" && type === "ARN") ? "AWS-ARN" : null);
            const exists = availableIntegrations.some(obj => obj.name === nameToFind);
            let foundObjInAvailableIntegrations = null;
            if (exists) {
                foundObjInAvailableIntegrations = availableIntegrations.find(obj => obj.name === nameToFind);
            }

            if (foundObjInAvailableIntegrations !== null && foundObjInAvailableIntegrations !== undefined) {

                if (provider === "Google Cloud Storage") {
                    values = { ...values, provider: provider, type: (provider === "AWS" ? type : null), id: foundObjInAvailableIntegrations.id, name: foundObjInAvailableIntegrations.name }
                }
                else if (provider === "Azure") {
                    values = { ...values, provider: provider, type: (provider === "AWS" ? type : null), id: foundObjInAvailableIntegrations.id, name: foundObjInAvailableIntegrations.name }
                }
                else if (provider === "AWS" && type === "Key") {
                    values = { ...values, provider: provider, type: (provider === "AWS" ? type : null), id: foundObjInAvailableIntegrations.id, name: foundObjInAvailableIntegrations.name }
                }
                else if (provider === "AWS" && type === "ARN") {
                    values = { ...values, provider: provider, type: (provider === "AWS" ? type : null), id: foundObjInAvailableIntegrations.id, name: foundObjInAvailableIntegrations.name }
                }

                try {
                    saveCloudStorage(values)
                        .then((resp) => {
                            if (resp.Status === "Success") {
                                setLoading(false);
                                showSuccessNotification("Cloud Storage details saved successfully")
                                setFirstScreen(false);
                                setSecondScreen(false);
                                mainprops.getUserIntegrationsData()
                                mainprops.onClickCancel()
                            }
                            else {
                                setLoading(false);
                                showErrorNotification(resp.Message)
                                console.log(resp.Message)
                            }
                        })
                        .catch((e) => {
                            setLoading(false);
                            console.error(e);
                            showErrorNotification("Error saving CloudStorage details");
                        });
                }
                catch {
                    setLoading(false);
                    showErrorNotification(`Error in saving CloudStorage details`)
                }
            }
            else {
                setLoading(false);
                showWarningNotification("Integration is not available")
            }
        }

        return (
            <div>
                <Formik
                    initialValues={initData}
                    validationSchema={((provider === "AWS" && type === "Key") || provider === "Azure") ? validationSchema1 : (provider === "AWS" && type === "ARN") ? validationSchema2 : (provider === "Google Cloud Storage") ? validationSchema3 : null}
                    onSubmit={(values) => {
                        console.log(values);
                        saveCloudstorage(values);
                    }}
                >
                    {
                        (props) => (
                            <div>
                                <form
                                    onSubmit={props.handleSubmit}
                                    id="cloudStorageForm"
                                    style={{
                                        width: "682px",
                                    }}
                                >
                                </form>

                                <div>
                                    <Divider variant="fullWidth" sx={{ borderColor: "#D6D6D7" }} />
                                </div>
                                {firstScreen && !secondScreen && namesWithData && namesWithData.length > 0 &&
                                    <div style={{ margin: "20px", border: '1px solid #D6D9DC', borderRadius: "8px", padding: "10px" }}>
                                        <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                                            <label style={{ fontSize: "14px", fontWeight: 600, color: "#909DAD", minWidth: "80px" }}>
                                                S.no.
                                            </label>
                                            <label style={{ fontSize: "14px", fontWeight: 600, color: "#909DAD", minWidth: "275px" }}>
                                                Connection Name
                                            </label>
                                            <label style={{ fontSize: "14px", fontWeight: 600, color: "#909DAD", minWidth: "275px" }}>
                                                Created Date
                                            </label>
                                        </div>
                                        <Divider variant="fullWidth" sx={{ borderColor: "#D6D6D7", marginTop: "8px" }} />
                                        {namesWithData.map((data, index) => {
                                            return (
                                                <div style={{ display: "flex", flexDirection: "row", gap: "20px", marginTop: "8px" }}>
                                                    <label style={{ fontSize: "12px", fontWeight: 400, color: "#3D4C5E", minWidth: "80px" }}>
                                                        {`0${index + 1}.`}
                                                    </label>
                                                    <label style={{ fontSize: "12px", fontWeight: 400, color: "#008DFF", minWidth: "275px", cursor: "pointer" }} onClick={() => { onClickName(data.name) }}>
                                                        {data.name}
                                                    </label>
                                                    <label style={{ fontSize: "12px", fontWeight: 400, color: "#909DAD", minWidth: "275px" }}>
                                                        {convertToRequiredDateString(data.date)}
                                                    </label>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                }
                                {firstScreen && !secondScreen && namesWithData && namesWithData.length === 0 &&
                                    <div style={{ display: "flex", alignItems: 'center', fontSize: "14px", fontWeight: 600, color: "#D6D9DC", justifyContent: "center", margin: "20px" }}>
                                        No Data Found
                                    </div>
                                }
                                {secondScreen && !firstScreen &&
                                    <div style={{ margin: "10px" }}>
                                        <div style={{ marginLeft: "20px", marginRight: "20px", display: "flex", flexDirection: "column" }}>
                                            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                <div style={{ fontSize: "14px", fontWeight: 400, color: "#546881", width: "60px" }}>
                                                    Provider
                                                </div>
                                                <label>:</label>
                                                <div>
                                                    <FormControl sx={{
                                                        paddingLeft: "15px",
                                                        '& .css-s1wlvs-MuiButtonBase-root-MuiRadio-root': {
                                                            paddingRight: "5px"
                                                        }
                                                    }}>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            value={provider}
                                                            onChange={handleProviderChange}
                                                        >
                                                            {(alreadyIntegratedData === null && providersToShowInNewConnection.some(provider => provider === "AWS")) || (alreadyIntegratedData?.provider === "AWS") ?
                                                                <FormControlLabel value="AWS" control={<Radio />} sx={{
                                                                    '& .MuiTypography-root': {
                                                                        fontSize: "12px",
                                                                        fontWeight: 400,
                                                                        color: "#47586E",
                                                                        cursor: alreadyIntegratedData !== null ? 'not-allowed' : 'pointer',
                                                                    }
                                                                }} label="AWS" />
                                                                : null
                                                            }
                                                            {(alreadyIntegratedData === null && providersToShowInNewConnection.some(provider => provider === "Google Cloud Storage")) || (alreadyIntegratedData?.provider === "Google Cloud Storage") ?
                                                                <FormControlLabel value="Google Cloud Storage" control={<Radio />} sx={{
                                                                    '& .MuiTypography-root': {
                                                                        fontSize: "12px",
                                                                        fontWeight: 400,
                                                                        color: "#47586E",
                                                                        cursor: alreadyIntegratedData !== null ? 'not-allowed' : 'pointer',
                                                                    }
                                                                }} label="Google Cloud Storage" />
                                                                : null
                                                            }
                                                            {(alreadyIntegratedData === null && providersToShowInNewConnection.some(provider => provider === "Azure")) || (alreadyIntegratedData?.provider === "Azure") ?
                                                                <FormControlLabel value="Azure" control={<Radio />} sx={{
                                                                    '& .MuiTypography-root': {
                                                                        fontSize: "12px",
                                                                        fontWeight: 400,
                                                                        color: "#47586E",
                                                                        cursor: alreadyIntegratedData !== null ? 'not-allowed' : 'pointer',
                                                                    }
                                                                }} label="Azure" />
                                                                : null
                                                            }
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>
                                            </Stack>

                                            {provider === "AWS" &&
                                                <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} marginTop={"-10px"}>
                                                    <div style={{ fontSize: "14px", fontWeight: 400, color: "#546881", width: "60px" }}>
                                                        Type
                                                    </div>
                                                    <label>:</label>
                                                    <div>
                                                        <FormControl sx={{
                                                            paddingLeft: "15px",
                                                            '& .css-s1wlvs-MuiButtonBase-root-MuiRadio-root': {
                                                                paddingRight: "5px"
                                                            }
                                                        }}>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                value={type}
                                                                onChange={handleTypeChange}
                                                            >
                                                                {(alreadyIntegratedData === null && typesToShowInNewConnection.some(type => type === "Key")) || (alreadyIntegratedData?.type === "Key") ?
                                                                    <FormControlLabel value="Key" control={<Radio />} sx={{
                                                                        '& .MuiTypography-root': {
                                                                            fontSize: "12px",
                                                                            fontWeight: 400,
                                                                            color: "#47586E",
                                                                            cursor: alreadyIntegratedData !== null ? 'not-allowed' : 'pointer',
                                                                        }
                                                                    }} label="Key" />
                                                                    : null
                                                                }
                                                                {(alreadyIntegratedData === null && typesToShowInNewConnection.some(type => type === "ARN")) || (alreadyIntegratedData?.type === "ARN") ?
                                                                    <FormControlLabel value="ARN" control={<Radio />} sx={{
                                                                        '& .MuiTypography-root': {
                                                                            fontSize: "12px",
                                                                            fontWeight: 400,
                                                                            color: "#47586E",
                                                                            cursor: alreadyIntegratedData !== null ? 'not-allowed' : 'pointer',
                                                                        }
                                                                    }} label="ARN" />
                                                                    : null
                                                                }
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </div>
                                                </Stack>
                                            }
                                        </div>
                                        <Divider style={{ border: '1px dashed #B2BBC6' }} />
                                        <div style={{ marginTop: "10px", backgroundColor: "#F6F8F9", maxHeight: "220px", overflowY: "scroll" }}>
                                            <div style={{ padding: "10px" }}>
                                                <div style={{ color: "#546881", fontSize: "16px", fontWeight: 500 }}>
                                                    {provider === "AWS" ? type === "Key" ? "AWS S3 Auth Using Key" : "AWS S3 Auth Using ARN" : provider === "Google Cloud Storage" ? "Google Cloud Storage Auth" : "Azure Auth"}
                                                </div>
                                                <div style={{ color: "#47586E", fontSize: "12px", fontWeight: 400, marginBottom: "10px" }}>
                                                    Enter the below information to schedule the report
                                                </div>
                                                <Divider style={{ border: '1px dashed #B2BBC6' }} />
                                            </div>
                                            <Grid style={{ margin: "10px", display: "flex", flexDirection: "column", gap: "12px" }}>
                                                {((provider === "AWS" && type === "Key") || provider === "Azure") &&
                                                    <Grid item xs={12}>
                                                        <OZInput
                                                            name='accessKey'
                                                            label='Access Key'
                                                            placeholder="Enter Access key"
                                                        />
                                                    </Grid>
                                                }
                                                {((provider === "AWS" && type === "Key") || provider === "Azure") &&
                                                    <Grid item xs={12}>
                                                        <OZInput
                                                            name='secretKey'
                                                            label='Secret Key'
                                                            placeholder="Enter Secret key"
                                                        />
                                                    </Grid>
                                                }
                                                {provider === "Google Cloud Storage" &&
                                                    <>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='gcptype'
                                                                label='Type'
                                                                placeholder="Service Account"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='projectId'
                                                                label='Project Id'
                                                                placeholder="Enter Project Id"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='PrivateId'
                                                                label='Private_Key_Id'
                                                                placeholder="Enter Private Key Id"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='PrivateKey'
                                                                label='Private_Key'
                                                                placeholder="Enter Private Key"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='clientEmail'
                                                                label='Client Email'
                                                                placeholder="Enter Client Email"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='clientId'
                                                                label='Client Id'
                                                                placeholder="Enter Client Id"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='authUrl'
                                                                label='Auth URL'
                                                                placeholder="Enter Auth Url"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='tokenUrl'
                                                                label='Token URL'
                                                                placeholder="Enter Token Url"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='auth_provider_x509_curt_url'
                                                                label='Auth_provider_x509_curt_url'
                                                                placeholder="Enter auth_provider_x509_curt_url"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <OZInput
                                                                name='client_provider_x509_curt_url'
                                                                label='Client_provider_x509_curt_url'
                                                                placeholder="Enter client_provider_x509_curt_url"
                                                            />
                                                        </Grid>
                                                    </>
                                                }
                                                {(provider === "AWS" || provider === "Azure") &&
                                                    <Grid item xs={12}>
                                                        <OZInput
                                                            name='bucketName'
                                                            label='Bucket Name'
                                                            placeholder="Enter Bucket Name"
                                                        />
                                                    </Grid>
                                                }
                                                {(provider === "AWS" || provider === "Azure") &&
                                                    <Grid item xs={12}>
                                                        <OZInput
                                                            name='folderName'
                                                            label='Folder Name'
                                                            placeholder="Enter Folder Name"
                                                        />
                                                    </Grid>
                                                }
                                                {(provider === "AWS" || provider === "Azure") &&
                                                    <Grid item xs={12}>
                                                        <OZSelect
                                                            name="isZip"
                                                            label="is Zip? "
                                                            placeholder="Select"
                                                            options={["Yes", "No"]}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    }
                </Formik >
            </div >
        )
    }

    return (
        <>
            {loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                        <LoadingScreen />
                    </div>
                ) :
                <OZDialog open={mainprops.open} onClose={mainprops.onClickCancel} title="Cloud Storage" saveButtonProps={{ form: 'cloudStorageForm', type: 'submit' }} form="cloudStorageForm" showNewConnectionButton={(firstScreen && providersToShowInNewConnection.length > 0) ? true : false} onClickNewConnectionButton={() => { onClickNewConnectionButton() }} onClickCancelOnCloudStorage={onClickCancelOnCloudStorageScecondScreen} hideCancelButton={firstScreen ? true : false} hideSaveButton={firstScreen ? true : false}>
                    <DataForm />
                </OZDialog>
            }
        </>
    )
}

export default CloudStorage