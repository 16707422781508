import { useState, useMemo, useRef, useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Grid, Button, Typography, Menu, ListItemButton, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import OZInput from "../../../components/admin/common/OZInput";
import OZDrawer from "../../../components/admin/common/OZDrawer/OZDrawer";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import OZDialog from "../../../components/admin/common/OZDialog";
import { addAgentPhoneNumbers, addAgentPhoneNumbersById, deleteAgentPhoneNumbersById, getSkillsDropdown,getAvailableSipNumbers } from "../../../services/api-service";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import "./manageNumber.css"
import OZFCheckbox from "../../../components/admin/common/OZFCheckbox";
import { checkRoles } from "../../../utils/util";
import { CustomViewCheckBox } from "./ManageNumbers";
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";
import CustomAutoComplete from "../../../components/admin/common/AutoComplete/CustomAutoComplete";
import OZSelect from '../../../components/admin/common/OZSelect';

// function ViewPhoneNumberForm(viewPhoneNumberFormProps) {
//   let styleFieldHeader = useMemo(
//     () => ({
//       fontSize: "0.75rem",
//       height: '0.875rem',
//       color: '#99A0A8',
//       lineHeight: "0.875rem",

//     }),
//     []
//   );

//   let styleFieldValue = useMemo(
//     () => ({
//       height: '1rem',
//       fontWeight: 400,
//       fontSize: "0.875rem",
//       color: '#212121',
//       lineHeight: "1rem",
//     }),
//     []
//   );

//   return (
//     <div>
//       <Grid container={true} marginTop="1.5rem" marginLeft="0.625rem">
//         <Grid item sx={{ ...styleFieldHeader, width: "5.5625rem", }} xs={12} sm={12} md={12} lg={12}>
//           Phone Number *
//         </Grid>
//         <Grid item sx={{ ...styleFieldValue, width: "6.625rem", marginTop: '0.5rem' }} xs={12} sm={12} md={12} lg={12}>
//           {viewPhoneNumberFormProps.data.phoneNumber}
//         </Grid>

//         <Grid item sx={{ ...styleFieldHeader, width: "2.75rem", marginTop: '1.3rem' }} xs={12} sm={12} md={12} lg={12}>
//           Prority *
//         </Grid>
//         <Grid item sx={{ ...styleFieldValue, width: "1.125rem", marginTop: '0.5rem' }} xs={12} sm={12} md={12} lg={12}>
//           {viewPhoneNumberFormProps.data.priority}
//         </Grid>
//         {checkRoles("ROLE_SIP") && <Grid item sx={{ marginTop: '0.9rem', marginLeft: "2px" }}>
//           <CustomViewCheckBox checked={viewPhoneNumberFormProps.data.sip || false} label="SIP" />
//         </Grid>}
//       </Grid>
//     </div>
//   );
// }

const ViewPhoneNumberForm = (viewPhoneNumberFormProps) => {
  const [skillOption, setSkillOption] = useState([]);
  const [sipOption, setSipOption] = useState([]);
  
  useEffect(() => {
    getSkillsOptions();
    
    getSipOptions();
  }, [viewPhoneNumberFormProps]);

  async function getSkillsOptions() {
    try {
      getSkillsDropdown().then(resp => {
        setSkillOption(resp.Data);
      }).catch(e => {
        setSkillOption([]);
        showErrorNotification('No Skill Data, Check your Internet Connection');
      })
    }
    catch (e) {
      showErrorNotification('Something went wrong, contact to Admin');
      setSkillOption([]);
    }
  }
  
  async function getSipOptions() {
     // alert('1');
    try {
        
      getAvailableSipNumbers(0).then(resp => {
        //  alert("::::::::"+resp.Data[0].skillName);
          //alert(resp.Data);
          //alert("hiii");
           const sipList =  resp.Data.map(item => item);
         // alert(sipList);
        setSipOption(sipList);
        
        
      }).catch(e => {
         // alert("hiii");
        setSipOption([]);
       // showErrorNotification('No Sip Data, Check your Internet Connection');
      })
    }
    catch (e) {
      showErrorNotification('Something went wrong, contact to Admin');
      setSipOption([]);
    }
  }
  
   
  let styleFieldHeader = useMemo(
    () => ({
      fontSize: "0.75rem",
      height: '0.875rem',
      color: '#99A0A8',
      lineHeight: "0.875rem",

    }),
    []
  );

  let styleFieldValue = useMemo(
    () => ({
      height: '1rem',
      fontWeight: 400,
      fontSize: "0.875rem",
      color: '#212121',
      lineHeight: "1rem",
    }),
    []
  );

  let initData = viewPhoneNumberFormProps.data || {
    name: "",
    phoneNumber: "",
    priority: "",
    phoneSkills: [],
    phoneLocation:""
  };

  return (
    <div>
      <Formik
        initialValues={initData}
      >
        {
          (props) => (
            <div>
              <form
                id="viewFormPhoneNumber"
                style={{
                  maxHeight: 'auto',
                  overflowY: 'auto',
                  padding: '10px'
                }}
              >
                <Grid container spacing={2}>
                {checkRoles("ROLE_SIP") && <Grid item sx={{ marginTop: '0.9rem', marginLeft: "2px" }}>
                    <CustomViewCheckBox checked={viewPhoneNumberFormProps.data.sip || false} label="SIP" />
                  </Grid>}
                  <Grid item sx={{ ...styleFieldHeader, width: "5.5625rem", }} xs={12} sm={12} md={12} lg={12}>
                    Phone Number *
                  </Grid>
                  <Grid item sx={{ ...styleFieldValue, width: "6.625rem", marginTop: '0.5rem' }} xs={12} sm={12} md={12} lg={12}>
                    {viewPhoneNumberFormProps.data.phoneNumber}
                  </Grid>

                  <Grid item sx={{ ...styleFieldHeader, width: "2.75rem", marginTop: '1.3rem' }} xs={12} sm={12} md={12} lg={12}>
                    Prority *
                  </Grid>
                  <Grid item sx={{ ...styleFieldValue, width: "1.125rem", marginTop: '0.5rem' }} xs={12} sm={12} md={12} lg={12}>
                    {viewPhoneNumberFormProps.data.priority}
                  </Grid>
                  <Grid item sx={{ ...styleFieldHeader, width: "2.75rem", marginTop: '1.3rem' }} xs={12} sm={12} md={12} lg={12}>
                    Skills
                  </Grid>
                  {viewPhoneNumberFormProps?.data?.phoneSkills ?
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CustomAutoComplete
                        disabled={true}
                        limitTags={3}
                        mode='Add'
                        name="phoneSkills"
                        placeholder={"Search Skill Name"}
                        Datas={skillOption}
                        optionLabelName='skillName'
                        showCheckBox={true}
                        multiple={true}
                        showselectall="true"
                        popupIcon={null}
                      />
                    </Grid>
                    :
                    ""
                  }

                </Grid>
              </form>
            </div>
          )}
      </Formik>
    </div>
  );
}

const AddPhoneNumberForm = (phoneNumberFormProps) => {
    
    
    //alert(phoneNumberFormProps.data.sip)
  let initMode = phoneNumberFormProps.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add;
  const [openPhoneNumberForm, setOpenPhoneNumberForm] = useState(true);
  const [phoneNumberFormMode, setPhoneNumberFormMode] = useState(initMode);
  const [viewFormDrawerMenu, setViewFormDrawerMenu] = useState(false);
  const [openDelete, setopenDelete] = useState(false)
  const anchorRef = useRef();
  const [valid, setValid] = useState(true);
  const [skillOption, setSkillOption] = useState([]);
  const [sipOption, setSipOption] = useState([]);
  const [phoneNumberValue, setPhoneNumberValue] = useState([]);

  const handleCloseViewFormMenu = () => {
    setViewFormDrawerMenu(false);
  };

  useEffect(() => {
      
    getSkillsOptions();
    getSipOptions();
  }, [phoneNumberFormMode]);

  function onClickDelete() {
    setViewFormDrawerMenu(false);
    setopenDelete(true)
  }

  const onMorevertClickViewMenu = () => {
    setTimeout(() => setViewFormDrawerMenu(anchorRef?.current), 1);
  };

  let buttonStyle = useMemo(
    () => ({
      borderRadius: "0.5rem",
      height: "2.75rem",
      fontSize: "0.875rem",
      fontWeight: 400,
    }),
    []
  );

  const styleListItemButton = useMemo(
    () => ({
      "&.MuiButtonBase-root": {
        color: '#212121',
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: 400,
        height: '18px',
        lineHeight: "14px",
      },
      '&:hover': {
        borderRadius: "8px",
      },
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      yup.object({
        phoneNumber: yup
  .mixed() // Use mixed type to allow both string and object
  .test("validPhoneNumber", "Phone Number should be between 3 to 16 digits long and allows prefix +", function(value) {
    if (this.resolve(yup.ref("sip")) === true) {
      // If sip is true, allow object
        
        if(typeof value === "object"){
            return typeof value === "object";
        }else{
           return validationSchema;
        }
      
    } else {
      // Otherwise, validate as string
      return yup
        .string()
        .matches(
          /^(?:\+\d{2,16}|\d{3,17})$/,
          "Phone Number should be between 3 to 16 digits long and allows prefix +"
        )
        .isValidSync(value);
    }
  })
  .required("PhoneNumber is required"),

        name: yup
          .string("Enter Name")
          .min(2, "Name should be between 2 to 50 alphanumeric characters long allows prefix + and special characters like .,@,_,- and Name cannot start and end with Special characters.")
          .max(50, "Name should be between 2 to 50 alphanumeric characters long allows prefix + and special characters like .,@,_,- and Name cannot start and end with Special characters.")
          .matches(/^([+A-Za-z\d]((\.|@|_|-)?[A-Za-z\d])+)$/, 'Name should be between 2 to 50 alphanumeric characters long allows prefix + and special characters like .,@,_,- and Name cannot start and end with Special characters.')
          .required("Name is required"),
        priority: yup
          .number("Enter Priority")
          .min(1, 'Priority should be between 1 and 20')
          .max(20, 'Priority should be between 1 and 20')
          .typeError('The value must be a number')
          .integer('The value must be a number')
          .required('Priority is required'),
      }),
    []
  );

  const closePhoneNumbersForm = () => {
    phoneNumberFormProps.onClose();
    setOpenPhoneNumberForm(false);
  };

  async function deleteAgentPhoneNumberById() {
    try {
      deleteAgentPhoneNumbersById(phoneNumberFormProps.data.id)
        .then((resp) => {
          if (resp.Status === "Success") {
            closePhoneNumbersForm();
            phoneNumberFormProps.updateAgentPhoneNumbers();
            showSuccessNotification(resp.Message)
          }
          else {
            showErrorNotification(JSON.stringify(resp.Message));
          }
        })
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
    setopenDelete(false)
  }

  async function getSkillsOptions() {
    try {
      getSkillsDropdown().then(resp => {
        setSkillOption(resp.Data);
      }).catch(e => {
        setSkillOption([]);
        showErrorNotification('No Skill Data, Check your Internet Connection');
      })
    }
    catch (e) {
      showErrorNotification(JSON.stringify(e));
      setSkillOption([]);
    }
  }
  
  
  async function getSipOptions() {
      //alert();
      //alert('2');
     let id = phoneNumberFormProps.data && phoneNumberFormProps.data.sipNumberId ? phoneNumberFormProps.data.sipNumberId : 0;
     
         
    try {
      getAvailableSipNumbers(id).then(resp => {
        //  alert("::::::::"+resp.Data[0].skillName);
          //alert(resp.Data);
//          resp={"Data":[
//        {
//            "sipNumberLabel": "9398340070~ozonetel",
//            "sipNumber": 9398340070,
//            "sipLocation": "ozonetel"
//        },{
//            "sipNumberLabel": "9398340071~delhi",
//            "sipNumber": 9398340071,
//            "sipLocation": "delhi"
//        }]};
           const sipList =  resp.Data.map(item => item);
         // alert(sipList);
        setSipOption(sipList);
        try{
        if(phoneNumberFormProps.data.sip===true){
            const filteredData = resp.Data.filter(item => item.sip_number === phoneNumberFormProps.data.phoneNumber);
            console.log("hiii");
            phoneNumberFormProps.data.phoneNumber=filteredData[0].sipNumberLabel;
            //alert(phoneNumberFormProps.data.phoneNumber.sipNumber);
            
        }
    }catch(e){
        console.log(e);
    }
        
      }).catch(e => {
        setSipOption([]);
        if(phoneNumberFormMode === ADMIN_FORM_MODES.add){
            
            showErrorNotification('No Sip Data, Check your Internet Connection');
        }
      })
    }
    catch (e) {
      showErrorNotification('Something went wrong, contact to Admin');
      setSipOption([]);
    }
  }
  
 

  async function addPhoneNumber(formData) {
      
      
    
    try {
        if(formData.sip===true){
            
            
                formData={...formData,["phoneLocation"]:formData.phoneNumber.id+"~"+formData.phoneNumber.sip_location_id}
            formData.phoneNumber=formData.phoneNumber.sip_number;
            
            
            //formData.phoneLocation=formData.phoneNumber.sipLocation;
            
        }else{
            formData={...formData,["phoneLocation"]:""}
        }
        
        
      addAgentPhoneNumbers(formData)
        .then((resp) => {
          if (resp.Status === "Success") {
            closePhoneNumbersForm();
            if (phoneNumberFormProps.currentTab === '1') {
              phoneNumberFormProps.updateAgentPhoneNumbers();
            }
            showSuccessNotification(`PhoneNumber: ${formData.phoneNumber} Created Successfully and Synced Successfully`)
          }
          else {
            // showErrorNotification(resp.Message);
            showErrorNotification(resp?.Message?.name || resp?.Message?.phoneNumber || resp?.Message?.description || resp?.Message);
          }
        }
        )
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
  }

  async function updatePhoneNumber(formData) {
    formData.id = phoneNumberFormProps.data.id;
    
    
     if(formData.sip===true){
         
         
         if(typeof formData.phoneNumber !== "object"){
                formData={...formData,["phoneLocation"]:formData.sipNumberId+"~"+formData.sipLocationId}
                
                const phoneData = formData.phoneNumber.split("~");

            formData.phoneNumber=phoneData[0];
                
            }else{
            formData={...formData,["phoneLocation"]:formData.phoneNumber.id+"~"+formData.phoneNumber.sip_location_id}
            formData.phoneNumber=formData.phoneNumber.sip_number;
        }
            //formData.phoneLocation=formData.phoneNumber.sipLocation;
            
        }else{
            formData={...formData,["phoneLocation"]:""}
        }
    
    try {
      addAgentPhoneNumbersById(formData)
        .then((resp) => {
          if (resp.Status === "Success") {
            phoneNumberFormProps.updateAgentPhoneNumbers();
            closePhoneNumbersForm();
            showSuccessNotification(`PhoneNumber: ${formData.phoneNumber} Updated Successfully and Synced Successfully`)
          }
          else {
            // showErrorNotification(resp.Message);
            showErrorNotification(resp?.Message?.phoneNumber || resp?.Message?.name || resp?.Message?.description || resp?.Message);
          }
        })
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
  }

  const getValidationInViewMode = (e, value) => {
    phoneNumberFormProps.data.name = value;
    const regex = /^([+A-Za-z\d]((\.|@|_|-)?[A-Za-z\d])+)$/;
    const isMatch = regex.test(value);

    const digitCount = value.length;

    // Check the conditions for validation
    const isValidLength = value.length >= 2 && value.length <= 50;
    const isValidDigitCount = digitCount >= 2 && digitCount <= 50;

    // Final validation check
    if (isMatch && isValidLength && isValidDigitCount) {
      setValid(true);
    } else {
      setValid(false);
    }
  }

  const DataForm = (formProps) => {
      
    
          
//formProps.data.phoneNumber="34343";   
          
          let initData = formProps.data || {
      name: "",
      phoneNumber: "",
      priority: null,
      sip: false,
      phoneSkills: [],
      phoneLocation:""
    };
      
      //initData.phoneNumber="1234";
      
      
    const sipChange = (event, phoneNumber,setFieldValue) => {
        
        setFieldValue("phoneNumber", "");
            };

    return (
      <div className="ozManageNumbersAddForm">
        <Formik
          initialValues={initData}
          validationSchema={validationSchema}
          onSubmit={(inputValues) => {
            if (phoneNumberFormMode === ADMIN_FORM_MODES.add) {
              addPhoneNumber(inputValues);
            }
            else {
              if (valid === true) {
                updatePhoneNumber(inputValues);
              }
            }
          }}>
          {(formik) => (
            <form
              onSubmit={formik.handleSubmit}
              id="manageNumbersForm"
              className={"ozcwScrollbar oz-add-phone-number-form-container " + (phoneNumberFormMode === ADMIN_FORM_MODES.edit ? "oz-add-phone-number-form-edit-container" : "oz-add-phone-number-form-add-container")}>
              <Grid container={true} spacing={2}>
              {checkRoles("ROLE_SIP") &&
                  <Grid sx={{ marginLeft: "2px" }} item xs={12} sm={12} md={12} lg={12}>
                    <OZFCheckbox  onChange={(event) => sipChange(event, formik?.values?.phoneNumber,formik.setFieldValue)}  labelPosition="end" name="sip" label="SIP" />
                  </Grid>
                  }
              
                {(phoneNumberFormMode === ADMIN_FORM_MODES.add) &&
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                  
                    <OZInput
                      name="name"
                      label="Name *"
                      placeholder="Enter Name" />
                  </Grid>
                }
                <Grid item xs={12} sm={12} md={12} lg={12}> 
              
              
             
              
              {formik?.values?.sip === true &&
                    
                <OZSelect
                                name="phoneNumber"
                                options={sipOption}
                                optionLabel="sipNumberLabel"
                                label="Phone Number *"
                                placeholder="Enter Number"
                            /> 
                            }
              
              
              {formik?.values?.sip === false &&
                      
                <OZInput
                                name="phoneNumber"
                                label="Phone Number *"
                                placeholder="Enter Number"
                            /> 
                            }              
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <OZInput
                    name="priority"
                    label="Prority *"
                    placeholder="Enter Priority" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CustomAutoComplete
                    limitTags={3}
                    mode='Add'
                    name="phoneSkills"
                    placeholder={"Search Skill Name"}
                    labelHeader='Skills'
                    Datas={skillOption}
                    optionLabelName='skillName'
                    showCheckBox={true}
                    multiple={true}
                    showselectall="true"
                    popupIcon={null}
                  />
                </Grid>
                
              </Grid>

              {/* {(phoneNumberFormMode === ADMIN_FORM_MODES.edit) &&
                <div className="oz-add-phone-number-form-edit-button-container">
                  <Button
                    variant="outlined"
                    sx={{ ...buttonStyle, marginRight: '1.25rem' }}
                    onClick={closePhoneNumbersForm}>
                    Cancel
                  </Button>
                  <Button variant="contained" sx={buttonStyle} type="submit">
                    Save
                  </Button>
                </div>
              } */}
              {(phoneNumberFormMode === ADMIN_FORM_MODES.edit) &&
                <Grid container spacing={2} direction={'row'} flexGrow={1} flex justifyContent='end'>
                  <Grid item>
                    <Button sx={buttonStyle} onClick={closePhoneNumbersForm} variant="outlined">
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item >
                    <Button sx={buttonStyle} variant='contained' type="submit">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              }
            </form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <div>
      {phoneNumberFormMode === ADMIN_FORM_MODES.add ? (
        <OZDialog
          title="Add Agent Phone Number"
          saveButtonProps={{
            form: 'manageNumbersForm',
            type: 'submit'
          }}
          open={openPhoneNumberForm}
          mode={phoneNumberFormMode}
          onClose={closePhoneNumbersForm}>
          {phoneNumberFormMode === ADMIN_FORM_MODES.add && <DataForm />}
        </OZDialog>
      ) : (
        <OZDrawer
          open={openPhoneNumberForm}
          title={
            <div className="oz-add-phone-number-form-drawer-header-container">
              {/* {authPage(AdminPages.AGENT_NUMBERS_EDIT) && phoneNumberFormMode === "View" && <img ref={anchorRef} className="oz-add-phone-number-form-drawer-header-morevert" */}
              {(newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full')) && phoneNumberFormMode === "View" && <img ref={anchorRef} className="oz-add-phone-number-form-drawer-header-morevert"
                src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                alt="edit"
                onClick={onMorevertClickViewMenu}
              ></img>}
              <Menu
                anchorEl={viewFormDrawerMenu}
                open={Boolean(viewFormDrawerMenu)}
                onClose={handleCloseViewFormMenu}
                sx={{
                  maxHeight: "70vh",
                  "& .MuiPaper-root": {
                    backgroundColor: "white",
                    width: "160px",
                  },
                  "& .MuiMenu-paper": {
                    backgroundColor: "white",
                    boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                    border: "0.0625rem solid #E6E5E6",
                    borderRadius: "12px",
                    marginLeft: "-10.5625rem",
                    marginTop: '-1.5rem'
                  },
                }}
              >
                <ListItemButton
                  onClick={onClickDelete}
                  disabled={newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit')}
                  sx={styleListItemButton}>
                  Delete
                </ListItemButton>
              </Menu>
              {openDelete && <DeleteDialog
                open={openDelete}
                onClose={() => { setopenDelete(false) }}
                title="Delete Agent Number"
                content={`This will delete Phone Number:${phoneNumberFormProps.data.phoneNumber} permanently. You cannot undo this action.`}
                deleteFunction={deleteAgentPhoneNumberById} />
              }

              {phoneNumberFormMode === "View" ? <Tooltip title={phoneNumberFormProps?.data?.name}><div
                className="oz-managenumbers-drawer-title-heading"
                sx={{
                  fontWeight: 600,
                  fontSize: "1.125rem",
                  width: "11rem",
                  marginTop: '1px',
                  // marginLeft: authPage(AdminPages.AGENT_NUMBERS_EDIT) ? "" : "10px",
                  marginLeft: (newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full')) ? "" : "10px",
                }}
                flexGrow={1}>
                {phoneNumberFormProps.data.name}
              </div></Tooltip>
                :
                <TextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      backgroundColor: '#ffff',
                      fontSize: '18px',
                      color: "#212121",
                      height: '44px',
                      '&:hover': {
                        border: "none"
                      },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #D6D6D7'
                    },
                    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #D6D6D7'
                    }
                  }}
                  defaultValue={phoneNumberFormProps.data.name}
                  fullWidth
                  // onChange={(e) => { phoneNumberFormProps.data.name = e.target.value }}
                  onChange={(e) => { getValidationInViewMode(e, e.target.value) }}
                  error={!valid}
                  helperText={!valid ? "Name should be between 2 to 50 alphanumeric characters long allows prefix + and special characters like .,@,_,- and Name cannot start and end with Special characters." : ""}
                >
                </TextField>
              }

              {(newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full')) &&
              <>
              {phoneNumberFormMode === "Edit" &&
                <Typography
                  sx={{
                    fontSize: '0.875rem', marginRight: "-4px", paddingLeft: "8px", fontWeight: 400, color: "#3D8BF8", cursor: "pointer"
                  }}
                  onClick={() => setPhoneNumberFormMode('View')}>
                  Discard
                </Typography>
              }
              </>
              }
            </div>
          }
          onClose={closePhoneNumbersForm}
          // hideEditIcon={!authPage(AdminPages.AGENT_NUMBERS_EDIT)}
          hideEditIcon={!newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'View') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'data')}
          onEdit={() => setPhoneNumberFormMode(ADMIN_FORM_MODES.edit)}
          mode={phoneNumberFormMode} >
          {phoneNumberFormMode === ADMIN_FORM_MODES.view && (
            <ViewPhoneNumberForm data={phoneNumberFormProps.data} />
          )}
          {phoneNumberFormMode === ADMIN_FORM_MODES.edit && <DataForm data={phoneNumberFormProps.data} />}
        </OZDrawer >
      )}
    </div >
  );
};

export default AddPhoneNumberForm;
