import * as React from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { ReactComponent as FileDownloadOutlinedIcon } from '../../static/icons/download.svg';
import { Box, Menu, MenuItem } from '@mui/material';
import '../Widget-Download/Widget-Download.css';

type Props = {
	handleDownload?: any;
};

export default function ReportDownload({ handleDownload }: Props) {
	const { useState } = React;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const downloadCSV = (type: any) => {
		console.log(type);
	};

	const downloadXLSX = (type: any) => {
		console.log(type);
	};

	const handleClose = async (type: string = '') => {
		setAnchorEl(null);
		if (!type) return;
		if (type === 'CSV') {
			downloadCSV(type);
		} else if (type === 'XLS') {
			downloadXLSX(type);
		}
		handleDownload(type);
	};

	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	return (
		<>
			<Box onClick={handleMenuOpen} sx={{ display: 'flex', alignItems: 'center' }}>
				<FileDownloadOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
				<KeyboardArrowDownOutlinedIcon style={{ color: '#536580', fontSize: '10px' }} />
			</Box>
			<Menu
				id="download-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				open={open}
				onClose={() => handleClose()}
				className="custom-dropdown"
			>
				<MenuItem className="download-item" onClick={() => handleClose('CSV')}>
					<img src={`${process.env.PUBLIC_URL}/img/download-pdf-icon.svg`} width="18" alt="Download PDF Icon"></img> as CSV
				</MenuItem>
				<MenuItem className="download-item" onClick={() => handleClose('XLS')}>
					<img src={`${process.env.PUBLIC_URL}/img/download-jpg-icon.svg`} width="18" alt="Download JPG Icon"></img> as XLS
				</MenuItem>
			</Menu>
		</>
	);
}
