import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Drawer, Grid, IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { secondsToTime } from '../../utils/util';
import './AgentDetailsDrawer.css';

type Props = {
	toggleDrawer: any;
	isOpen: boolean | undefined;
	agentDetails?: any;
	selectedAgent?: any;
};

const agentDetailsItems = [
	{ label: 'First login time', key: 'FirstLoginTime' },
	{ label: 'Total idle time', key: 'TotalIdleTime', type: 'TIME' },
	{ label: 'Total login duration', key: 'TotalLoginDuration', type: 'TIME' },
	{ label: 'Total pause time', key: 'TotalPauseTime' },
	{ label: 'Total talk time', key: 'TotalTalkTime' },
	{ label: 'Total wrapup time', key: 'TotalWrapupTime' },
	{ label: 'Answer rate \n (From first login till now)', key: 'AnsweredPerc' }
];

const useStyles: any = makeStyles((theme: Theme) => ({
	breakdownContainer: {
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column'
	},
	breakdownHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	headerText: {
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '21px',
		color: '#212121'
	},
	drawerGutter: {
		paddingTop: '74px'
	},
	drawerContent: {
		minWidth: 350,
		fontSize: '14px',
		lineHeight: 2
	}
}));

const AgentDetailsDrawer = ({ isOpen, toggleDrawer, agentDetails, selectedAgent }: Props) => {
	const classes = useStyles();

	return (
		<Drawer open={isOpen} anchor="right" onClose={() => toggleDrawer(false)}>
			<Box sx={{ width: 456 }} className={classes.breakdownContainer}>
				<div className={classes.drawerGutter}>
					<div className={classes.breakdownHeader}>
						<div>
							<b className="drawer-title">Agent Details</b>
							<span className="drawer-title-badge">Live</span>
						</div>
						<IconButton aria-label="delete" size="small" onClick={() => toggleDrawer(false)}>
							<CloseIcon style={{ fontSize: '22px', color: '#99a0a8' }} />
						</IconButton>
					</div>
					<div>
						<span className="drawer-subtitle">{selectedAgent?.AgentName}</span>
					</div>
					<hr style={{ marginTop: '20px' }} />
				</div>
				<Box sx={{ p: 2 }} className={classes.drawerContent}>
					<div>
						{agentDetailsItems.map((item: any, idx: number) => (
							<Grid key={idx} container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item xs={7}>
									{item.label}
								</Grid>
								<b>
									<span>{item.type ? secondsToTime(agentDetails[0]?.[item.key]) : agentDetails[0]?.[item.key]}</span>
								</b>
							</Grid>
						))}
					</div>
				</Box>
			</Box>
			<div style={{ margin: '15px' }}>
				<b>Suggestion</b>
			</div>
			<div className="drawer-text-container">
				<div className="drawer-info">
					<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
						<Grid item xs={1}>
							<InfoOutlinedIcon />
						</Grid>
						<Grid item>
							<span>Agent pause time has increased</span>
						</Grid>
					</Grid>
				</div>
				<div className="drawer-suggestion-action">
					<Button color="primary" variant="contained" sx={{ borderRadius: '8px' }}>
						Alert Agent
					</Button>
				</div>
			</div>
			<div style={{ marginTop: '200px' }}></div>
		</Drawer>
	);
};

export default AgentDetailsDrawer;
