import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ClickAwayListener, Grow, MenuList, Paper, Popper } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HISTORIC_REPORTS_LIST, LIVE_REPORTS_LIST } from '../../utils/constants';
import '../ReportsDrawer/ReportsDrawer.css';
import Menu from '@mui/material/Menu';
import { AdminPages, authPage } from '../../services/page-service';

type props = {
	// isOpen: boolean;
	isOpen: any;
	handleReportsDrawer: any;
};

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0
	},
	'&:before': {
		display: 'none'
	}
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)'
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1)
	}
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export default function ReportsDrawer({ isOpen, handleReportsDrawer }: props) {
	const location = useLocation();
	const [expanded, setExpanded] = React.useState<string | false>('Dashboard');
	const [currentTab, setCurrentTab] = React.useState('1');
	const [currentLocation, setCurrentLocation] = React.useState<string>('');
	const [isLiveTabSelected, setIsLiveTabSelected] = React.useState(true);
	const [isHistoricTabSelected, setIsHistoricTabSelected] = React.useState(false);

	const liveTabBorder = isLiveTabSelected ? 'tab-selected' : '';
	const historicTabSelected = isHistoricTabSelected ? 'tab-selected' : '';

	const handleLiveTab = () => {
		setIsLiveTabSelected(true);
		setIsHistoricTabSelected(false);
	};

	const handleHistoricTab = () => {
		setIsLiveTabSelected(false);
		setIsHistoricTabSelected(true);
	};
	
	const handleDrawer = (): any => {
		localStorage.removeItem("ps");
		handleReportsDrawer(!isOpen);
	};

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setCurrentTab(newValue);
	};

	const handleAccordianChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
		setExpanded(newExpanded ? panel : false);
	};

	const locationActiveClass = (route: string): string => {
		return currentLocation.includes(route) ? 'active' : '';
	};

	React.useEffect(() => {
		setCurrentLocation(location.pathname);
	}, [location]);

	return (
		// <>
		// 	<Drawer sx={{
		// 		"& .MuiDrawer-paper": {
		// 			left: "88px",
		// 			width: "241.17px"
		// 		}
		// 	}} ModalProps={{ componentsProps: { backdrop: { style: { opacity: 0 } } } }} open={!!isOpen} anchor="left" className="reports-drawer-container" onClose={handleDrawer}>
		// 		<div className="live-historic-tabs-container" onMouseLeave={handleDrawer}>
		// 			<Box sx={{ width: '100%', typography: 'body1' }}>
		// 				<TabContext value={currentTab}>
		// 					<Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="box-contianer">
		// 						<TabList onChange={handleTabChange} aria-label="lab API tabs example" className="tabs-holder">
		// 							<Tab label="Live" value="1" className={`live-button ${liveTabBorder}`} onClick={handleLiveTab} />
		// 							<Tab label="Historic" value="2" className={`live-button no-border ${historicTabSelected}`} onClick={handleHistoricTab} />
		// 						</TabList>
		// 					</Box>
		// 					<TabPanel value="1" className="live-tab-content-container">
		// 						<div className="live-reports-bar">
		// 							{LIVE_REPORTS_LIST.map((item: any, index: number) => {
		// 								return (
		// 									<Link to={item.route} onClick={handleDrawer} className={`live-link-styling ${locationActiveClass(item.route)}`} key={index}>
		// 										{item.name}
		// 									</Link>
		// 								);
		// 							})}
		// 						</div>
		// 					</TabPanel>
		// 					<TabPanel value="2" className="historic-tab-content-container">
		// 						<div className="accordian-container">
		// 							{HISTORIC_REPORTS_LIST.map((item: any, index: number) => {
		// 								if (item.type === 'LINK') {
		// 									return (
		// 										<Link to={item.route} onClick={handleDrawer} className={`historic-link-styling ${locationActiveClass(item.route)}`} key={index}>
		// 											{item.name}
		// 										</Link>
		// 									);
		// 								}
		// 								return (
		// 									<Accordion key={index} expanded={expanded === item.name} onChange={handleAccordianChange(item.name)} className="accordian-heading">
		// 										<AccordionSummary aria-controls={`${item.name}-content`} id={`${item.name}-header`} className="accordian-summary">
		// 											<span className="accordian-item">{item.name}</span>
		// 										</AccordionSummary>
		// 										{item.items.map((subItem: any, childIdx: number) => {
		// 											return (
		// 												<AccordionDetails key={`${index}-${childIdx}`} className="expanded-item">
		// 													<Link onClick={handleDrawer} className={`accordian-item acc-sub-item ${locationActiveClass(subItem.route)}`} to={subItem.route}>
		// 														{subItem.name}
		// 													</Link>
		// 												</AccordionDetails>
		// 											);
		// 										})}
		// 									</Accordion>
		// 								);
		// 							})}
		// 							{/* <Accordion expanded={expanded === 'panel1'} onChange={handleAccordianChange('panel1')}>
		// 								<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
		// 									<Typography>Dashboard</Typography>
		// 								</AccordionSummary>
		// 								<AccordionDetails>
		// 									<Typography>System Analytics</Typography>
		// 								</AccordionDetails>
		// 							</Accordion> */}
		// 						</div>
		// 					</TabPanel>
		// 				</TabContext>
		// 			</Box>
		// 		</div>
		// 	</Drawer>
		// </>

		<Popper
			open={!!isOpen}
			role={undefined}
			placement="right"
			transition
			disablePortal
			sx={{
				left: '83px !important',
				top: '76px !important',
			}}
		>
			{({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					style={{
						transformOrigin:
							placement === 'bottom-start' ? 'left right' : 'left bottom',
					}}
				>
					<Paper sx={{
						marginLeft: '7px',
						borderRadius: '8px',
						backgroundColor: 'white',
						boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
						border: '1px solid #E6E5E6',
						// width: "241px",
						minHeight: '78vh !important',
						maxHeight: '83vh !important',
						overflow: 'scroll'
					}} onMouseLeave={handleDrawer}>
						<ClickAwayListener onClickAway={handleDrawer}>
							<MenuList
								id="reportsMenu"
								aria-labelledby="reportsModule"
								className="reports-drawer-container"
							>

								<div className="live-historic-tabs-container"
								// onMouseLeave={handleDrawer}
								>
									<Box sx={{ width: '100%', typography: 'body1' }}>
										<TabContext value={currentTab}>
											<Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="box-contianer">
												<TabList onChange={handleTabChange} aria-label="lab API tabs example" className="tabs-holder">
													<Tab label="Live" value="1" className={`live-button ${liveTabBorder}`} onClick={handleLiveTab} />
													<Tab label="Historic" value="2" className={`live-button no-border ${historicTabSelected}`} onClick={handleHistoricTab} />
												</TabList>
											</Box>
											<TabPanel value="1" className="live-tab-content-container">
												<div className="live-reports-bar">
													{LIVE_REPORTS_LIST?.map((item: any, index: number) => {
														return (
															<Link to={item.route} onClick={handleDrawer} className={`live-link-styling ${locationActiveClass(item.route)}`} key={index}>
																{item.name}
															</Link>
														);
													})}
												</div>
											</TabPanel>
											<TabPanel value="2" className="historic-tab-content-container">
												<div className="accordian-container">
													{HISTORIC_REPORTS_LIST.map((item: any, index: number) => {
														if (item.type === 'LINK') {
															return (
																<Link to={item.route} onClick={handleDrawer} className={`historic-link-styling ${locationActiveClass(item.route)}`} key={index}>
																	{item.name}
																</Link>
															);
														}
														return (
															<Accordion key={index} expanded={expanded === item.name} onChange={handleAccordianChange(item.name)} className="accordian-heading">
																<AccordionSummary aria-controls={`${item.name}-content`} id={`${item.name}-header`} className="accordian-summary">
																	<span className="accordian-item">{item.name}</span>
																</AccordionSummary>
																{item.items.map((subItem: any, childIdx: number) => {
																	return (
																		<AccordionDetails key={`${index}-${childIdx}`} className="expanded-item">
																			<Link onClick={handleDrawer} className={`accordian-item acc-sub-item ${locationActiveClass(subItem.route)}`} to={subItem.route}>
																				{subItem.name}
																			</Link>
																		</AccordionDetails>
																	);
																})}
															</Accordion>
														);
													})}
													{/* <Accordion expanded={expanded === 'panel1'} onChange={handleAccordianChange('panel1')}>
										<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
											<Typography>Dashboard</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography>System Analytics</Typography>
										</AccordionDetails>
									</Accordion> */}
												</div>
											</TabPanel>
										</TabContext>
									</Box>
								</div>
							</MenuList>
						</ClickAwayListener >
					</Paper >
				</Grow >
			)
			}
		</Popper >
	);
}
