const DateFormat = "MM/DD/YYYY";
const DateTimeFormat = "MM/DD/YYYY HH:mm:ss";
const SystemDateFormat = "yyyy-MM-DD";

// Prod-properties (InCcaas)
const LOGIN_URL = 'https://api.cxhub.ozonetel.com/auth/login';
const BASE_URL = 'https://api.cxhub.ozonetel.com/dashboardApi';
const BASE_API_URL = 'https://api.cxhub.ozonetel.com/reportApi/endpoint';
const LIVE_BASE_URL = 'https://api.cxhub.ozonetel.com/liveReportApi';
const ADMIN_BASE_URL = 'https://api.cxhub.ozonetel.com/ca-admin-Api/CloudAgentAPI';
//const AGENT_LOGIN_URL = 'https://agent.beta.ccaas.ozonetel.com/login'
const CRM_REDIRECT_URL = "https://crm.auth.ozonetel.com/auth/zoho/integration";
const LOGIN_PAGE_BACKGROUND_IMG_URL = "https://s3.ap-south-1.amazonaws.com/ca.cdn/";
const UPDATE_PASSWORD_URL = 'https://api.cxhub.ozonetel.com/updatepassword';
const FORGOT_PASSWORD_URL = 'https://api.cxhub.ozonetel.com/resetpassword';
const IVR_FLOW_URL = "https://ivr.cxhub.ozonetel.com/ivrdd";
const IMAGE_UPLOAD_URL = 'https://api.ccaas.ozonetel.com';
let AGENT_LOGIN_URL = ''
const currentLocation = window.location.href;
if(currentLocation.includes("engage")) {
    AGENT_LOGIN_URL = "https://cxagent.engage.personacloud.net/login";
} else if (currentLocation.includes("crexendocx")) {
    AGENT_LOGIN_URL = "https://cxagent.crexendocx.com/login";
} else {
    AGENT_LOGIN_URL ="https://agent.cxhub.ozonetel.com/login";
}
const APP_URL = 'http://10.244.11.225/DDParser/v1/index.php?tag_id=start&flow_id='

export { LOGIN_PAGE_BACKGROUND_IMG_URL, LOGIN_URL, BASE_URL, BASE_API_URL, LIVE_BASE_URL, ADMIN_BASE_URL, CRM_REDIRECT_URL, AGENT_LOGIN_URL, IVR_FLOW_URL, DateTimeFormat, DateFormat, SystemDateFormat, FORGOT_PASSWORD_URL, UPDATE_PASSWORD_URL, APP_URL, IMAGE_UPLOAD_URL }