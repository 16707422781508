import React, { useState, useEffect, useMemo, useRef } from "react";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import { Typography, Menu, Box, ListItemButton, Divider, Button, Tooltip } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { addBlockGroupsById, deleteAgentPhoneNumbersById, deleteDialoutNumbersById, deleteTranferNumbersById, getAgentPhoneNumberById, getAgentPhoneNumbers, getBlockGroupById, getBlockGroups, getDialoutNumberById, getDialoutNumbers, getTransferNumberById, getTransferNumbers, importBulkFWPNumbers, uploadEditBulkFWPNumbers, getBlockGroupFilters, getBlockGroupData, deleteBlockNumbers, addBlockGroupNew, updateBlockGroups } from "../../../services/api-service";
import AddPhoneNumberForm from "./addPhoneNumberForm";
import AddDialoutNumberForm from "./addDialoutNumberForm";
import AddTransferNumberForm from "./addTransferNumberForm";
import AddBlockGroupForm from "./addBlockGroupForm";
import "./manageNumber.css";
import CustomizedCheckbox from "../../../components/admin/common/CustomCheckBox";
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import { checkRoles } from "../../../utils/util";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import OZDialog from "../../../components/admin/common/OZDialog";
import { NewAdminPages, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";
import { OZCustomToggleButton } from "../../../components/admin/common/OZCustomToggleButton";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import { getAdminSettingsList, getSettingsList } from "../../../services/api-service";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { apiResponse } from "../../../utils/constants";
import PopupState from 'material-ui-popup-state';
import moment from 'moment';
import { createFilterIds } from '../../../utils/util';
import { DateTimeFormat } from "../../../utils/properties";
import AddToGroupForm from "./addToGroupForm"

function ChooseForm(chooseMenuProps) {
  const handleMenuClose = () => {
    chooseMenuProps.close("10");
  };

  return (
    <div>
      {chooseMenuProps.menuType === "1" && (
        <AddPhoneNumberForm updateAgentPhoneNumbers={chooseMenuProps.updatePhoneNumber} currentTab={chooseMenuProps.currentTab} onClose={handleMenuClose} />
      )}
      {chooseMenuProps.menuType === "2" && (
        <AddDialoutNumberForm updateDialoutNumbers={chooseMenuProps.updateDialoutNumber} currentTab={chooseMenuProps.currentTab} onClose={handleMenuClose} />
      )}
      {chooseMenuProps.menuType === "3" && (
        <AddTransferNumberForm updateTransferNumbers={chooseMenuProps.updateTransferNumber} currentTab={chooseMenuProps.currentTab} onClose={handleMenuClose} />
      )}
      {chooseMenuProps.menuType === "4" && (
        <AddBlockGroupForm updateBlockGroups={chooseMenuProps.updateBlockGroup} currentTab={chooseMenuProps.currentTab} onClose={handleMenuClose} />
      )}
    </div>
  );
}



function ManageNumbers() {
  let menuValues = []
  let tabValues = []

  // if (authPage(AdminPages.AGENT_NUMBERS_VIEW)) {
  //   tabValues.push({ id: "1", name: "Agent Phone" });
  //   if (authPage(AdminPages.AGENT_NUMBERS_EDIT)) {
  //     menuValues.push({ id: "1", name: "Agent Phone Number" })
  //   }
  // }
  // if (authPage(AdminPages.DAILOUT_NUMBERS_VIEW)) {
  //   tabValues.push({ id: "2", name: "Dialouts" })
  //   if (authPage(AdminPages.DAILOUT_NUMBERS_EDIT)) {
  //     menuValues.push({ id: "2", name: "Dialout Number" })
  //   }
  // }
  // if (authPage(AdminPages.TRANSFER_NUMBERS_VIEW)) {
  //   tabValues.push({ id: "3", name: "Transfer" })
  //   if (authPage(AdminPages.TRANSFER_NUMBERS_EDIT)) {
  //     menuValues.push({ id: "3", name: "Transfer Number" })
  //   }
  // }
  // if (authPage(AdminPages.BLOCKED_NUMBERS_VIEW)) {
  //   tabValues.push({ id: "4", name: "Blocked" })
  //   menuValues.push({ id: "4", name: "Block Number" })
  // }

  if ((newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'View'))) {
    tabValues.push({ id: "1", name: "Agent Phone" });
    tabValues.push({ id: "2", name: "Dialouts" })
    tabValues.push({ id: "3", name: "Transfer" })
    tabValues.push({ id: "4", name: "Blocked" })
    if ((newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full'))) {
      menuValues.push({ id: "1", name: "Agent Phone Number" })
      menuValues.push({ id: "2", name: "Dialout Number" })
      menuValues.push({ id: "3", name: "Transfer Number" })
      menuValues.push({ id: "4", name: "Block Number" })
    }
  }


  const [rowData, setRowdata] = useState([]);
  const [onRowClickNumberData, setOnRowClickNumberData] = useState({}); // Onclick Row Dataz
  const [columnDefs, setColumnDefs] = useState([]);
  const [showNumbersForm, setShowNumbersForm] = useState({ isNew: false, show: false });
  const [tabValue, setTabValue] = useState(tabValues[0]?.id ? tabValues[0].id : '1'); // For changing Column definitions for different Tabs
  const [addFormtype, setAddFormType] = useState(menuValues[0]?.id ? menuValues[0].id : '1');   // State for Opening different ADD Form
  const [numbersTableDataRef, setNumbersTableDataRef] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [loading, setLoading] = useState(false);
  const [settingsData, setSettingsData] = useState([])
  const [filters, setFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [fullRowData, setFullRowData] = useState([]);
  const [newRowSize, setNewRowSize] = useState(localStorage.getItem("ps") !== null ? localStorage.getItem("ps"):10)
  const [newPageNo, setNewPageNo] = useState(1)
  const [totalRows, setTotalRows] = useState(undefined);

  function handleAddButtonClick(formValue) {
    setOpenAddDialog(!openAddDialog)
    setTabValue(formValue !== undefined ? formValue : tabValue)
    changeColumnDefinitions(formValue !== undefined ? formValue : tabValue)
    setFullRowData([]);
  };

  const handleAddButtonMenuValue = (event, newMenuValue) => {
    setTabValue(newMenuValue);
    changeColumnDefinitions(newMenuValue);
    setSelectedFilters([]);
    setFullRowData([])
  };

  // eslint-disable-next-line
  useEffect(() => {
    changeColumnDefinitions(tabValue);
    getSettingsData();
    localStorage.removeItem("BlockGroupDescription");
    localStorage.removeItem("BlockGroups");
    localStorage.removeItem("BlockGroupName");
    localStorage.removeItem("BlockNumberString");
    localStorage.removeItem("expiryDate");
    localStorage.removeItem("checkUpdateExpiryAdd");
    // eslint-disable-next-line
    retrieveFilters();
    setFullRowData([]); 
  }, []);

  const gridElements = useMemo(
    () => ({
      fontSize: "12px", fontWeight: 400, color: "#000000"
    }),
    []
  );

  const closeManageNumbersForm = () => {
    setShowNumbersForm({ isNew: false, show: false });
  };

  function ChecBoxCellRendered(columnData) {
    return (
      <CustomizedCheckbox
        sx={{ paddingLeft: '0.75rem' }}
        edge="start"
        checked={columnData?.data?.sip || false}
        disbaled={!newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit')}
      />
    )
  }

  const ChangeColumnDefinitions = (columnData) => {
    return <Typography sx={{ ...gridElements, color: "#3D8BF8", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{columnData.value}</Typography>;
  };

  function PriorityCellRendered(columnData) {
    return <Typography sx={gridElements}>{columnData.value}</Typography>;
  }

  function PhoneSkillsNamesFormatter(phoneSkillsData) {
    const phoneSkillNames = phoneSkillsData?.data?.skillName.split(',');

    let phoneSkillsName;
    if (phoneSkillNames.length > 2) {
      const agentGroupNamesLastTwo = phoneSkillNames.slice(-2).join(',');
      const remainingCount = phoneSkillNames.length - 2;
      const remainingAgentGroupNames = phoneSkillNames.slice(0, -2).join(',');
      phoneSkillsName = (
        <>
          <div>
            {agentGroupNamesLastTwo}
            <span key="badge" className="badge" title={remainingAgentGroupNames}>
              {`+${remainingCount}`}
            </span>
          </div>
        </>
      )
    }
    else {
      phoneSkillsName = phoneSkillNames.join(',');
    }
    return (<Typography style={{ ...gridElements }}>{phoneSkillsName}</Typography>)
  }

  //formatter for , seperated values
  function groupNamesFormatter(blockNumbersData) {
    const minWidth = blockNumbersData?.colDef?.minWidth || 300;
    let phoneSkillsName;

    let modifiedData = blockNumbersData?.data || null;
    const blockGroups = blockNumbersData?.data?.blockgroups || [];  
    if(Array.isArray(blockGroups)){
      const groupNames = blockGroups.map(group => group.name);
      const modifiedGroupNames = groupNames.join(', ');
      modifiedData.blockgroups = modifiedGroupNames;
    }

    if(modifiedData.blockgroups){
      const groupNames =modifiedData?.blockgroups.split(',');
      const availableSpace = Math.max(minWidth, 0); // Ensure minWidth is non-negative

      if (groupNames.length > 2) {
        let totalWidth = 0;
        let visibleGroupNames = [];
        for (let i = 0; i < groupNames.length; i++) {
          totalWidth += groupNames[i].length * 10; // Assuming each character takes 10px width
          if (totalWidth <= availableSpace) {
            visibleGroupNames.push(groupNames[i]);
          } else {
            break;
          }
        }

        const remainingCount = groupNames.length - visibleGroupNames.length;
        const remainingAgentGroupNames = groupNames.slice(visibleGroupNames.length).join(',');
        phoneSkillsName = (
          <>
            <div>
              {visibleGroupNames.join(', ')}
              {remainingCount > 0 && (
                <span key="badge" className="badge" title={remainingAgentGroupNames}>
                  {`+${remainingCount}`}
                </span>
              )}
            </div>
          </>
        );
      } else {
        phoneSkillsName = groupNames.join(',');
      }
    }
    return (<Typography style={{ ...gridElements, color: '#3D8BF8' }}>{phoneSkillsName}</Typography>)
  }

  function MoreVertColumnRenderer(moreVertProps) {
    const [deleteMenuAnchor, setDeleteMenuAnchor] = useState(false);
    const anchorRef = useRef();
    const [openDelete, setopenDelete] = useState(false)


    const onClickGridMoreVertIcon = () => {
      setTimeout(() => setDeleteMenuAnchor(anchorRef?.current), 1);
    };

    const handleCloseDeleteMenu = () => {
      setDeleteMenuAnchor(false);
    };

    function onClickDelete() {
      setDeleteMenuAnchor(false);
      setopenDelete(true)
    }

    function deletePhoneNumbers(deletePhoneNumber, refrershGrid, number) {
      try {
        deletePhoneNumber(moreVertProps.data.id)
          .then((resp) => {
            if (resp.Status === "Success") {
              refrershGrid();
              showSuccessNotification(`Number: ${number} Deleted Successfully`)
            }
            else {
              showErrorNotification(JSON.stringify(resp.Message));
            }
          })
          .catch((e) => {
            showErrorNotification(JSON.stringify(e));
          });
      } catch (e) {
        showErrorNotification(JSON.stringify(e));
      }
    }

    const styleListItemButton = useMemo(
      () => ({
        "&.MuiButtonBase-root": {
          color: '#212121',
          fontFamily: "Roboto",
          fontSize: "12px",
          width: '105px',
          fontWeight: 400,
          height: '14px',
          lineHeight: "14px",
        },
        '&:hover': {
          borderRadius: "4px",
        },
      }),
      []
    );

    async function deleteNumbersUsingId() {
      if (moreVertProps.data.phoneNumber !== undefined) {
        deletePhoneNumbers(deleteAgentPhoneNumbersById, getAgentPhoneNumber, moreVertProps.data.phoneNumber)
      }
      else {
        if (moreVertProps.data.dialOutName !== undefined) {
          deletePhoneNumbers(deleteDialoutNumbersById, getDialoutNumber, moreVertProps.data.dialOutName)
        }
        else {
          deletePhoneNumbers(deleteTranferNumbersById, getTransferNumber, moreVertProps.data.transferNumber)
        }
      }
    }
    return (
      <div className="oz-manage-numbers-morevert-column-render">
        {(moreVertProps.data.dialOutName !== undefined || moreVertProps.data.transferName !== undefined) &&
          <CustomizedCheckbox
            sx={{ paddingLeft: '0.75rem' }}
            edge="start"
            checked={moreVertProps?.data?.sip || false}
          />}
        {(moreVertProps.data.phoneNumber !== undefined || moreVertProps.data.description !== undefined) &&
          <div component="span" style={{ ...gridElements, marginLeft: "2px" }}> {moreVertProps.data.priority ? moreVertProps.value : 0}</div>
        }
        <img className="delMoreIcon"
          onClick={onClickGridMoreVertIcon}
          src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="option"
        >
        </img>
        <div ref={anchorRef}>
          <Menu anchorEl={deleteMenuAnchor} open={Boolean(deleteMenuAnchor)} onClose={handleCloseDeleteMenu} sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#FFFFFF",
              width: "103px",
              height: "35px",
            },
            "& .MuiMenu-paper": {
              backgroundColor: "#FFFFFF",
              boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
              border: "0.0625rem solid #E6E5E6",
              borderRadius: "12px",
              marginLeft: "-0.0625rem",
              marginTop: "0rem",
            },
          }}>
            <ListItemButton
              onClick={onClickDelete}
              sx={styleListItemButton} >
              Delete
            </ListItemButton>
          </Menu>
          {openDelete && <DeleteDialog
            open={openDelete}
            onClose={() => { setopenDelete(false) }}
            title={(moreVertProps.data.phoneNumber !== undefined) ? "Delete Agent Phone Number" : (moreVertProps.data.dialOutName !== undefined) ? "Delete Dialout Number" : "Delete Transfer Number"}
            content={(moreVertProps.data.phoneNumber !== undefined) ? `This will delete Phone Number:${moreVertProps.data.phoneNumber} permanently. You cannot undo this action.` : (moreVertProps.data.dialOutName !== undefined) ? `This will delete Dialout Number:${moreVertProps.data.dialOutNumber} permanently. You cannot undo this action.` : `This will delete Transfer Number:${moreVertProps.data.transferNumber} permanently. You cannot undo this action.`}
            deleteFunction={deleteNumbersUsingId} />}
        </div>
      </div>
    );
  }

  function DeleteIconGridClick(deletegrid) {
    console.log("here came on click grid", deletegrid);
    // return <Typography sx={gridElements}>{columnData.value}</Typography>;
  }

  function MenuOnBlockNumberGridClick(moreVertProps) {
    const [deleteMenuAnchor, setDeleteMenuAnchor] = useState(false);
    const anchorRef = useRef();
    const [openDelete, setopenDelete] = useState(false)

    const onClickGridMoreVertIcon = () => {
      setTimeout(() => setDeleteMenuAnchor(anchorRef?.current), 1);
    };

    const handleCloseDeleteMenu = () => {
      setDeleteMenuAnchor(false);
    };

    function onClickDelete(e) {
      console.log("delete row data", moreVertProps.data)
      setDeleteMenuAnchor(false);
      setopenDelete(true)
    }

    function deletePhoneNumbers(deletePhoneNumber, refrershGrid, number) {
      try {
        deletePhoneNumber(moreVertProps.data.id)
          .then((resp) => {
            if (resp.Status === "Success") {
              refrershGrid();
              showSuccessNotification(`Number: ${number} Deleted Successfully`)
            }
            else {
              showErrorNotification(JSON.stringify(resp.Message));
            }
          })
          .catch((e) => {
            showErrorNotification(JSON.stringify(e));
          });
      } catch (e) {
        showErrorNotification(JSON.stringify(e));
      }
    }

    const styleListItemButton = useMemo(
      () => ({
        "&.MuiButtonBase-root": {
          color: '#212121',
          fontFamily: "Roboto",
          fontSize: "12px",
          width: '105px',
          fontWeight: 400,
          height: '14px',
          lineHeight: "14px",
        },
        '&:hover': {
          borderRadius: "4px",
        },
      }),
      []
    );

    return (
      // const date = new Date(params.value);
      // const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });

      <div className="oz-manage-numbers-morevert-column-render">
        {moreVertProps?.data?.description ? moreVertProps?.data?.description : ''}
        {(newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'data') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit')) &&
        <img className="delMoreIcon"
          onClick={onClickGridMoreVertIcon}
          src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="option"
        >
        </img>
        }
        <div ref={anchorRef}>
          <Menu anchorEl={deleteMenuAnchor} open={Boolean(deleteMenuAnchor)} onClose={handleCloseDeleteMenu} sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#FFFFFF",
              width: "103px",
              height: "35px",
            },
            "& .MuiMenu-paper": {
              backgroundColor: "#FFFFFF",
              boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
              border: "0.0625rem solid #E6E5E6",
              borderRadius: "12px",
              right: '1.5%',
              marginLeft: "auto",
              marginTop: "0rem",
            },
          }}>
            <ListItemButton
              onClick={onClickDelete}
              disabled={newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'data') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit')}
              sx={styleListItemButton} >
              Delete
            </ListItemButton>
          </Menu>
          {openDelete && <DeleteDialog
            open={openDelete}
            onClose={() => { setopenDelete(false) }}
            title={"Delete Block Number"}
            content={ `This will delete Blocked Number:${moreVertProps.data.blockedNumber} permanently. You cannot undo this action.`}
            deleteFunction={() => deleteBlockNumber(moreVertProps.data)} />}
        </div>
      </div>
    );
  }

  async function newupdateBlockGroup(id) {
    console.log("here comes value", id);
    try {
      getBlockGroupById(id).then((resp) => {
        setOnRowClickNumberData(resp.Data[0]);
      }).catch((e) => {
        showErrorNotification(JSON.stringify(e));
        setOnRowClickNumberData({});
      });
    } catch (e) {
      console.log(e)
      showErrorNotification(JSON.stringify(e));
    }
  }

  async function updateBlockGroup(data) {
    try {
      addBlockGroupsById(data)
        .then((resp) => {
          if (resp.Status === "Success") {
            updateBlockGroup();
            getBlockGroupById(data.id)
              .then((resp) => {
                setOnRowClickNumberData(resp.Data[0]);
              })
              .catch((e) => {
                showErrorNotification(JSON.stringify(e));
                setOnRowClickNumberData({});
              });
            showSuccessNotification(`Block Group: ${data.name} Updated Successfully`)
          }
          else {
            console.log(resp.Message)
            showErrorNotification(JSON.stringify(resp.Message));
          }
        }
        )
        .catch((e) => {
          console.log(e)
        });
    } catch (e) {
      console.log(e)
      showErrorNotification(JSON.stringify(e));
    }
  }
  function getNumbersByID(getNumberByIdFunction, phoneNumberID) {
    setOnRowClickNumberData({});
    setLoading(true);
    try {
      getNumberByIdFunction(phoneNumberID)
        .then((resp) => {
          setOnRowClickNumberData(resp.Data[0]);
          setLoading(false);
        })
        .catch((e) => {
          showErrorNotification("View data is not available");
          setOnRowClickNumberData({});
          setLoading(false);
        });
    } catch (e) {
      console.log(e)
      setLoading(false);
      showErrorNotification("View Data is not available");
    }
  }

  // Saving User Selected Row Data in data 
  async function onClickRow(rowItem) {
    console.log("rowItem", rowItem)
    setShowNumbersForm({ isNew: false, show: true });
    //call repective rowdata from api by ID
    switch (tabValue) {
      case "1":
        getNumbersByID(getAgentPhoneNumberById, rowItem.data.id)
        break;
      case "2":
        getNumbersByID(getDialoutNumberById, rowItem.data.id)
        break;
      case "3":
        getNumbersByID(getTransferNumberById, rowItem.data.id)
        break;
      case "4":
        getNumbersByID(getBlockGroupById, rowItem.data.id)
        break;
      default: showErrorNotification("Row Data is not Available");
    }
  }

  function addSelectedRowData(selectedData) {
    console.log("yes it came", selectedData);
    setFullRowData(selectedData);
  }
  function setNewRows(rowsPerPage) {
    console.log("yes it came rowsPerPage", rowsPerPage);
    setNewRowSize(rowsPerPage);
    setPageNo(1);
  }
  function setPageNo(PageNo) {
    setNewPageNo(PageNo)
    console.log("yes it came PageNo", PageNo);
    // setFullRowData(rowsPerPage);
  }

  async function getAgentPhoneNumber() {
    setLoading(true);
    try {
      getAgentPhoneNumbers()
        .then((resp) => {
          console.log("here ", resp.Data);
          setRowdata(resp.Data);
          setLoading(false);
        })
        .catch((e) => {
          showErrorNotification("Failed to fetch Agent Phone Number data");
          setRowdata([]);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      showErrorNotification(JSON.stringify(e));
    }
  }

  async function getDialoutNumber() {
    setLoading(true);
    try {
      getDialoutNumbers()
        .then((resp) => {
          setRowdata(resp.Data);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setRowdata([]);
          showErrorNotification("Failed to fetch Dialout Number data");

        });
    } catch (e) {
      setLoading(false);
      showErrorNotification(JSON.stringify(e));
    }
  }

  async function getTransferNumber() {
    setLoading(true);
    try {
      getTransferNumbers()
        .then((resp) => {
          setRowdata(resp.Data);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          showErrorNotification("Failed to fetch Transfer Number data");
          setRowdata([]);
        });
    } catch (e) {
      setLoading(false);
      showErrorNotification(JSON.stringify(e));
    }
  }

  async function deleteBlockNumber(data) {
    setLoading(true);

    try {
      deleteBlockNumbers(data)
        .then((resp) => {
          if (resp?.Status === "Success") {
            showSuccessNotification(`Block Numbers Deleted Successfully`)
            getBlockGroups({});
          }
          else {
            showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message || "Failed to delete Block Number data");
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          showErrorNotification("Failed to delete Block Number data");

        });
    } catch (e) {
      setLoading(false);
      showErrorNotification(JSON.stringify(e));
    }
  }

  const retrieveFilters = async () => {
    try {
      await getBlockGroupFilters()
        .then((resp) => {
          if (resp && resp.Status == "Success") {
            const filterData = resp.Data;
            setFilters(filterData);
          } else {
            setFilters([]);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          showErrorNotification("Failed to fetch Block Group filter data");
          setFilters([]);
        });
    } catch (e) {
      setLoading(false);
      showErrorNotification(JSON.stringify(e));
      setFilters([]);
    }
  };

  const handleApplyFilters = ({ selectedFilters }) => {
    console.log("selectedFilters", selectedFilters)
    // setController({ //on every filter change make the controller start for starting
    // 	...controller,
    // 	page: 1,
    // 	toLimit: controller.toLimit,
    // 	fromLimit: 0
    // });
    //modifying date format 
    if(selectedFilters?.startDate){
      let newStartDate = moment(selectedFilters.startDate, 'DD-MM-YYYY HH:mm:ss');
      selectedFilters.startDate = newStartDate.format("YYYY-MM-DD HH:mm:ss");
    }
    if(selectedFilters?.endDate){
      let newStartDate = moment(selectedFilters.endDate, 'DD-MM-YYYY HH:mm:ss');
      selectedFilters.endDate = newStartDate.format("YYYY-MM-DD HH:mm:ss");
    }

    setTabValue('4');
    setSelectedFilters(selectedFilters);
    setPageNo(1);
    // toggleDrawer(false);
  };

  let filterIds
  useEffect(() => {
    if (tabValue === '4') {
      const filterIds = createFilterIds(selectedFilters);
      const newfilterIds = {
        ...filterIds,
        limit: newRowSize,
        page: newPageNo
      };
      getBlockGroups(newfilterIds);
    }else{
      localStorage.removeItem("pageNo")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, tabValue, newRowSize, newPageNo]);

  useEffect(() => {
    if (tabValue === '4') {
      changeColumnDefinitions(tabValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullRowData]);

  const getBlockGroups = async (filters) => {
    if (Object.keys(filters).length === 0) {
      filters = {limit: newRowSize,page: newPageNo}
    }
    console.log("filters", filters)
    setLoading(true);
    try {
      await getBlockGroupData(filters)
        .then((resp) => {
          console.log("resp", resp)
          if (resp && resp.Status == "Success") {
            //modifying blockgroups from array to , seperated string
            const data = resp.Data;
            setRowdata(data);
            setTotalRows(resp.totalRecords);
          } else {
            setRowdata([]);
            setTotalRows(undefined);
          }
          setLoading(false);
        })
        .catch((e) => {
          showErrorNotification("Failed to fetch Block Numbers data");
          setRowdata([]);
          setTotalRows(undefined);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      showErrorNotification(JSON.stringify(e));
      setRowdata([]);
      setTotalRows(undefined);
    }
    setTabValue('4');
    retrieveFilters()
  }

  let blockNumberData = [];
  const getBlockGroupsDownload = async () => {

      const filterIds = createFilterIds(selectedFilters);
      const newfilterIds = {
          ...filterIds,
          limit: totalRows,
          page: 1
      };
      console.log("filters", newfilterIds)
      try {
        await getBlockGroupData(newfilterIds)
          .then((resp) => {
            console.log("resp", resp)
            if (resp && resp.Status == "Success") {
              //modifying blockgroups from array to , seperated string
              const data = resp.Data;
              data.map(data => {
                const blockGroups = data?.blockgroups || [];
                const groupNames = blockGroups.map(group => group.name);
                const phoneSkillsName = groupNames.join(', ');
                data.blockgroups = phoneSkillsName;
              })
              blockNumberData=data;
            } else {
              blockNumberData=[];
            }
          })
          .catch((e) => {
            showErrorNotification("Failed to download Block Numbers data");
          blockNumberData=[];
          });
      } catch (e) {
        showErrorNotification(JSON.stringify(e));
      blockNumberData=[];
      } 
      return blockNumberData;
    }

    const [openAddGroupForm,setOpenAddGroupForm] = useState(false);
    async function updateNewBlockGroups(data) {
      console.log("formData", data)
      console.log("fullRowData", fullRowData)
      const BlockGroupIds = [];
  
      //creating new group
      if (data.name && data.description) {
        try {
          const blockGroupToCreate = {name:data.name,description:data.description}
          
          await addBlockGroupNew(blockGroupToCreate)
            .then((resp) => {
              if (resp?.Status === "Success") {
                BlockGroupIds.push({ id: resp?.Data?.id });
                showSuccessNotification(`Block Group: ${data.name} Created Successfully`);
              }
              else {
                // console.log(resp.Message)
                // showErrorNotification(JSON.stringify(resp.Message));
                showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
              }
            })
            .catch((e) => {
              console.log(e)
              showErrorNotification(JSON.stringify(e));
              return;
            });
        } catch (e) {
          console.log(e)
          showErrorNotification(JSON.stringify(e));
          return;
        }
      }else if(data?.blockGroups?.length>0){
        data.blockGroups.forEach(item => {
          BlockGroupIds.push({ id: item.id });
        });
      } else {
        showErrorNotification('Group details not found');
        return;
      }
      console.log("BlockGroupIds ", BlockGroupIds);

      if(BlockGroupIds?.length >0){
      // updating block numbers
      const blockNumberIds = fullRowData.map(group => group.id).join(',');
      const blockGroupIds = BlockGroupIds.map(group => group.id).join(',');

      const dataToApi = {blockNumberIds: blockNumberIds,blockGroupIds: blockGroupIds}
              
      console.log("dataToApi", dataToApi);
      await updateBlockGroups(dataToApi)
          .then((resp) => {
            if (resp?.Status === "Success") {
                showSuccessNotification(`Block Groups added Successfully`);
                setOpenAddGroupForm(false);
                setFullRowData([]);
                getBlockGroups({});
            }
            else {
              // console.log(resp.Message)
              // showErrorNotification(JSON.stringify(resp.Message));
              showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
              return;
            }
          })
          .catch((e) => {
            console.log(e)
            showErrorNotification(JSON.stringify(e));
            return;
          });
        }
    }

  //Set State to Choose Add Form
  const showNumberForm = (formValue) => {
    setShowNumbersForm({ isNew: true, show: true });
    handleAddButtonClick(formValue);
    switch (formValue) {
      case "1":
        setAddFormType("1");
        break;

      case "2":
        setAddFormType("2");
        break;

      case "3":
        setAddFormType("3");
        break;

      case "4":
        setAddFormType("4");
        break;
      default:
        showErrorNotification("Can't set Form state");
        break;
    }
  };

  const CustomHeader = ({ onClick, headerStyle, headerName }) => {
    const handleClick = () => {
      if (onClick) {
        onClick();
      }
    };

    return (
      <div role='presentation' onClick={handleClick} style={headerStyle}>
        {headerName}
      </div>
    );
  };

  function changeColumnDefinitions(tabData) {
    switch (tabData) {
      case "1":
        setColumnDefs([
          {
            field: "name",
            headerName: "Name",
            cellRenderer: ChangeColumnDefinitions,
            minWidth: 50
          },
          {
            field: "phoneNumber", headerName: "Phone Number", minWidth: 150
          },
          {
            field: "skillName", headerName: "Assigned Skills", cellRenderer: PhoneSkillsNamesFormatter, minWidth: 280
          },
          // { field: "priority", headerName: "Priority", cellRenderer: authPage(AdminPages.AGENT_NUMBERS_EDIT) ? MoreVertColumnRenderer : PriorityCellRendered, }
          { field: "priority", headerName: "Priority", cellRenderer: (newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full')) ? MoreVertColumnRenderer : PriorityCellRendered, }
        ]);
        getAgentPhoneNumber();
        break;

      case "2":
        setColumnDefs([
          {
            field: "dialOutName",
            headerName: "Dialout Name",
            cellRenderer: ChangeColumnDefinitions,
            maxWidth: '300'
          },
          { field: "dialOutNumber", headerName: "Dialout Number", maxWidth: '300' },
          {
            field: "sip",
            headerName: "SIP",
            // cellRenderer: authPage(AdminPages.DAILOUT_NUMBERS_EDIT) ? MoreVertColumnRenderer : ChecBoxCellRendered,
            cellRenderer: (newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full')) ? MoreVertColumnRenderer : ChecBoxCellRendered,
          },
        ]);
        getDialoutNumber();
        break;

      case "3":
        setColumnDefs([
          {
            field: "transferName",
            headerName: "Transfer Name",
            cellRenderer: ChangeColumnDefinitions,
            maxWidth: '300'

          },
          { field: "transferNumber", headerName: "Transfer Number", maxWidth: '300' },
          {
            field: "sip",
            headerName: "SIP",
            // cellRenderer: authPage(AdminPages.TRANSFER_NUMBERS_EDIT) ? MoreVertColumnRenderer : ChecBoxCellRendered,
            cellRenderer: (newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS,'Full')) ? MoreVertColumnRenderer : ChecBoxCellRendered,
          },
        ]);
        getTransferNumber();
        break;

      case "4":
        setColumnDefs([
          {
            field: "blockedNumber",
            headerName: "Phone Number",
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            headerCheckboxSelection: true,
            minWidth: 150,
          },
          {
            field: "blockgroups",
            headerName: "Group Names",
            cellRenderer: groupNamesFormatter,
            minWidth: 500
          },
          {
            field: "expiryDate", headerName: "Expiry",
            cellRenderer: (params) => {
              const formattedDate = params.value ? moment(params.value).format(DateTimeFormat) : '';
              return formattedDate;
            }
          },
          {
            sortable: false,
            cellRenderer: MenuOnBlockNumberGridClick,
            headerComponentFramework: CustomHeader, // Use the custom header component
            headerComponentParams: {
              headerName: fullRowData?.length > 1 ? "Delete All" : "",
              headerStyle: { color: 'red', fontSize: '12px', fontWeight: '500', cursor: "pointer" },
              onClick: () => {setOpenDeleteBlckNo(true)}
            }

          },
        ]);
        break;
      default:
    }
  }

  const buttonStyle = useMemo(
    () => ({
      width: "9.375rem",
      height: "1.875rem",
      borderRadius: "0.5rem",
      fontSize: "0.875rem",
      fontWeight: 400,
    }),
    []
  );

  const styleListItemButton = useMemo(
    () => ({
      "&.MuiButtonBase-root": {
        width: '374px',
        height: '40px',
        borderRadius: "0.5rem",
        marginBottom: "8px",
        marginLeft: "1.1rem",
        marginRight: "1.2rem",
        fontStyle: "normal",
        fontFamily: "Roboto",
        fontSize: " 18px",
        fontWeight: 400,
        lineHeight: "0.875rem",
        paddingLeft: '12px'
      },
    }),
    []
  );


  const toggleStyle = useMemo(
    () => ({
      color: "#212121",
      fontSize: " 0.75rem",
      fontWeight: 400,
      letterSpacing: "0rem",
      backgroundColor: "#F5F8FA",
      width: "5.8125rem",
      height: "1.875rem",
      borderRadius: 0,
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#FFFF',
      },
    }),
    []
  );

  const [openDeleteBlckNo,setOpenDeleteBlckNo] = useState(false);
  const handleClickOnDelete = (data) => {
    console.log("here ti came in dlete icon", data);

    const ids = data.map(element => element.id);
    const idString = ids.join(',');
    const idData = { id: idString };

    deleteBlockNumber(idData);
  };

  function searchText() {
    if (tabValue === "1") {
      return ("Phone Number");
    } else if (tabValue === "2") {
      return ("Dialout Number");
    } else if (tabValue === "3") {
      return ("Transfer Number");
    } else if (tabValue === "4") {
      return ("Group Name");
    }
  }

  function gridTitle() {
    if (tabValue === "1") {
      return ("List of Phone Numbers");
    } else if (tabValue === "2") {
      return ("List of Dialout Numbers");
    } else if (tabValue === "3") {
      return ("List of Transfer Numbers");
    } else if (tabValue === "4") {
      return ("List of Block Number groups");
    }
  }

  function downloadFileName() {
    if (tabValue === "1") {
      return ("agentPhoneNumbers");
    } else if (tabValue === "2") {
      return ("dialoutNumbers");
    } else if (tabValue === "3") {
      return ("transferNumbers");
    } else if (tabValue === "4") {
      return ("blockNumbergroups");
    }
  }

  function showBulkImport() {
    if (tabValue === "1") {
      // return (authPage(AdminPages.AGENT_NUMBERS_EDIT) && checkRoles("ROLE_BulkEditPhoneNumber"));
      return ((newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'data') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full')) && checkRoles("ROLE_BulkEditPhoneNumber"));
    }
    else {
      return false
    }
  }


  async function getSettingsData() {
    try {
      getAdminSettingsList().then(resp => {
        // console.log(":::::::::???????????::::::::::::", resp.Data)
        setSettingsData(resp.Data);
      }).catch(e => {
        setSettingsData({})
        console.log(e)
      })
    }
    catch (e) {
      console.log(e)
    }
  }


  function hideAddButton(tabValue) {
    console.log("tabValue", tabValue);

    if (tabValue === "1") {



      let phoneNumbersCount = "";
      console.log(settingsData);
      if (settingsData.length > 0) {


        settingsData.map((item) => {
          if (item.ParameterCode === "FWP_CREATION_LIMIT") {
            if (item.ParameterValue === null) {
              phoneNumbersCount = item.DefaultValue;
              return phoneNumbersCount
            }
            else {
              phoneNumbersCount = (item.ParameterValue)
              return phoneNumbersCount
            }
          }
        })

        console.log(phoneNumbersCount);

        if (phoneNumbersCount === "-1") {
          return false;
        } else if (rowData.length < Number(phoneNumbersCount)) {
          return false;
        }
        else {
          return true;
        }


      }

    } else {
      return false;
    }






  }

  function onClickSampleDownload() {
    const link = document.createElement("a");
    link.download = `phone_numbers_upload_sample.xls`;
    link.href = `${process.env.PUBLIC_URL}/sample_files/phone_numbers_upload_sample.xls`;
    link.click();
  };
  let viewForms = [
    { id: "1", viewForm: <AddPhoneNumberForm updateAgentPhoneNumbers={getAgentPhoneNumber} onClose={closeManageNumbersForm} data={onRowClickNumberData} /> },
    { id: "2", viewForm: <AddDialoutNumberForm updateDialoutNumber={getDialoutNumber} onClose={closeManageNumbersForm} data={onRowClickNumberData} /> },
    { id: "3", viewForm: <AddTransferNumberForm updateTransferNumber={getTransferNumber} onClose={closeManageNumbersForm} data={onRowClickNumberData} /> },
    { id: "4", viewForm: <AddBlockGroupForm updateBlockGroup={getBlockGroups} onClose={closeManageNumbersForm} deleteBlockNumer={updateBlockGroup} expUpdate={newupdateBlockGroup} data={onRowClickNumberData} /> }
  ]
  return (
    <div className="ozAdminManageNumbers oz-manage-numbers-container" >
      <div className="oz-manage-numbers-add-button-container">
        <Typography
          sx={{ fontSize: "0.75rem", lineHeight: "0.875rem", color: "#212121", flexGrow: 1, marginBottom: "10px" }}>
          Configurations
        </Typography>

        {(newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full')) &&
        <>
        {menuValues.length > 0 && <Button variant="contained" sx={{ ...buttonStyle, marginBottom: "-10px" }} onClick={() => { handleAddButtonClick(menuValues[0].id) }} startIcon={<AddBoxOutlinedIcon />}>
          New Number
        </Button>}
        </>
        }
        {openAddDialog &&
          <OZDialog
            onClose={handleAddButtonClick}
            saveButtonProps={{ label: "Continue" }}
            disabled={hideAddButton(tabValue)}
            disabledMessage="Phone Number Creation Limit Exceeded"
            open={openAddDialog}
            onSave={() => { showNumberForm(tabValue) }}
            title="Which Number to add?" >
            <Typography sx={{
              fontSize: '12px', marginBottom: '8px'
            }} > Choose a type of Number</Typography>
            <Box sx={{
              border: '1px solid #D6D6D7',
              borderRadius: '8px',
              background: '#FFFFFF',
            }} >
              {menuValues.map((item, index) => {
                return (
                  <ListItemButton
                    key={item.id}
                    selected={tabValue === item.id}
                    onClick={() => { setTabValue(item.id); changeColumnDefinitions(item.id); setFullRowData([]) }}
                    sx={{
                      ...styleListItemButton, color: tabValue === item.id ? "#3D8BF8" : "#212121", marginTop: "8px", marginBottom: index === menuValues.length - 1 ? "6px" : "0px"
                    }}>
                    {item.name}
                  </ListItemButton>
                )
              })}
            </Box >
          </OZDialog>
        }
      </div>
      <Typography sx={{ fontWeight: 600, fontSize: "1.5rem", lineHeight: "1.75rem", marginBottom: "22px" }} flexGrow={1}>
        Manage Numbers
      </Typography>

      <div className="oz-manage-numbers-tabs-container" style={{ display: 'flex' }}>
        <TabContext value={tabValue}>
          <TabList
            TabIndicatorProps={{
              sx: { backgroundColor: "#008EFF", marginBottom: "1.125rem" },
            }}
            onChange={(event, newValue) => { handleAddButtonMenuValue(event, newValue) }}>
            {tabValues.map((tabItem, index) => {
              return (
                <OZCustomToggleButton
                  key={tabItem.id}
                  sx={{ ...toggleStyle, borderRight: index !== tabValues.length - 1 ? "0px" : "" }}
                  value={tabItem.id} >
                  {tabItem.name}
                </OZCustomToggleButton>
              )
            })}
          </TabList>
        </TabContext>
        <span className="oz-manage-numbers-tabs-divider-container">
          <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6" }} />
        </span>
      </div>
      {loading ? (
        <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
          <LoadingScreen />
        </div>
      )
        :
        (
          <>
            {showNumbersForm.show && !showNumbersForm.isNew &&
              (viewForms.map((form) => {
                if (tabValue === form.id) {
                  return <div key={form.id}>{form.viewForm}</div>
                }
              })
              )
            }
          </>
        )
      }

      {loading ? (
        <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
          <LoadingScreen />
        </div>
      )
        :
        (
          <div>
            {console.log("tabValueXXXXXXx", tabValue)}
            {console.log("tabValueXXXXXXx", numbersTableDataRef)}
            {console.log("tabValueXXXXXXx", rowData.length)}
            <OZGridToolbar
              title={gridTitle()}
              showEditUpload={(newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'data') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full')) ?  showBulkImport() : null}
              showImport={(newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'data') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full')) ? showBulkImport() : null}
              uploadEditProps={{
                label: "Bulk Update", importEditFunction: uploadEditBulkFWPNumbers, fileName: "PauseReasonEdit.xls", onClickSampleDownload: onClickSampleDownload
              }}
              importProps={{
                label: "Bulk Import", importFunction: importBulkFWPNumbers, fileName: "PauseReason.xls", onClickSampleDownload: onClickSampleDownload
              }}
              searchPlaceholder={searchText()}
              hideSearch={tabValue === '4' ?  true : false}
              showAddGroup={tabValue === '4' && fullRowData?.length >0 &&  true}
              showAddGroupForm={()=>setOpenAddGroupForm(true)}
              downloadBlockNumber={tabValue === '4' ?  true : false}
              downloadBlockNumberFunc={getBlockGroupsDownload}
              rowCount={tabValue === '4' ? totalRows : rowData.length}
              refreshGrid={getAgentPhoneNumber}
              dataGridRef={numbersTableDataRef}
              fileName={downloadFileName()}
              showDrawerFilters={tabValue === '4' ? true : false}
              applyFilters={handleApplyFilters}
              filtertProps={filters}
              updatedFilterValues={selectedFilters}
              allowedFilters={['groupNames', 'phoneNumbers', 'expiryDate']}
              enableDateFilter={true}
              dateFilterFor={['expiryDate']}
            />


            {
              showNumbersForm.show && showNumbersForm.isNew && (
                <ChooseForm
                  updatePhoneNumber={getAgentPhoneNumber}
                  updateDialoutNumber={getDialoutNumber}
                  updateTransferNumber={getTransferNumber}
                  updateBlockGroup={getBlockGroups}
                  menuType={addFormtype} currentTab={tabValue} close={setAddFormType} />
              )
            }

            <OZDataGrid
              height="26.3rem"
              columns={columnDefs}
              data={rowData}
              importProps={{ label: "Bulk Import Numbers", importFunction: importBulkFWPNumbers, fileName: "PauseReason.xls" }}
              rowClickCallback={onClickRow}
              setGridRef={setNumbersTableDataRef}
              onRowSelected={addSelectedRowData}
              newRowPerPage={setNewRows}
              name={tabValue === '4' && 'blockNumbers'}
              newPageNo={tabValue === '4' && setPageNo}
              totalRows={tabValue === '4' ? totalRows : undefined}
            />
            {openDeleteBlckNo && <DeleteDialog
            open={openDeleteBlckNo}
            onClose={() => { setOpenDeleteBlckNo(false) }}
            title={"Delete Block Number"}
            content={ `This will delete all the selected Blocked Numbers permanently. You cannot undo this action.`}
            deleteFunction={() => {handleClickOnDelete(fullRowData);setFullRowData([])}} />}

            {openAddGroupForm && <AddToGroupForm onClose={()=>{setOpenAddGroupForm(false)}} updateFunction={updateNewBlockGroups}/>}
          </div>
        )
      }
    </div >
  );
}
export function CustomViewCheckBox(checkBoxProps) {
  return (
    <FormGroup>
      <FormControlLabel sx={{
        '& .MuiTypography-root': {
          fontSize: "0.875rem",
          color: '#212121',
        }
      }}
        control={
          <CustomizedCheckbox
            checked={checkBoxProps.checked || false}
          ></CustomizedCheckbox>
        }
        label={checkBoxProps.label} />
    </FormGroup>
  )

}
export default ManageNumbers;