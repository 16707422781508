import { Drawer, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import AdminMenu from '../admin/menu/Menu';
import '../OZSidenav/OZSidenav.css';
import { useEffect, useState } from 'react';
import ReportsDrawer from '../ReportsDrawer/ReportsDrawer';
import { ReactComponent as DashboardIcon } from '../../static/icons/dashboard.svg';
import { ReactComponent as ReportIcon } from '../../static/icons/report.svg';
import { ReactComponent as CampaignsIcon } from '../../static/icons/campaign.svg';
import { ReactComponent as TemplatesIcon } from '../../static/icons/template.svg';
import { ReactComponent as AdminIcon } from '../../static/icons/admin.svg';
import { AdminPages, NewAdminPages, authPage, getAccess } from '../../services/page-service';
const drawerWidth = 90;

const useStyles: any = makeStyles((theme: Theme) => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0
	},
	drawerPaper: {
		padding: theme.spacing(1),
		width: drawerWidth,
		borderRight: '1px solid #E6E5E6',
		background: 'white'
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: {
		marginTop: 95
	}
}));

function OZSidenav() {
	// const [isReportsOpen, setIsReportsOpen] = useState<boolean>(false);
	const [currentRoute, setCurrentRoute] = useState<string>('');
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isReportsOpen, setIsReportsOpen] = useState<null | HTMLElement>(null);

	const classes = useStyles();
	const location = useLocation();
	const navigate = useNavigate();

	const handleClick = (event: React.MouseEvent<HTMLElement>, name: any) => {
		if (name === 'Admin') {
			setAnchorEl(event.currentTarget);
		}
		if (name === 'Reports') {
			// setIsReportsOpen(!isReportsOpen)
			setIsReportsOpen(event.currentTarget);
		}
	};

	const handleMouseLeave = (event: React.MouseEvent<HTMLElement>, name: any) => {
		if (name !== 'Admin') {
			setAnchorEl(null);
		}
		if (name !== 'Reports') {
			setIsReportsOpen(null)
		}
	}

	const handleClose = () => {
		setAnchorEl(null);
		// setIsReportsOpen(false)
		setIsReportsOpen(null)
	};

	const handleReportsDrawer: any = (boolValue: boolean) => {
		// setIsReportsOpen(boolValue);
		setIsReportsOpen(null);
	};

	const listItems: any = [
		{ name: 'Dashboard', icon: DashboardIcon, route: '/preset', routeActive: '' },
		{ name: 'Reports', icon: ReportIcon, handler: handleReportsDrawer, routeActive: 'reports' },
		{ name: 'Campaigns', icon: CampaignsIcon, route: '/admin/campaigns', routeActive: 'campaigns' },
		{ name: 'Templates', icon: TemplatesIcon, route: '/admin/templates', routeActive: 'templates' },
		{ name: 'Admin', icon: AdminIcon, handler: handleClose, routeActive: 'admin' },

	];
	let filteredListItems: any = listItems.filter((component: any, index: any) => {
		if (component.name === "Templates") {
			// return (setTimeout(authPage(AdminPages.TEMPLATES_VIEW)), 1000);
			return (localStorage.getItem('newAuthPage')?.includes(NewAdminPages.TEMPLATES_PAGE) || localStorage.getItem('newAuthSubAdminAccess')?.includes(`${NewAdminPages.TEMPLATES_PAGE}_${getAccess('viewAccess')}`) || localStorage.getItem('newAuthSubAdminAccess')?.includes(`${NewAdminPages.TEMPLATES_PAGE}_${getAccess('fullAccess')}`) || localStorage.getItem('newAuthSubAdminAccess')?.includes(`${NewAdminPages.TEMPLATES_PAGE}_${getAccess('editAccess')}`));
		}
		else if (component.name === "Campaigns") {
			// return authPageWithDelay(NewAdminPages.CAMPAIGN_PAGE);
			return (localStorage.getItem('newAuthPage')?.includes(NewAdminPages.CAMPAIGN_PAGE) || localStorage.getItem('newAuthSubAdminAccess')?.includes(`${NewAdminPages.CAMPAIGN_PAGE}_${getAccess('viewAccess')}`) || localStorage.getItem('newAuthSubAdminAccess')?.includes(`${NewAdminPages.CAMPAIGN_PAGE}_${getAccess('fullAccess')}`) || localStorage.getItem('newAuthSubAdminAccess')?.includes(`${NewAdminPages.CAMPAIGN_PAGE}_${getAccess('editAccess')}`) || localStorage.getItem('newAuthSubAdminAccess')?.includes(`${NewAdminPages.CAMPAIGN_PAGE}_${getAccess('dataUpload')}`));
		}
		else if (component.name === "Reports") {
			return (localStorage.getItem('newAuthPage')?.includes(NewAdminPages.REPORTS) || localStorage.getItem('newAuthSubAdminAccess')?.includes(`${NewAdminPages.REPORTS}_${getAccess(true)}`));
		}
		else if (component.name === "Dashboard") {
			return (localStorage.getItem('newAuthPage')?.includes(NewAdminPages.DASHBOARDS) || localStorage.getItem('newAuthSubAdminAccess')?.includes(`${NewAdminPages.DASHBOARDS}_${getAccess(true)}`));
		}
		else {
			return true
		}
	})

	const isActive = (route: string) => {
		if (route.length) {
			if (route === 'admin') {
				return currentRoute.includes('admin') && (!currentRoute.includes('templates') && !currentRoute.includes('campaigns'))
			} else {
				return currentRoute.includes(route);
			}

		}
		else {
			return !currentRoute.includes('reports') && !currentRoute.includes('admin')
		}
	};

	useEffect(() => {
		if (location.pathname) {
			setCurrentRoute(location.pathname);
		}
	}, [location]);

	const navigateTo = (route: any) => {
		if (currentRoute.includes('campaigns') && isActive(route)) {
			navigate('/admin/');
			navigate(-1);
		}
	}
	return (
		<Drawer
			className={classes.drawer}
			variant="permanent"
			classes={{
				paper: classes.drawerPaper
			}}
		>
			<div className={classes.toolbar} />
			<div>
				{
					filteredListItems.map((item: any, index: any) => {
						const IconComponent = item.icon || null;
						return (
							<div key={item.name} onClick={() => { 
								// localStorage.clear(); 
								navigateTo(item.routeActive) }} onMouseEnter={(e) => handleMouseLeave(e, item.name)}>
								<NavLink to={item.handler ? '#' : item.route}
									onMouseEnter={(e) => handleClick(e, item.name)}
									aria-controls={Boolean(anchorEl) ? 'adminMenu' : undefined}
									aria-haspopup="true"
									aria-expanded={Boolean(anchorEl) ? 'true' : undefined}>
									<div className={`listItem ${isActive(item.routeActive) ? 'active' : ''}`} onClick={item.handler ? item.handler : null}>
										<>
											<IconComponent />
											<p className="noMargin listItemText">{item.name}</p>
										</>
									</div>
								</NavLink>
							</div>
						);
					})}
			</div>
			<ReportsDrawer isOpen={isReportsOpen} handleReportsDrawer={handleReportsDrawer}></ReportsDrawer>
			<AdminMenu anchorEl={anchorEl} handleClose={handleClose} />
		</Drawer>
	);
}

export default OZSidenav;
