import React, { useState, useMemo, useEffect} from 'react';
import { Formik} from 'formik';
import * as yup from 'yup';
import { Button, Grid, Typography, Stack, Divider} from '@mui/material';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
// import "./Campaign.css";
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import OZInput from '../../../components/admin/common/OZInput';
import { UserConfiguration } from './UserConfiguration';
import OZFCheckbox from '../../../components/admin/common/OZFCheckbox';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import { getFeatureListRoles, saveAndUpdateUserProfile } from '../../../services/api-service';

const SubUserProfileForm = (props) => {

    let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add
    const [mode, setMode] = useState(initMode);

    useEffect(() => {
        getFullFeatureList();
    }, []);
    
    const validationSchema = useMemo(() => (yup.object({
        name: yup
            .string()
            .test("maxDigits",
                "Profile Name should be between 3 to 50 alphanumeric characters long and allows special characters like .,@,_,-",
                (number) => String(number).length >= 3 && String(number).length <= 50)
            .matches(/^([A-Za-z\d]((\.|@|_|\-)?[A-Za-z\d])+)$/, "Profile Name should be between 3 to 50 alphanumeric characters long and allows special characters like .,@,_,-")
            .required('Profile Name is required'),
        })),
    []);

    let formHeading = useMemo(
        () => ({
            fontWeight: '500',
            fontSize: '12px',
            color: '#212121',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
        }),
        []
    );

    let formSubHeading = useMemo(
        () => ({
            fontWeight: '400',
            fontSize: '18px',
            color: '#212121',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
        }),
        []
    );

    let formSubMinorHeading = useMemo(
        () => ({
            fontWeight: '400',
            fontSize: '12px',
            color: '#47586E',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
        }),
        []
    );

    const formButtonStyle = useMemo(
        () => ({
            borderRadius: '8px',
            fontWeight: '400',
            fontSize: '14px',
        }),
        []
    );

    let loadingScreenStyle = useMemo(
        () => ({
            position: 'fixed',
            top: '0', left: '0',
            width: '100%', height: '100%', 
            backgroundColor: '0,0,0,0.5',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000 

        }),
        []
    );

    const [loading, setLoading] = useState((mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? true : false);
    const [newLoading, setNewLoading] = useState(false);
    const [featureListParent, setFeatureListParent] = useState([]);
    const [featureList, setFeatureList] = useState([]);

    const closeUserProfileForm = () => {
        localStorage.removeItem("userProfileForm");
        props.onClose();
    }

    const onClickEdit = () => {
        setMode(ADMIN_FORM_MODES.edit);
    }

    const onClickDiscardEdit = () => {
        setMode(ADMIN_FORM_MODES.view);
    }

    async function getFullFeatureList(){
        setNewLoading(true);
        try{
            getFeatureListRoles().then(resp => {
                if(resp?.Status === "Success" && resp?.Data?.features?.length > 0){
                    const filteredList = resp?.Data?.features?.filter(item => item.name !== 'Reports' && item.name !== 'Dashboard');
                    setFeatureListParent(resp?.Data?.features);
                    setFeatureList(filteredList);
                    setNewLoading(false);
                }else{
                    setNewLoading(false);
                    showErrorNotification("Fail to get List, please try again..");
                }

            }).catch(e => {
                setNewLoading(false);
                showErrorNotification(JSON.stringify(e));
            })

        }catch(e){
            setNewLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function addUserProfile(data) {
       delete data.dashboard;
       delete data.reports;
       data.profileSetting = JSON.stringify(data.profileSetting);
       setNewLoading(false);
        try {
            saveAndUpdateUserProfile(data).then(resp => {
                if (resp.Status === 'Success') {
                    // showSuccessNotification(`Profile (${data.name}) Created Successfully`);
                    showSuccessNotification(resp.Message);
                    props.updateUserProfile();
                    closeUserProfileForm();
                    setNewLoading(false);
                } else {
                    showErrorNotification(resp?.Message);
                    setNewLoading(false);
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function updateProfile(data) {
        delete data.dashboard;
        delete data.reports;
        data.profileSetting = JSON.stringify(data.profileSetting);
        setNewLoading(false);
        // console.log("here final going to edit", data);
        try {
            saveAndUpdateUserProfile(data).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(resp.Message);
                    props.updateUserProfile();
                    closeUserProfileForm();
                    setNewLoading(false);
                } else {
                    showErrorNotification(resp?.Message);
                    setNewLoading(false);
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    function getName(value) {
        let NValue = false;
        if(value === 'dashboard'){
            featureListParent.map((Nitem) => {
                if(Nitem.name === "Dashboard"){
                    NValue = true;
                }
            })
        }
        if(value === 'reports'){
            featureListParent.map((Nitem) => {
                if(Nitem.name === "Reports"){
                    NValue = true;
                }
            })
        }
        return NValue
    }

    const DataForm = (props) => {

        let profileSetting = useMemo(
            () => (
                [
                    {
                        "configutaions": [],
                        "access": ""
                    }
                ]
            ), []
        );

        let initData = props.data || {
            name: '',
            profileSetting: profileSetting,
            dashboard: false,
            reports: false
        };
        setLoading(false);
        

        const BasicSettings = (props) => {
            const basicProps = props?.props;
            const [stopSave, setStopSave] = useState(false);

            return(
                <Stack sx={{marginLeft: '15px', marginTop: '10px', marginRight: '15px'}}>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                        <Typography sx={formHeading} flexGrow={1}>Sub User Profiles</Typography>
                        {mode === ADMIN_FORM_MODES.view && (
                            <div>
                                <Grid container direction='row'>
                                    <div style={{ fontSize: '14px', color: '#536580' }} onClick={onClickEdit}>
                                        <label style={{ cursor: "pointer" }}>Edit</label>
                                    </div>
                                </Grid>
                            </div>
                        )}
                        {mode === ADMIN_FORM_MODES.edit && (
                            <div>
                                <Grid container direction='row'>
                                    <div style={{ fontSize: '14px', color: '#3D8BF8' }} onClick={onClickDiscardEdit}>
                                        <label style={{ cursor: "pointer" }} >Discard Edit</label>
                                    </div>
                                </Grid>
                            </div>
                        )}
                    </div>
                    <span style={{ flexGrow: 1, alignSelf: "flex-end" , marginTop: '10px'}}>
                        <Divider sx={{ borderColor: "#E6E5E6", width:'80vw'}} />
                    </span>

                    <Grid style={{ marginTop: '8px', fontSize: '12px' }} container spacing={2} >
                        <Grid item xs={6} >
                            <OZInput
                                disabled={mode === ADMIN_FORM_MODES.view}
                                name="name"
                                label="Sub User Profile Name *"
                                placeholder="Sub user Profile Name"
                            />
                        </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '8px', fontSize: '12px' }} container spacing={2} >
                        <Grid item xs={12} >
                            <UserConfiguration
                                mode={mode}
                                formikProps={basicProps}
                                list={featureList.filter(item => item?.name !== "Reports" || item?.name !== "Dashboard")}
                                forceStopToSave={(value) => {value === true ? setStopSave(true) : setStopSave(false)}}
                            />
                        </Grid>
                    </Grid>

                    <div style={{marginTop: '25px'}}>
                        <Typography sx={formSubHeading}>Data Access</Typography>
                        <div style={formSubMinorHeading}>Configure the required access to user</div>

                        <span style={{ flexGrow: 1, alignSelf: "flex-end" , marginTop: '10px'}}>
                            <Divider sx={{ borderColor: "#E6E5E6", width:'80vw'}} />
                        </span>

                        <Grid style={{ marginTop: '8px', fontSize: '12px' }} container spacing={1} >
                            {getName('dashboard') === true ? 
                                <Grid item xs={3} >
                                    <OZFCheckbox
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name="dashboard"
                                        label="Dashboards"
                                        labelPosition="end"
                                    />
                                </Grid>
                            :
                                null
                            }
                            {getName('reports') === true ? 
                                <Grid item xs={3} >
                                    <OZFCheckbox
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name="reports"
                                        label="Reports"
                                        labelPosition="end"
                                    />
                                </Grid>
                            :
                                null
                            }
                        </Grid>
                    </div>

                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 5, marginBottom: '10px' }} justifyContent='end'>
                        <Grid item>
                            <Button disabled={mode === ADMIN_FORM_MODES.view} style={formButtonStyle} onClick={closeUserProfileForm} variant="outlined">
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item >
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view || stopSave} onClick={() => {
                                if (!basicProps.isValid) {
                                    basicProps.handleSubmit();
                                    showErrorNotification("Please Check the values and fill all the mandatory field")
                                }
                            }} variant="contained" type={(basicProps.isValid) ? "submit" : null} form="userProfile-form">
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            )

        }

        function getInitData() {
            let localData = localStorage.getItem('userProfileForm');
            if (localData) {
                return JSON.parse(localData);
            } else {
                return initData;
            }
        }

        return (
            <div className='oz-campaignOut-add-form'>
                <Formik
                    validateOnMount
                    initialValues={getInitData()}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        if (mode === ADMIN_FORM_MODES.add || mode === ADMIN_FORM_MODES.edit) {
                            localStorage.setItem('userProfileForm', JSON.stringify(values));
                        }
                        let flag = 0
                        let saveData = structuredClone(values);
                        saveData?.profileSetting.map((item) => {
                            if(item.configutaions?.length === 0 || item?.configutaions?.length === null){
                                showErrorNotification(`Please select mandatory feature in the List`);
                                flag = 1;
                                return null;
                            }
                            if(item?.access === ''){
                                showErrorNotification(`Please select mandatory access in the List`);
                                flag = 1;
                                return null;
                            }
                        })
                        if(flag === 0){
                            let id = saveData?.id ? saveData?.id : null;

                            function convertData(input) {
                                const output = [];
                                
                                input.profileSetting.forEach(config => {
                                    const accessObject = {};
                                    accessObject[config.access] = config.configutaions.map(c => c.name);
                                    output.push(accessObject);
                                });
                                
                                if (input.dashboard) {
                                    output.push({ dashboard: input.dashboard });
                                }else{
                                    output.push({ dashboard: false });
                                }
                                
                                if (input.reports) {
                                    output.push({ reports: input.reports });
                                }else{
                                    output.push({ reports: false });
                                }
                                
                                return output;
                            }

                            const newCOn = convertData(saveData);
                            //:::::::::::to convert Array to Object as API compatible:::::::://
                            const result = newCOn.reduce((acc, item) => {
                                return { ...acc, ...item };
                            }, {});
                            //:::::::::::to convert Array to Object as API compatible:::::::://

                            saveData.profileSetting = result;

                            if(mode === ADMIN_FORM_MODES.add){
                                setNewLoading(true);
                                addUserProfile(saveData);
                            }else if(mode === ADMIN_FORM_MODES.edit){
                                setNewLoading(true);
                                updateProfile(saveData);
                            }
                        }
                    }}
                >
                    {
                        props => (
                            <div>
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) && (
                                    <div style={{marginLeft: '50px'}}>
                                        <form onSubmit={props.handleSubmit} id='userProfile-form' className='ozcw-scrollbar' style={{
                                            maxHeight: 'auto',
                                            overflowY: 'auto',
                                            padding: '5px'
                                        }}>
                                            {loading ?
                                                (
                                                    <div style={loadingScreenStyle}>
                                                        <LoadingScreen />
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div style={{ display: 'flex', alignContent: 'center' }}>
                                                        <div style={{ width: '80vw', minHeight: '10rem', borderRadius: '8px', marginTop: 4, backgroundColor: '#FFFFFF'}}>
                                                            <React.Fragment >
                                                                <BasicSettings props={props} />
                                                            </React.Fragment>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {newLoading && (
                                                <div style={loadingScreenStyle}>
                                                    <LoadingScreen />
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                )}

                                {mode === ADMIN_FORM_MODES.add && (
                                    <div style={{marginLeft: '50px'}}>
                                        <form onSubmit={props.handleSubmit} id='userProfile-form' className='ozcw-scrollbar' style={{
                                            maxHeight: 'auto',
                                            overflowY: 'auto',
                                            padding: '5px'
                                        }}>
                                            <div style={{ display: 'flex', alignContent: 'center' }}>
                                                <div style={{ width: '80vw', minHeight: '23rem', borderRadius: '8px', marginTop: 4, backgroundColor: '#FFFFFF'}}>
                                                    <React.Fragment >
                                                        <BasicSettings props={props} />
                                                    </React.Fragment>
                                                </div>
                                            </div>
                                        </form>
                                        {newLoading && (
                                            <div style={loadingScreenStyle}>
                                                <LoadingScreen />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )
                    }
                </Formik>
            </div >
        )
    }
    return (
        <div
            onClose={closeUserProfileForm}
            onEdit={onClickEdit}
            mode={mode}
        >
            {mode === ADMIN_FORM_MODES.add && (
                <div style={{marginLeft: '50px'}}>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '14ppx',
                                color: '#212121',
                                cursor: "pointer",
                            }} flexGrow={1} alignSelf='center' onClick={closeUserProfileForm}>{`User Profile List / ${mode} Profile`}</Typography>
                        </Stack>
                    </div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '28px',
                                color: '#000000',
                            }} flexGrow={1} alignSelf='center'>{`${mode} Profile`}</Typography>
                        </Stack>
                    </div>
                </div>
            )}

            {mode === ADMIN_FORM_MODES.view && (
                <div style={{marginLeft: '50px'}}>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '14ppx',
                                color: '#212121',
                                cursor: "pointer",
                            }} flexGrow={1} onClick={closeUserProfileForm} alignSelf='center'>{`User Profile List / ${mode} Profile`}</Typography>
                        </Stack>
                    </div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '28px'
                            }} flexGrow={1} alignSelf='center'>{props.data.name}</Typography>
                        </Stack>
                    </div>
                </div>
            )}

            {mode === ADMIN_FORM_MODES.edit && (
                <div style={{marginLeft: '50px'}}>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '14ppx',
                                color: '#212121',
                                cursor: "pointer",
                            }} flexGrow={1} alignSelf='center' onClick={closeUserProfileForm}>{`User Profile List / ${mode} Profile`}</Typography>
                        </Stack>
                    </div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '28px'
                            }} flexGrow={1} alignSelf='center'>{props.data.name}</Typography>
                        </Stack>
                    </div>
                </div>
            )}

            {mode === ADMIN_FORM_MODES.add && <DataForm />}
            {mode === ADMIN_FORM_MODES.view && <DataForm data={props.data} />}
            {mode === ADMIN_FORM_MODES.edit && <DataForm data={props.data} />}

        </div>
    );
};

export default SubUserProfileForm;