import React, { useState, useMemo } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Grid } from "@mui/material";
import OZInput from "../../../components/admin/common/OZInput";
import { adddisposition, updatedispositionById } from "../../../services/api-service";
import OZDialog from "../../../components/admin/common/OZDialog";
import { showErrorNotification, showSuccessNotification, showWarningNotification } from "../../../components/admin/common/NotiService";
import { ADMIN_FORM_MODES } from "../../../utils/constants";

const DispositionForm = (props) => {

  let initMode = props.data ? "Rename" : ADMIN_FORM_MODES.add;
  initMode = props?.openDialog === true ? "Rename" : initMode;
  const [showDispositionForm, setDispositionFormState] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [mode, setMode] = useState(initMode);

  const validationSchema = useMemo(
    () =>
      yup.object({
        reason: yup
          .string()
          .min(2, "Reason should be between 2 to 150 alphanumeric characters long allows special characters like _,space and Reason cannot start and end with Special characters.")
          .max(150, "Reason should be between 2 to 150 alphanumeric characters long allows special characters like _,space and Reason cannot start and end with Special characters.")
          .matches(/^([A-Za-z0-9]((_|\s)*?[A-Za-z0-9])+)$/, 'Reason should be between 2 to 150 alphanumeric characters long allows special characters like _,space and Reason cannot start and end with Special characters.')
          .required("Reason is required"),
      }),
    []
  );

  const closeDispositionForm = () => {
    props.onClose();
    setDispositionFormState(false);
  };

  async function addDisposition(data) {
    try {
      adddisposition(data)
        .then((resp) => {
          if (resp.Status === "Success") {
            showSuccessNotification(`Disposition ${data.reason} Created Successfully`);
            props.disposition();
            closeDispositionForm();
          }
          else {
            showWarningNotification(resp.Message.reason || resp.Message);
          }
        })
        .catch((e) => {
          showErrorNotification("Error adding disposition");
        });
    } catch (e) {
      showErrorNotification("Error in disposition adding");
    }
  };

  async function updatedisposition(data) {
    try {
      updatedispositionById(data)
        .then((resp) => {
          if (resp.Status === "Success") {
            closeDispositionForm();
            props.updateReason();
            showSuccessNotification(`Disposition ${data.reason} Updated Successfully`);
          }
          else {
            showWarningNotification(resp.Message.reason || resp.Message);
          }
        })
        .catch((e) => {
          showErrorNotification("Error updating disposition");
        });
    } catch (e) {
      showErrorNotification("Error in disposition update");
    }
  };

  const DataForm = (formProps) => {

    let initData = formProps.editData || {
      reason: "",
    };

    return (
      <div className="ozAddDispositionForm">
        <Formik
          initialValues={initData}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (mode === "Create") {
              addDisposition(values);
            }
            else {
              updatedisposition(values);
            }
          }}
        >
          {
            (props) => (
              <div>
                <form
                  onSubmit={props.handleSubmit}
                  id="dispositionForm"
                  style={{
                    width: "464px",
                  }}
                >
                  <Grid container={true} spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <OZInput
                        name="reason"
                        placeholder="Enter Reason"
                        label="Reason *"
                      />
                    </Grid>
                  </Grid>
                </form>
              </div>
            )}
        </Formik>
      </div>
    );
  };

  return (
    <div>
      {mode === ADMIN_FORM_MODES.add ? (
        <OZDialog open={showDispositionForm} onClose={closeDispositionForm} title="Add Disposition" saveButtonProps={{ form: 'dispositionForm', type: 'submit' }} mode={mode}>
          <DataForm />
        </OZDialog >
      ) :
        (
          <OZDialog open={showDispositionForm} onClose={closeDispositionForm} title="Rename" saveButtonProps={{ form: 'dispositionForm', type: 'submit' }} mode={mode} hideSaveButton={!(props.editAccess) || false}>
            <DataForm editData={props.data} />
          </OZDialog>
        )
      }
    </div>
  );
};

export default DispositionForm;