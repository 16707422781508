import React, { useState, useEffect } from 'react'
import OZDataGrid from '../../../components/admin/common/OZDataGrid/OZDataGrid';
import { Button, Stack, Tooltip } from '@mui/material';
import './Integration.css'
import CustomSelect from '../Users/CustomSelect';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import { getZohoAgentIdMappings, getZohoMappings, saveZohoAgentMapping } from '../../../services/api-service';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';

export default function AgentIntegration() {

  const [agentIntegrationList, setAgentIntegrationList] = useState([]);
  console.log(agentIntegrationList)
  const [agentZohoData, setAgentZohoData] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    getZohoMappingsDetails();
  }, []);

  useEffect(() => {
    getAgentList();
  }, [agentZohoData])

  async function getZohoMappingsDetails() {
    setAlreadyAssignedZohoUsers([]);
    setLoading(true);
    try {
      getZohoMappings()
        .then((resp) => {
          if (resp.Status === "Success") {
            let zohoData = resp.Data;
            let username = (localStorage.getItem("selectedUser") !== null && localStorage.getItem("selectedUser") !== undefined && localStorage.getItem("selectedUser") !== "null" && localStorage.getItem("selectedUser") !== "undefined") ? localStorage.getItem("selectedUser") : localStorage.getItem("userName");
            let filteredZohoAgentsMap = {};
            Object.entries(zohoData.zohoAgentsMap).forEach(([key, value]) => {
              const [prefix, suffix] = key.split(':');
              if (prefix === username && (value !== "-1" && value !== -1)) {
                filteredZohoAgentsMap[suffix] = value;
              }
            });
            setAgentZohoData({
              zohoAgentsMap: filteredZohoAgentsMap,
              zohoUsers: zohoData.zohoUsers
            });
            const zohoUsersValues = Object.values(zohoData.zohoUsers);
            const updatedOptions = ["DeSelect", ...zohoUsersValues];
            setOptions(updatedOptions);
          }
          else {
            showErrorNotification("Zoho Mappings Cannot Be Fetched .", resp.Message);
            setLoading(false);
          }
        })
        .catch((e) => {
          showErrorNotification("Error in fetching Zoho Mappings");
          // setLoading(false);
        });
    } catch (e) {
      showErrorNotification("Error in getting Zoho Mappings");
      setLoading(false);
    }
  };


  const [options, setOptions] = useState([])
  const [alreadyAssignedZohoUsers, setAlreadyAssignedZohoUsers] = useState([])

  const ZohoUserRenderer = (props) => {

    const [isEditMode, setEditMode] = useState(false);
    const [zohoUser, setZohoUser] = useState(props.data.zohoUser);

    const toggleEditMode = () => {
      setEditMode(!isEditMode);
      setZohoUser(props.data.zohoUser);
    };

    async function saveAgentZohoMapping() {
      const zohoUserVal = zohoUser;
      let result = null;
      for (const [key, value] of Object.entries(agentZohoData.zohoUsers)) {
        if (value === zohoUserVal) {
          result = key;
          break;
        }
      }
      if (zohoUserVal === "DeSelect") {
        result = "-1";
      }
      let data = {
        caAgentId: props.data.agentName,
        zohoUser: result
      }
      try {
        setLoading(true);
        saveZohoAgentMapping(data)
          .then((resp) => {
            if (resp.Status === "Success") {
              if (zohoUserVal !== "DeSelect") {
                showSuccessNotification(`Agent ${props.data.agentName} is Successfully Mapped with zohoUser ${zohoUser}`);
              }
              if (zohoUserVal === "DeSelect") {
                showSuccessNotification(`Agent ${props.data.agentName} is Successfully UnMapped`);
              }
              setEditMode(false);
              getZohoMappingsDetails();
            }
            else {
              showErrorNotification("Error in save of Zoho Agent Mapping.", resp.Message);
              setLoading(false);
            }
          })
          .catch((e) => {
            showErrorNotification("Error in saving Zoho Agent Mapping");
            // setLoading(false);
          });
      } catch (e) {
        showErrorNotification("Error in Zoho Agent Mapping Saving");
        setLoading(false);
      }
    }

    return (
      <>
        {!isEditMode &&
          <div className='editButtonPosition'>
            <div>{zohoUser}</div>
            <Button variant="text" onClick={toggleEditMode} className='editButton'> Edit </Button>
          </div>
        }
        {isEditMode &&
          <>
            <Stack direction="row">
              <CustomSelect
                options={options}
                file="integration"
                placeholder={zohoUser}
                onChange={setZohoUser}
                disabledOptions={alreadyAssignedZohoUsers}
                disableOptions={true}
              />
              <Stack direction="row" spacing="20px">
                <Button variant="text" onClick={toggleEditMode} className='discardButton'> Discard </Button>
                <Button variant="contained" className={(zohoUser !== "" && props.data.zohoUser !== zohoUser) ? (zohoUser === "DeSelect") ? (props.data.zohoUser !== "") ? 'saveButton' : 'notAllowSaveButton' : 'saveButton' : 'notAllowSaveButton'} onClick={(zohoUser !== "" && props.data.zohoUser !== zohoUser) ? (zohoUser === "DeSelect") ? (props.data.zohoUser !== "") ? saveAgentZohoMapping : null : saveAgentZohoMapping : null} size="small"> Save </Button>
              </Stack>
            </Stack>
          </>
        }
      </>
    );
  };

  const ColumnRenderer = (props) => {
    return (
      <Tooltip title={(props.value) ? props.value : ''}>
        <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", cursor: "pointer" }}>{props.value}</span>
      </Tooltip>
    );
  };

  const columnDefs = ([
    { field: 'agentId', headerName: 'Agent Id', maxWidth: 160, cellRenderer: ColumnRenderer },
    { field: 'agentName', headerName: 'Agent Name', maxWidth: 160, cellRenderer: ColumnRenderer },
    { field: 'zohoUser', headerName: 'Zoho User', cellRenderer: ZohoUserRenderer },
  ]);

  async function getAgentList() {
    try {
      getZohoAgentIdMappings()
        .then((resp) => {
          if (resp.Status === "Success") {
            let arrayOfObjects = resp.Data.filter(item => item.active === true)
            const updatedArrayOfObjects = arrayOfObjects.map(obj => {
              const lowercaseAgentName = obj.agentName.toLowerCase().replace(/[a-z]/g, char => char.toLowerCase());
              const zohoUser = agentZohoData.zohoAgentsMap.hasOwnProperty(lowercaseAgentName) ?
                (agentZohoData.zohoUsers.hasOwnProperty(agentZohoData.zohoAgentsMap[lowercaseAgentName]) ?
                  agentZohoData.zohoUsers[agentZohoData.zohoAgentsMap[lowercaseAgentName]] : "") : "";
              if (zohoUser !== "") {
                setAlreadyAssignedZohoUsers(prevUsers => [...prevUsers, zohoUser])
              }
              return {
                ...obj,
                zohoUser: zohoUser
              };
            });
            setAgentIntegrationList(updatedArrayOfObjects);
            setLoading(false);
          }
          else {
            showErrorNotification(resp.Message);
            setLoading(false);
          }
        })
        .catch((e) => {
          setAgentIntegrationList([]);
          // setLoading(false);
        });
    } catch (e) {
      showErrorNotification("Error in agent list");
      setLoading(false);
    }
  };

  return (
    <div className="ozAdminSubIntegrationContainer">
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        <OZDataGrid height="26.3rem" data={agentIntegrationList} columns={columnDefs} />
      }
    </div>
  );
};