import React, { useState } from "react";
import { Button, Typography, ListItemButton, Divider, Menu, Stack, Chip, Tooltip } from "@mui/material";
import { useEffect, useMemo } from "react";
import OZDataGrid from '../../../components/admin/common/OZDataGrid/OZDataGrid';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CampaignInboundForm from "./CampaignInboundForm";
import {
    getCampaignListForAdmin, getMarketingCampaignListForAdmin, getRestoreCampaignListForAdmin, restoreById, deleteCampaignInbound,
    deleteCampaignOutbound, deleteChatCampaign, deleteMissedCallCampaign, getPluginDropdown, startStopCampaignById, forceCompleteCampaignById,
    downloadOutboundCampaign,
    getDownloadDataCampaignListForAdmin,
    getDispositionsDropdown
} from "../../../services/api-service";
import { getCampaignOutboundListForAdmin, getRowDataListForCampaignId } from "../../../services/api-service";
import CampaignOutboundForm from "./CampaignOutBoundForm";
import PopupState from 'material-ui-popup-state';
import CustomizedCheckbox from "../../../components/admin/common/CustomCheckBox";
import OZDialog from "../../../components/admin/common/OZDialog";
import './Campaign.css';
import MarketingCampaignForm from "./MarketingCampaignForm";
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import AddCampaignDataform from "./AddCampaignDataform";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { BulkActionCampaign } from "./BulkActionCampaign";
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import CopyCampaignForm from "./CopyCampaignForm";
import SimpleSwitch from '../../../components/admin/common/OZSwitch/SimpleSwitch';
import { authPage, AdminPages, newAuthPages, newAuthSubAdminAccess, NewAdminPages } from '../../../services/page-service';
import ResetCampaignForm from "./ResetCampaign";
// import DownloadDataForm from "./DownloadDataForm";
import { OZCustomToggleButton } from "../../../components/admin/common/OZCustomToggleButton";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import { getUserId } from "../../../services/auth-service";
import { IVR_FLOW_URL } from "../../../utils/properties";
import BulkCampaignDownloadDataForm from "./BulkCampaignDownloadDataForm";
import { Userpilot } from 'userpilot';
import { checkRoles } from '../../../utils/util';

const Campaigns = (props) => {

    const [loading, setLoading] = useState(false);

    async function getCampaign() {
        setLoading(true);
        setCampaignList([]);
        try {
            getCampaignListForAdmin().then(resp => {
                setRowData(resp.Data);
                setColumnData(checkRoles("ROLE_IVRDESIGNER_ALL") ? inboundColumnDefs:inboundColumnDefsIVR);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                setRowData([])
            })
        }
        catch (e) {
            setLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    }
    async function getCampaignOutboundList() {
        setLoading(true);
        setCampaignList([]);
        try {
            getCampaignOutboundListForAdmin().then(resp => {
                setRowData(resp.Data);
                setColumnData(outboundColumnDefs);
                try {
                    getDispositionsDropdown().then(resp => {
                        setDispositionDropdownData(resp?.Data);
                        setLoading(false);
                    }).catch(e => {
                        showErrorNotification('No Dispostions Data, Check your Internet Connection');
                        setDispositionDropdownData([]);
                        setLoading(false);
                    })
                }
                catch (e) {
                    setLoading(false);
                    showErrorNotification(JSON.stringify(e));
                }
                setLoading(true);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                setRowData([])
            })
        }
        catch (e) {
            setLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function getRowDataById(id, disposition) { //api service
        setLoading(true);
        setRowDataById({});
        try {
            getRowDataListForCampaignId(id).then(resp => {
                let data: any = structuredClone(resp.Data[0]);
                // console.log("onclickData::::::::::::", data)
                if(data?.sla === -1){
                    data.sla=''
                }
                data.dynamicDID = data?.dynamicDID === "" ? [] : data?.dynamicDID.split(',').map(value => ({
                    id: value,
                    dynamicDID: value
                }));

                data.fallbackDynamicDID = data?.fallbackDynamicDID === "" ? [] : data?.fallbackDynamicDID.split(',').map(value => ({
                    id: value,
                    fallbackDynamicDID: value
                }));

                if (showEditForm === 'Marketing') {
                    getPluginDropdown().then(resp => {
                        resp.Data.forEach(item => {
                            if (item.id === Number(data.screenPopUrl)) {
                                data.screenPopUrl = item;
                                return
                            }
                        })
                        setRowDataById(data);
                        setLoading(false);
                    }).catch(e => {
                        setLoading(false);
                        showErrorNotification(JSON.stringify(e));
                    })
                } else if (showEditForm === 'Outbound') {
                    if (data?.retryConditions === null || data?.retryConditions === "") {

                        //just doing :::::::::::::::::::verify::::::::::::::::::::
                        //for this discussion needs::::::::::::://
                        const prevRetryConditionValue = data?.retryConditions;
                        let newData = {
                            ...data,
                            prevRetryConditionValue: prevRetryConditionValue
                        }
                        setRowDataById(newData)
                        //for this discussion needs::::::::::::://
                        //just doing :::::::::::::::::::verify:::::::::::::::::::://

                        //setRowDataById(data);
                    } else if (data?.retryConditions !== null || data?.retryConditions !== "") {
                        data.retryConditions = JSON.parse(data?.retryConditions);
                        let retry = structuredClone(data);

                        // console.log("here getting value", retry);

                        //::::::::::::from here we are making it in key value pair::::::::::::::::::://
                        const comparearrayData = [{ id: 'busy', label: 'Busy' }, { id: 'congestion', label: 'Congestion' }, { id: 'noanswer', label: 'NoAnswer' }, { id: 'noresponse', label: 'NoResponse' },
                        { id: 'normalcallclearing', label: 'NormalCallClearing' }, { id: 'normalunspecified', label: 'NormalUnspecified' },
                        { id: 'not_answered', label: 'Not_Answered' }, { id: 'notanswered', label: 'NotAnswered' }, { id: 'answered', label: 'Answered' }, { id: 'user_disconnected', label: 'user_disconnected' },
                        { id: 'rejected', label: 'Rejected' }, { id: 'unknown', label: 'Unknown' }, { id: 'others', label: 'Others' }]
                        const comparearrayFail = [{ id: 'busy', label: 'Busy' }, { id: 'congestion', label: 'Congestion' }, { id: 'noanswer', label: 'NoAnswer' }, { id: 'noresponse', label: 'NoResponse' },
                        { id: 'normalcallclearing', label: 'NormalCallClearing' }, { id: 'normalunspecified', label: 'NormalUnspecified' },
                        { id: 'not_answered', label: 'Not_Answered' }, { id: 'notanswered', label: 'NotAnswered' }, { id: 'user_disconnected', label: 'user_disconnected' },
                        { id: 'rejected', label: 'Rejected' }, { id: 'unknown', label: 'Unknown' }, { id: 'others', label: 'Others' }]

                        // function convertDialStatus(dialStatus) {
                        //     return dialStatus.map(status => {
                        //         const match = comparearray.find(item => item.id === status);
                        //         return match ? match : { id: status, label: status };
                        //     });
                        // }

                        function convertDialStatus(dialStatus) {
                            if (dialStatus.includes("all")) {
                                return comparearrayFail;
                            } else {
                                return dialStatus.map(status => {
                                    const match = comparearrayData.find(item => item.id === status);
                                    return match ? match : { id: status, label: status };
                                });
                            }
                        }

                        function convertDispositions(dispositions) {
                            if (dispositions?.includes("all")) {
                                return data?.dispositions;
                            } else {
                                return dispositions.map((dispo, dispoIndex) => {
                                    const matchdis = disposition?.find(item => item.reason === dispo);
                                    return matchdis ? matchdis : { id: `${dispoIndex}_${dispo}`, reason: dispo };
                                });
                            }
                        }

                        //New things for callStatus:::::::::::::::::://
                        function convertCallStatus(callStatus) {
                            if(callStatus === 'success'){
                                return 'Success'
                            }else if(callStatus === 'fail'){
                                return 'Fail'
                            }else{
                                return 'Fail';
                            }
                        }
                        //New things for callStatus:::::::::::::::::://

                        const transformedData = retry?.retryConditions?.map(item => {
                            if (item.hasOwnProperty("dialStatus")) {
                                item.dialStatus = convertDialStatus(item.dialStatus);
                            }
                            if (item.hasOwnProperty('dispositions')) {
                                item.dispositions = convertDispositions(item.dispositions);
                            }
                            //New things for callStatus:::::::::::::::::://
                            if (item.hasOwnProperty('callStatus')) {
                                item.callStatus = convertCallStatus(item.callStatus);
                            }
                            //New things for callStatus:::::::::::::::::://
                            return item;
                        });

                        // Function to filter items based on condition
                        function filterItemsByCondition(items) {
                            return items?.map(item => {
                                if (item.condition === "" && (!item.dialStatus || item?.dialStatus?.length === 0)) {
                                    delete item.dialStatus;
                                }
                                if (item.condition === "" && (!item.dispositions || item?.dispositions?.length === 0)) {
                                    delete item.dispositions;
                                }
                                return item;
                            }).filter(item => item.condition !== "" || item.dialStatus || item.dispositions);
                        }

                        // ::::::::::::::here we are making retryCount those having not:::::::::::://
                        const newDatas = transformedData?.map((item) => {
                            const updatedConfig = item?.config?.map((configItem, configIndex) => {
                                if (!configItem.hasOwnProperty("retryCount")) {
                                    configItem.retryCount = configIndex + 1;
                                }
                                return configItem;
                            });

                            item.config = updatedConfig;
                            return item;
                        });
                        // ::::::::::::::here we are making retryCount those having not:::::::::::://

                        // const filteredData = filterItemsByCondition(transformedData);
                        const filteredData = filterItemsByCondition(newDatas);

                        //::::::::::::Till here we are making it in key value pair::::::::::::::::::://

                        //::::::::::::::::::::::from here ::::::::::::::::::::::::::::::::///
                        const convertedData = filteredData?.map((item) => {
                            if (item.hasOwnProperty('dialStatus') && item.hasOwnProperty('dispositions')) {
                                const parameters = [
                                    {
                                        type: "Customer Status",
                                        value: item.dialStatus,
                                        condition: item.condition ? item.condition : item.condition === undefined ? "" : ""
                                    },
                                    {
                                        type: "Dispositions",
                                        value: item.dispositions,
                                        condition: item.condition ? item.condition : item.condition === undefined ? "" : ""
                                    }
                                ];

                                return {
                                    parameters,
                                    config: item.config,
                                    noOfTries: item.noOfTries,
                                    callStatus: item.callStatus
                                };
                            } else if (item.hasOwnProperty('dialStatus') && !item.hasOwnProperty('dispositions')) {
                                const parameters = [
                                    {
                                        type: "Customer Status",
                                        value: item.dialStatus,
                                        condition: item.condition ? item.condition : item.condition === undefined ? "" : ""
                                    },
                                ];

                                return {
                                    parameters,
                                    config: item.config,
                                    noOfTries: item.noOfTries,
                                    callStatus: item.callStatus
                                };
                            } else if (!item.hasOwnProperty('dialStatus') && item.hasOwnProperty('dispositions')) {
                                const parameters = [
                                    {
                                        type: "Dispositions",
                                        value: item.dispositions,
                                        condition: item.condition ? item.condition : item.condition === undefined ? "" : ""
                                    }
                                ];

                                return {
                                    parameters,
                                    config: item.config,
                                    noOfTries: item.noOfTries,
                                    callStatus: item.callStatus
                                };
                            }
                        })
                        retry.retryConditions = convertedData;
                        setRowDataById(retry);
                        //::::::::::::::::::::::till here ::::::::::::::::::::::::::::::::///
                    }
                    setLoading(false);
                } else {
                    setLoading(false);
                    setRowDataById(data);
                }
            }).catch(e => {
                setLoading(false);
                showErrorNotification(JSON.stringify(e));
                setRowDataById({});
            })
        }
        catch (e) {
            setLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function getMarketingCampaign() { //api service
        setLoading(true);
        setCampaignList([]);
        try {
            getMarketingCampaignListForAdmin().then(resp => {
                setRowData(resp.Data);
                setColumnData(marketingColumnDefs);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                setRowData([])
            })
        }
        catch (e) {
            setLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function getRestoreCampaign() { //api service
        setLoading(true);
        setCampaignList([]);
        try {
            getRestoreCampaignListForAdmin().then(resp => {
                setRowData(resp.Data);
                setColumnData(restoreColumnDefs);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                setRowData([])
            })
        }
        catch (e) {
            setLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function getDataTabCampaign() { //api service
        setLoading(true);
        setCampaignList([]);
        try {
            getDownloadDataCampaignListForAdmin().then(resp => {
                setRowData(resp.Data);
                setColumnData(downloadDataTabColumnDefs);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                setRowData([])
            })
        }
        catch (e) {
            setLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function getRestoreCampaignById(id, name) { //api service
        try {
            restoreById(id).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(`Campaign ${name} Restored Successfully`);
                    getRestoreCampaign();
                } else {
                    showErrorNotification(resp?.Message ? resp?.Message : `Unable to Restore ${name} Campaign`);
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function deleteCampaignInboundById(id) { //api service
        try {
            deleteCampaignInbound(id).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(resp.Message);
                    getCampaign();
                } else {
                    showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function deleteCampaignOutboundById(id) { //api service
        try {
            deleteCampaignOutbound(id).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(resp.Message);
                    getCampaignOutboundList();
                } else {
                    showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function forceCompleteOutboundCampaign(id, name) {
        try {
            forceCompleteCampaignById(id).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(`${name} campaign is forcely stoped`);
                    getCampaignOutboundList();
                } else {
                    showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function downloadOutboundCampaignForm(data, id) {
        try {
            downloadOutboundCampaign(data, id).then(resp => {
                if (resp.Status === "Success") {
                    showSuccessNotification(resp.Message);
                } else {
                    showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function deleteChatCampaignById(id) {
        try {
            deleteChatCampaign(id).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(resp.Message);
                    // getMarketingCampaign();
                    getCampaign();
                } else {
                    showErrorNotification(resp.Message);
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function deleteMissedCallCampaignById(id) { //api service
        try {
            deleteMissedCallCampaign(id).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(resp.Message);
                    getMarketingCampaign();
                } else {
                    showErrorNotification(resp.Message);
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    const [alignment, setAlignment] = React.useState('Inbound');
    const [CamSelect, setCamapignSelect] = useState(false);
    const [gridRef, setGridRef] = useState(null)

    let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400, marginTop: '25px' }), [])

    let restoreButtonStyle = useMemo(() => ({
        fontWeight: '400',
        fontFamily: 'Roboto',
        fontSize: '12px',
        color: ' #536580',
        cursor: 'pointer',
    }), [])

    const [showForm, setShowForm] = useState({ isNew: false, show: false });
    const [showEditForm, setShowEditForm] = useState("Inbound");
    const [rowDataById, setRowDataById] = useState({});
    const [campaignPageType, setCampaignPageType] = useState("main");
    const [campaignList, setCampaignList] = useState([]);

    const [rowData, setRowData] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [dispositionDropdownData, setDispositionDropdownData] = useState([]);

    useEffect(() => {
        Userpilot.initialize('456987');
        Userpilot.identify('456987',
            {
                userid: localStorage.getItem("userId"),
                username: localStorage.getItem("userName")
            }
        );
        Userpilot.track('Campaigns', { name: localStorage.getItem("userName") });
        if (showEditForm === "Inbound") {
            getCampaign()
        } else if (showEditForm === "Outbound") {
            getCampaignOutboundList()
        } else if (showEditForm === "Marketing") {
            getMarketingCampaign()
        } else if (showEditForm === "Restore") {
            getRestoreCampaign()
        } else if (showEditForm === 'DownloadData') {
            getDataTabCampaign();
        }
        localStorage.removeItem("InboundForm");
        localStorage.removeItem("OutboundForm");
        localStorage.removeItem("MarketingForm");//For test
        localStorage.removeItem('downloadForm');
    }, [])

    const showCampaignGridList = (type) => {
        setCampaignList([]);
        if (type === 'Inbound') {
            getCampaign();
            setShowEditForm('Inbound');
        } else if (type === 'Outbound') {
            getCampaignOutboundList();
            setShowEditForm('Outbound');
        } else if (type === 'Marketing') {
            getMarketingCampaign();
            setShowEditForm('Marketing');
        } else if (type === 'Restore') {
            getRestoreCampaign();
            setShowEditForm('Restore');
        } else if (type === 'DownloadData') {
            getDataTabCampaign();
            setShowEditForm('DownloadData');
        }
    }

    const onLinkClickBulkAction = () => {
        setAlignment('DownloadData');
        showCampaignGridList('DownloadData');
    }

    const GetNameRenderer = (props) => {
        return (
            <Tooltip title={props?.value ? props?.value : ""}>
                <span style={{ color: '#3D8BF8', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{props.value}</span>
            </Tooltip>
        )
    }

    const GetNameFormatter = (params) => {
        const names = params?.value?.split(", ").map((name) => name.trim());

        let displayName;

        if (names.length > 1) {
            const firstName = names.slice(0, 1);
            const remainingCount = names.length - 1;
            const remainingnames = names.slice(1).join(", ");
            displayName = (
                <>
                    {firstName}
                    <Tooltip title={remainingnames}>
                        <Chip
                            sx={{ marginLeft: '5px' }}
                            label={`+${remainingCount}`}
                            variant="outlined"
                            size="small"
                            color="primary"
                        />
                    </Tooltip>
                </>
            )
        } else {
            displayName = params?.value;
        }

        return (
            <span style={{ color: '#3D8BF8', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{displayName}</span>
        )
    }

    const DateFormatter = (params) => {
        if (params.props) {
            const date = new Date(params.props);
            const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
            return date.toLocaleString("en-GB", options);
        }
        const date = new Date(params.value);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return date.toLocaleString("en-GB", options);
    }

    const FilterFormatter = (params) => {
        const jsonParse = JSON.parse(params?.data?.filters);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const vertOpen = Boolean(anchorEl);
        const anchorRef = React.useRef();

        const morevertclickMarketing = (event) => {
            setTimeout(() => setAnchorEl(anchorRef?.current, 1));
        }

        const handleCloseVertMarketing = () => {
            setAnchorEl(null);
        }

        return (
            <div style={{ display: 'flex', alignItems: 'start', width: '100%', justifyContent: 'space-between' }}>
                <img ref={anchorRef}
                    style={{ cursor: 'pointer' }}
                    onClick={morevertclickMarketing}
                    src={`${process.env.PUBLIC_URL}/icons/info-icon.svg`}
                    alt="info_grid"
                />
                <div
                    ref={anchorRef} >
                    <Menu
                        sx={{
                            maxHeight: "70vh",
                            "& .MuiPaper-root": {
                                backgroundColor: "white",
                                minWidth: "20px",
                                // height: "62px",
                            },
                            "& .MuiMenu-paper": {
                                backgroundColor: "white",
                                boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                                border: "0.0625rem solid #E6E5E6",
                                borderRadius: "12px",
                                marginLeft: '-140px',
                                // marginTop: '15px',
                            },
                        }}
                        anchorEl={anchorEl}
                        open={vertOpen}
                        onClose={handleCloseVertMarketing}
                    >
                        <PopupState variant="popover" popupId="demo-popup-menu-copy-mar" >
                            {(popupState) => (
                                <React.Fragment>
                                    <Stack direction={'row'} display={'flex'}>
                                        <ListItemButton sx={{ ...vertIconMenuStyle }}>
                                            dialstatus:
                                        </ListItemButton>
                                        <ListItemButton sx={{ ...vertIconMenuStyle }}>
                                            {(jsonParse?.dialstatus === undefined) ? '[]' : jsonParse?.dialstatus}
                                        </ListItemButton>
                                    </Stack>
                                    <Stack direction={'row'} display={'flex'}>
                                        <ListItemButton sx={{ ...vertIconMenuStyle }}>
                                            dispositions:
                                        </ListItemButton>
                                        <ListItemButton sx={{ ...vertIconMenuStyle }}>
                                            {(jsonParse?.dispositions === undefined) ? '[]' : jsonParse?.dispositions}
                                        </ListItemButton>
                                    </Stack>
                                    <Stack direction={'row'} display={'flex'}>
                                        <ListItemButton sx={{ ...vertIconMenuStyle }}>
                                            fromDate:
                                        </ListItemButton>
                                        <ListItemButton sx={{ ...vertIconMenuStyle }}>
                                            {(jsonParse?.fromDate === null || jsonParse?.fromDate === undefined) ? '' : jsonParse?.fromDate.toString()}
                                        </ListItemButton>
                                    </Stack>
                                    <Stack direction={'row'} display={'flex'}>
                                        <ListItemButton sx={{ ...vertIconMenuStyle }}>
                                            sortby:
                                        </ListItemButton>
                                        <ListItemButton sx={{ ...vertIconMenuStyle }}>
                                            {(jsonParse?.sortby === "" || jsonParse?.sortby === null) ? '' : (jsonParse?.sortby === "0" ? 'DateCreated' : "DateUpdated")}
                                        </ListItemButton>
                                    </Stack>
                                    <Stack direction={'row'} display={'flex'}>
                                        <ListItemButton sx={{ ...vertIconMenuStyle }}>
                                            status:
                                        </ListItemButton>
                                        <ListItemButton sx={{ ...vertIconMenuStyle }}>
                                            {(jsonParse?.status === "" || jsonParse?.status === null) ? '' : jsonParse?.status.toString()}
                                        </ListItemButton>
                                    </Stack>
                                    <Stack direction={'row'} display={'flex'}>
                                        <ListItemButton sx={{ ...vertIconMenuStyle }}>
                                            toDate:
                                        </ListItemButton>
                                        <ListItemButton sx={{ ...vertIconMenuStyle }}>
                                            {(jsonParse?.toDate === null || jsonParse?.toDate === undefined) ? '' : jsonParse?.toDate.toString()}
                                        </ListItemButton>
                                    </Stack>
                                </React.Fragment>
                            )}
                        </PopupState>
                    </Menu>
                </div>
            </div>
        )
    }

    const ExpiryDateFormatterForDownloadTab = (params) => {
        if (params.props) {
            const date = new Date(params.props);
            date.setHours(date.getHours() + 24);
            const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
            return date.toLocaleString("en-GB", options);
        }
        const date = new Date(params.value);
        date.setHours(date.getHours() + 24);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return date.toLocaleString("en-GB", options);
    }

    const OflineCheckBox = (props) => {
        if (props?.data?.offLineMode === true)
            return <CustomizedCheckbox size="small" defaultChecked disabled={true} />;
        return <CustomizedCheckbox size="small" disabled={true} />;
    }

    const ManualDialCheckBox = (props) => {
        if (props?.data?.allowedManual === true)
            return <CustomizedCheckbox size="small" defaultChecked disabled={true} />;
        return <CustomizedCheckbox size="small" disabled={true} />;
    }

    const MenuOnInboundGridClick = (props) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const vertOpen = Boolean(anchorEl);
        const anchorRef = React.useRef();
        const [deleteMode, setDeleteMode] = useState(false);
        const [copyForm, setCopyForm] = useState(false);

        const morevertclickInbound = (event) => {
            setTimeout(() => setAnchorEl(anchorRef?.current, 1));
        }

        const handleCloseVertInbound = () => {
            setAnchorEl(null);
        }

        const onClickDeleteInboundDialogue = (type) => {
            if (type === "InBound") {
                deleteCampaignInboundById(props.data.campaignId);
            } else if (type === "Chat") {
                deleteChatCampaignById(props.data.campaignId);
            }
            setDeleteMode(false);
        }

        const openMenuPopButton = (e, buttonType) => {
            setAnchorEl(null);
            if (buttonType === 'copy') {
                setCopyForm(true);
            }
            else if (buttonType === 'delete') {
                setDeleteMode(true);
            }
        }

        const closeMenuPopButton = (e, buttonType) => {
            if (buttonType === 'copy') {
                setCopyForm(false);
            }
            else if (buttonType === 'delete') {
                setDeleteMode(false);
            }
        }

        const openInNewWindow = (e, data) => {
            try {
                const url = new URL(IVR_FLOW_URL);
                const currentApplicationHost = window.location.host;
                url.host = currentApplicationHost;
                const updatedUrl = url.toString();
                e.stopPropagation();
                let userId = getUserId();

                //passing parent user Id as userId for opening ivr flow for subadmin
                if(localStorage.getItem('parentUser') && localStorage.getItem('parentUser') !== 'null'){
                    userId = JSON.parse(localStorage.getItem('parentUser')).id;
                }
                if (data?.type === 1) {
                    window.open(`${updatedUrl}/index.html?#/flows/${data.flowId}/userId/${userId}`, 'IVR Designer', 'location=1,status=1,scrollbars=1,addressbar=no,width=900,height=300')
                    // window.open(`https://beta-ivr.ccaas.ozonetel.com/ivrdd/index.html?#/flows/${data.id}/userId/${userId}`, 'IVR Designer', 'location=1,status=1,scrollbars=1,addressbar=no,width=900,height=300')
                } else if ((data?.type === 3)) {
                    window.open(`${updatedUrl}/botv2/index.html?flowId=${data?.flowId}&userId=${userId}`, 'IVR Designer', 'location=1,status=1,scrollbars=1,addressbar=no,width=900,height=300')
                    // window.open(`https://beta-ivr.ccaas.ozonetel.com/ivrdd/bot/index.html?#/flows/${data.id}/userId/${userId}`, 'IVR Designer', 'location=1,status=1,scrollbars=1,addressbar=no,width=900,height=300')
                } else {
                    window.open(`${updatedUrl}/bot/index.html?#/flows/${data?.flowId}/userId/${userId}`, 'IVR Designer', 'location=1,status=1,scrollbars=1,addressbar=no,width=900,height=300')
                }
            } catch (e) {
                showErrorNotification("Failed to open url");
            }
        };

        return (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                <Tooltip title={(props?.data?.ivrFlow) ? props?.data?.ivrFlow?.flowName : ' '}>
                    <label style={{ color: '#3D8BF8', cursor: 'pointer', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }} onClick={(e) => openInNewWindow(e, props?.data?.ivrFlow)}> {(props?.data?.ivrFlow) ? props?.data?.ivrFlow?.flowName : ' '} </label>
                </Tooltip>
                {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit')) &&
                    <img className="campaign-icon" ref={anchorRef}
                        src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                        alt="edit"
                        onClick={morevertclickInbound}>
                    </img>
                }
                <div
                    ref={anchorRef} >
                    <Menu
                        sx={{
                            maxHeight: "70vh",
                            "& .MuiPaper-root": {
                                backgroundColor: "white",
                                minWidth: "133px",
                                // height: "62px",
                            },
                            "& .MuiMenu-paper": {
                                backgroundColor: "white",
                                boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                                border: "0.0625rem solid #E6E5E6",
                                borderRadius: "12px",
                                marginLeft: '-8px',
                                marginTop: '15px',
                            },
                        }}
                        anchorEl={anchorEl}
                        open={vertOpen}
                        onClose={handleCloseVertInbound}
                    >
                        <PopupState variant="popover" popupId="demo-popup-menu-copy-in" >
                            {(popupState) => (
                                <React.Fragment>
                                    <ListItemButton sx={{ ...vertIconMenuStyle }}
                                        onClick={(e) => openMenuPopButton(e, 'copy')}
                                        disabled={newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit')}
                                    >
                                        Make a copy
                                    </ListItemButton>
                                    <ListItemButton sx={{ ...vertIconMenuStyle, marginTop: '8px' }}
                                        onClick={(e) => openMenuPopButton(e, 'delete')}
                                        disabled={newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit')}
                                    >
                                        Delete
                                    </ListItemButton>

                                </React.Fragment>
                            )}
                        </PopupState>
                    </Menu>

                    {copyForm && (
                        <div>
                            <CopyCampaignForm
                                id={props?.data?.campaignId} campaignName={props?.data?.campaignName} type={props?.data?.campaignType}
                                onClose={(e) => closeMenuPopButton(e, 'copy')}
                                updateCopyCampaign={getCampaign}
                            />
                        </div>
                    )}

                    {deleteMode &&
                        <DeleteDialog
                            title="Delete Campaign Inbound"
                            content={'This will delete this Campaign Inbound permanently. You cannot undo this action.'}
                            onClose={(e) => closeMenuPopButton(e, 'delete')}
                            open={true}
                            deleteFunction={() => onClickDeleteInboundDialogue(props?.data?.campaignType)}
                        />
                    }
                </div>
            </div>
        )
    }

    const MenuOnOutboundGridClick = (props) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const vertOpen = Boolean(anchorEl);
        const anchorRef = React.useRef();
        const [deleteMode, setDeleteMode] = useState(false);
        let id = props?.data?.campaignId;

        const [addForm, setAddForm] = useState(false);
        const [copyForm, setCopyForm] = useState(false);
        const [forceCompleteForm, setForceCompleteForm] = useState(false);
        const [downloadForm, setDownloadForm] = useState(false);
        const [addData, setAddData] = useState({});
        const [resetCampaign, setResetCampaign] = useState(false);
        const [addloading, setAddLoading] = useState(false);

        async function dataForDialogueById(id) {
            setAddLoading(true);
            try {
                getRowDataListForCampaignId(id).then(resp => {
                    setAddData(resp.Data[0]);
                    setAddLoading(false);
                    return
                }).catch(e => {
                    setAddLoading(false);
                    showErrorNotification(JSON.stringify(e));
                })
            }
            catch (e) {
                setAddLoading(false);
                showErrorNotification(JSON.stringify(e));
            }
        }

        let dataForDownload = {
            "dispositionName": "ALL",
            "status": "ALL",
            "dialStatus": "ALL",
            "fromDate": null,
            "toDate": null,
            "dateSortBy": "0"
        };

        const morevertclick = (event) => {
            setTimeout(() => setAnchorEl(anchorRef?.current, 1));
        }

        const handleCloseVert = () => {
            setAnchorEl(null);
        }

        const onClickDeleteOutboundDialogue = () => {
            deleteCampaignOutboundById(props.data.campaignId);
            setDeleteMode(false);
        }

        const onClickForceCompleteDialogue = () => {
            forceCompleteOutboundCampaign(props.data.campaignId, props.data.campaignName);
            setForceCompleteForm(false);
        }

        const onClickDownloadDialogue = () => {
            downloadOutboundCampaignForm(dataForDownload, props.data.campaignId);
            setDownloadForm(false);
        }

        const onLinkClick = (e) => { //this is new added for going to the Tab
            setAlignment('DownloadData');
            showCampaignGridList('DownloadData');
        }

        const openMenuPopButton = (e, buttonType) => {
            setAnchorEl(null);
            if (buttonType === 'AddData') {
                dataForDialogueById(id);
                setAddForm(true);
            }
            else if (buttonType === 'copy') {
                setCopyForm(true);
            }
            else if (buttonType === 'delete') {
                setDeleteMode(true);
            }
            else if (buttonType === 'forceComplete') {
                setForceCompleteForm(true);
            }
            else if (buttonType === 'download') {
                dataForDialogueById(id);
                setDownloadForm(true);
            }
            else if (buttonType === 'resetCampaign') {
                dataForDialogueById(id);
                setResetCampaign(true);
            }

        }

        const closeMenuPopButton = (e, buttonType) => {
            if (buttonType === 'AddData') {
                setAddForm(false);
            }
            else if (buttonType === 'copy') {
                setCopyForm(false);
            }
            else if (buttonType === 'delete') {
                setDeleteMode(false);
            }
            else if (buttonType === 'forceComplete') {
                setForceCompleteForm(false);
            }
            else if (buttonType === 'download') {
                setDownloadForm(false);
            }
            else if (buttonType === 'resetCampaign') {
                setResetCampaign(false);
                getCampaignOutboundList();
            }

        }

        return (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                {(props?.data?.customerFirst === true || props?.data?.customerFirst === 'true') ? <CustomizedCheckbox size='small' defaultChecked disabled={true} /> : <CustomizedCheckbox size='small' disabled={true} />}
                {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit')) &&
                    <img className="campaign-icon" ref={anchorRef}
                        src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                        alt="edit"
                        onClick={morevertclick}>
                    </img>
                }
                <div
                    ref={anchorRef} >
                    <Menu
                        sx={{
                            maxHeight: "70vh",
                            "& .MuiPaper-root": {
                                backgroundColor: "white",
                                minWidth: "133px",
                                // height: "62px",
                            },
                            "& .MuiMenu-paper": {
                                backgroundColor: "white",
                                boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                                border: "0.0625rem solid #E6E5E6",
                                borderRadius: "12px",
                                marginLeft: '-8px',
                                marginTop: '15px',
                            },
                        }}
                        anchorEl={anchorEl}
                        open={vertOpen}
                        onClose={handleCloseVert}
                    >
                        <PopupState variant="popover" popupId="demo-popup-menu" >
                            {(popupState) => (
                                <React.Fragment>
                                    <ListItemButton sx={{ ...vertIconMenuStyle }}
                                        onClick={(e) => openMenuPopButton(e, 'forceComplete')}
                                        disabled={props?.data?.position === "COMPLETED" || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'View') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit')}
                                    >
                                        Force Complete
                                    </ListItemButton>
                                    {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) &&
                                        <ListItemButton sx={{ ...vertIconMenuStyle, marginTop: '8px' }}
                                            onClick={(e) => openMenuPopButton(e, 'AddData')}
                                            disabled={props?.data?.position === "RUNNING"}
                                        >
                                            Add Data
                                        </ListItemButton>
                                    }
                                    <ListItemButton sx={{ ...vertIconMenuStyle, marginTop: '8px' }}
                                        onClick={(e) => openMenuPopButton(e, 'download')}
                                        disabled={newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'View') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit')}
                                    >
                                        Download
                                    </ListItemButton>
                                    <ListItemButton sx={{ ...vertIconMenuStyle, marginTop: '8px' }}
                                        onClick={(e) => openMenuPopButton(e, 'copy')}
                                        disabled={props?.data?.position === "WARM" || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'View') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit')}
                                    >
                                        Make a copy
                                    </ListItemButton>
                                    <ListItemButton sx={{ ...vertIconMenuStyle, marginTop: '8px' }}
                                        onClick={(e) => openMenuPopButton(e, 'delete')}
                                        disabled={props?.data?.position === "WARM" || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'View') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit')}
                                    >
                                        Delete
                                    </ListItemButton>
                                    <ListItemButton sx={{ ...vertIconMenuStyle, marginTop: '8px' }}
                                        onClick={(e) => openMenuPopButton(e, 'resetCampaign')}
                                        disabled={props?.data?.position === "RUNNING" || props?.data?.position === "READY"  || props?.data?.position === "WARM" || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'View') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit')}
                                    >
                                        Reset Campaign
                                    </ListItemButton>

                                </React.Fragment>
                            )}
                        </PopupState>
                    </Menu>

                    {copyForm && (
                        <div>
                            <CopyCampaignForm
                                id={props?.data?.campaignId} campaignName={props?.data?.campaignName} type={props?.data?.campaignType}
                                onClose={(e) => closeMenuPopButton(e, 'copy')}
                                updateCopyCampaignOutbound={getCampaignOutboundList}
                            />
                        </div>
                    )}

                    {addForm && (
                        <div>
                            <AddCampaignDataform
                                disabled={addloading}
                                updateAddDataForm={getCampaignOutboundList}
                                props={addData}
                                onClose={(e) => closeMenuPopButton(e, 'AddData')}
                            />
                        </div>
                    )}

                    {deleteMode &&
                        <DeleteDialog
                            title="Delete Campaign Outbound"
                            content={'This will delete this Campaign Outbound permanently. You cannot undo this action.'}
                            onClose={(e) => closeMenuPopButton(e, 'delete')}
                            open={true}
                            deleteFunction={onClickDeleteOutboundDialogue}
                        />
                    }

                    {forceCompleteForm &&
                        <DeleteDialog
                            mode="Add"
                            title="Force Complete Campaign Outbound"
                            content={'Are you sure you want to Force Complete this campaign ?'}
                            onClose={(e) => closeMenuPopButton(e, 'forceComplete')}
                            open={true}
                            deleteFunction={onClickForceCompleteDialogue}
                        />
                    }

                    {downloadForm &&
                        // <DownloadDataForm
                        //     props={props?.data?.campaignId}
                        //     campaignName={props?.data?.campaignName}
                        //     data={addData}
                        //     onClose={(e) => closeMenuPopButton(e, 'download')}
                        //     open={true}
                        // // downloadFunction={onClickDownloadDialogue}
                        // />
                        <BulkCampaignDownloadDataForm
                            disabled={addloading}
                            onClose={(e) => closeMenuPopButton(e, 'download')}
                            open={true}
                            list={props?.data?.campaignId}
                            link={onLinkClick} //this is new added for going to the Tab
                        />
                    }

                    {resetCampaign && (
                        <div>
                            <ResetCampaignForm
                                disabled={addloading}
                                ID={props?.data?.campaignId}
                                props={addData}
                                onClose={(e) => closeMenuPopButton(e, 'resetCampaign')}
                            />
                        </div>
                    )}


                </div>
            </div>
        )
    }

    const MenuGetDialMethod = (props) => {

        return (
            <div style={{ alignItems: 'center', width: '100%', justifyContent: 'space-between' }} >
                {props.data.campaignType}
                <label style={{ paddingLeft: '2px' }}>{(props?.data?.dialMethod) ? `(${props.data.dialMethod})` : '(Nonagentwise)'}</label>

            </div>
        )
    }

    const MenuOnMarketingGridClick = (props) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const vertOpen = Boolean(anchorEl);
        const anchorRef = React.useRef();
        const [deleteMode, setDeleteMode] = useState(false);
        const [copyForm, setCopyForm] = useState(false);

        const morevertclickMarketing = (event) => {
            setTimeout(() => setAnchorEl(anchorRef?.current, 1));
        }

        const onClickDeleteMarketingDialogue = () => {
            if (props.data.campaignType === 'Chat') {
                deleteChatCampaignById(props.data.campaignId);
            } else if (props.data.campaignType === 'MissedCall') {
                deleteMissedCallCampaignById(props.data.campaignId);
            }
            setDeleteMode(false);
        }

        const handleCloseVertMarketing = () => {
            setAnchorEl(null);
        }

        const openMenuPopButton = (e, buttonType) => {
            setAnchorEl(null);
            if (buttonType === 'copy') {
                setCopyForm(true);
            }
            else if (buttonType === 'delete') {
                setDeleteMode(true);
            }
        }

        const closeMenuPopButton = (e, buttonType) => {
            if (buttonType === 'copy') {
                setCopyForm(false);
            }
            else if (buttonType === 'delete') {
                setDeleteMode(false);
            }
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                <label>
                    {/* {moment(props.value.modifiedDate).format('DD-MMM-YYYY, HH:mm:ss')} */}
                    <DateFormatter props={props.value} />
                </label>
                {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit')) &&
                    <img className="campaign-icon" ref={anchorRef}
                        src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                        alt="edit"
                        onClick={morevertclickMarketing}>
                    </img>
                }
                <div
                    ref={anchorRef} >
                    <Menu
                        sx={{
                            maxHeight: "70vh",
                            "& .MuiPaper-root": {
                                backgroundColor: "white",
                                minWidth: "133px",
                                // height: "62px",
                            },
                            "& .MuiMenu-paper": {
                                backgroundColor: "white",
                                boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                                border: "0.0625rem solid #E6E5E6",
                                borderRadius: "12px",
                                marginLeft: '-8px',
                                marginTop: '15px',
                            },
                        }}
                        anchorEl={anchorEl}
                        open={vertOpen}
                        onClose={handleCloseVertMarketing}
                    >
                        <PopupState variant="popover" popupId="demo-popup-menu-copy-mar" >
                            {(popupState) => (
                                <React.Fragment>
                                    <ListItemButton sx={{ ...vertIconMenuStyle }}
                                        onClick={(e) => openMenuPopButton(e, 'copy')}
                                        disabled={newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit')}
                                    >
                                        Make a copy
                                    </ListItemButton>
                                    {props.data.campaignType === 'MissedCall' &&
                                        <ListItemButton sx={{ ...vertIconMenuStyle, marginTop: '8px' }}
                                            onClick={(e) => openMenuPopButton(e, 'delete')}
                                            disabled={newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit')}
                                        >
                                            Delete
                                        </ListItemButton>
                                    }

                                </React.Fragment>
                            )}
                        </PopupState>
                    </Menu>

                    {copyForm && (
                        <div>
                            <CopyCampaignForm
                                id={props?.data?.campaignId} campaignName={props?.data?.campaignName} type={props?.data?.campaignType}
                                onClose={(e) => closeMenuPopButton(e, 'copy')}
                                updateCopyCampaignMarketing={getMarketingCampaign}
                            />
                        </div>
                    )}

                    {deleteMode && (props.data.campaignType === 'Chat' || props.data.campaignType === 'MissedCall') &&
                        <DeleteDialog
                            title={(props.data.campaignType === 'Chat') ? 'Delete Chat Campaign' : 'Delete Missed Call Campaign'}
                            content={'This will delete this Campaign Marketing permanently. You cannot undo this action.'}
                            onClose={(e) => closeMenuPopButton(e, 'delete')}
                            open={true}
                            deleteFunction={onClickDeleteMarketingDialogue}
                        />
                    }
                </div>
            </div>
        )
    }

    const restoreCampaign = (e, props) => {
        getRestoreCampaignById(props.data.campaignId, props.data.campaignName);
    }

    const RestoreClick = (props) => {
        return <div style={restoreButtonStyle} onClick={(e) => restoreCampaign(e, props)}>Restore</div>;
    }

    const DownloadUrlClick = (props) => {

        const downloadIconClick = (data) => {
            // Create an anchor element
            const downloadLink = document.createElement('a');
            downloadLink.href = data;

            // Set the download attribute to give it a filename when downloaded
            downloadLink.download = 'Campaign_Data_downloaded-file';

            // Set the target attribute to '_blank' to open in a new tab
            downloadLink.target = '_blank';

            // Append the anchor to the document and trigger the click event
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // Clean up and remove the anchor element
            document.body.removeChild(downloadLink);
        }

        return (
            <>
                {props?.data?.downloadUrl ?
                    <img className="icon"
                        src={`${process.env.PUBLIC_URL}/icons/download_blue_icon.svg`}
                        alt="edit"
                        onClick={() => downloadIconClick(props?.data?.downloadUrl)}
                    >
                    </img>
                    :
                    null
                }
            </>
        )
    }

    const StartToggleRendered = (props) => {
        let startStopValue = false;
        let message = "started";
        let startData = { "action": "stop" };
        if (props.data.position === "RUNNING") {
            startStopValue = true;
            startData = { "action": "stop" };
        } else {
            startStopValue = false;
            startData = { "action": "start" };
        }

        const [startStop, setStartStop] = useState(startStopValue);
        const [startStopConfirmationDialogue, setStartStopConfirmationDialogue] = useState(false);

        const handleChange = (e, name) => {
            setStartStop(e.target.checked);
            setStartStopConfirmationDialogue(true);
        };

        function checkToggleDisableStatus(type) {
            if (type === "PushData" || type === 'SMS_OUT' || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'View') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'dataUpload')) {
                return true
            }
            // if (showEditForm === 'Inbound' && authPage(AdminPages.INBOUND_CAMP_EDIT)) {
            //     return false
            // } else if (showEditForm === 'Marketing' && authPage(AdminPages.MARKETING_CAMP_EDIT)) {
            //     return false
            // }
            // else if (showEditForm === 'Outbound' && authPage(AdminPages.OUTBOUND_CAMP_EDIT)) {
            //     return false
            // }
            if (showEditForm === 'Inbound' && (newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full'))) {
                return false
            } else if (showEditForm === 'Marketing' && (newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full'))) {
                return false
            }
            else if (showEditForm === 'Outbound' && (newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full'))) {
                return false
            }
        }

        const onCloseStartStopDialogue = () => {
            setStartStopConfirmationDialogue(false);
            setStartStop(startStopValue);
        }
        return (
            <div style={{ paddingTop: '22px' }}>
                {startStopConfirmationDialogue && (
                    <DeleteDialog
                        mode='Add'
                        title={'Start/Stop State Confirmation'}
                        content={"Are you Sure you want to change the Start/Stop State?"}
                        onClose={() => { onCloseStartStopDialogue() }}
                        open={startStopConfirmationDialogue}
                        deleteFunction={() => { updateStartStop(props.data.campaignId, startData, props.data.campaignType, props.data.campaignName, message); }}
                    />
                )}
                {props?.data?.position !== "COMPLETED" && props?.data?.position !== "WARM" &&
                    <SimpleSwitch
                        name={props.value}
                        disabled={checkToggleDisableStatus(props?.data?.campaignType)}
                        checked={startStop}
                        onChange={(e) => handleChange(e, props.value)}
                    />
                }
            </div>
        )
    }

    async function updateStartStop(id, data, campaignType, name, message) {
        if (data.action === "start") {
            message = "started";
        } else if (data.action === "stop") {
            message = "stopped";
        }
        try {
            startStopCampaignById(id, data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        showSuccessNotification(`Campaign ${name} ${message} successfully`);
                        if (campaignType === "InBound") {
                            getCampaign();
                        } else if (campaignType === "Chat") {
                            //// getMarketingCampaign();
                            getCampaign();
                        } else if (campaignType === "MissedCall") {
                            getMarketingCampaign();
                        }
                        //  else if (campaignType === "PushData" || campaignType === "SMS_OUT") {
                        //     getMarketingCampaign();
                        // } 
                        else {
                            getCampaignOutboundList();
                        }
                    } else {
                        showErrorNotification(JSON.stringify(resp.Message));
                    }
                })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    const inboundColumnDefs = [
        {
            field: 'stop/Start',
            headerName: 'Stop / Start',
            minWidth: 135,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            cellRenderer: StartToggleRendered

        },
        {
            field: 'campaignName',
            headerName: 'Campaign Name',
            cellRenderer: GetNameRenderer,

        },
        {
            field: 'did', headerName: 'DID / Campaign ID', minWidth: 180, enableRowGroup: false
        },
        { field: 'campaignType', headerName: 'Campaign Type' },
        { field: 'position', headerName: 'Positions', minWidth: 100 },
        {
            field: 'callPrefix', headerName: 'Call Prefix', minWidth: 100, valueFormatter: function (params) {
                return params.value || '-';
            }
        },
        {
            field: 'createdDate', headerName: 'Created On', minWidth: 180, cellRenderer: DateFormatter,
            enableRowGroup: false
        },
        {
            field: 'modifiedDate',
            minWidth: 180,
            headerName: 'Modified On',
            cellRenderer: DateFormatter,
        },
        {
            field: 'offLineMode',
            headerName: 'Offline Mode',
            cellRenderer: OflineCheckBox,
            minWidth: 120,
        },
        {
            field: 'allowedManual',
            headerName: 'Allowed Manual Dialling',
            cellRenderer: ManualDialCheckBox,
            minWidth: 130
        },
        {
            field: 'ivrFlow',
            headerName: 'IVR Flow',
            minWidth: 240,
            cellRenderer: MenuOnInboundGridClick,

        },
    ]
    const inboundColumnDefsIVR = [
        {
            field: 'stop/Start',
            headerName: 'Stop / Start',
            minWidth: 135,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            cellRenderer: StartToggleRendered

        },
        {
            field: 'campaignName',
            headerName: 'Campaign Name',
            cellRenderer: GetNameRenderer,

        },
        {
            field: 'did', headerName: 'DID / Campaign ID', minWidth: 180, enableRowGroup: false
        },
        { field: 'campaignType', headerName: 'Campaign Type' },
        { field: 'position', headerName: 'Positions', minWidth: 100 },
        {
            field: 'callPrefix', headerName: 'Call Prefix', minWidth: 100, valueFormatter: function (params) {
                return params.value || '-';
            }
        },
        {
            field: 'createdDate', headerName: 'Created On', minWidth: 180, cellRenderer: DateFormatter,
            enableRowGroup: false
        },
        {
            field: 'modifiedDate',
            minWidth: 180,
            headerName: 'Modified On',
            cellRenderer: DateFormatter,
        },
        {
            field: 'offLineMode',
            headerName: 'Offline Mode',
            cellRenderer: OflineCheckBox,
            minWidth: 120,
        },
        {
            field: 'allowedManual',
            headerName: 'Allowed Manual Dialling',
            cellRenderer: ManualDialCheckBox,
            minWidth: 130
        },
    ]

    const outboundColumnDefs = [
        {
            field: 'stop/Start',
            headerName: 'Stop / Start',
            minWidth: 135,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            cellRenderer: StartToggleRendered

        },
        {
            field: 'campaignName',
            headerName: 'Campaign Name',
            cellRenderer: GetNameRenderer,

        },
        { field: 'did', headerName: 'DID', enableRowGroup: false, },
        {
            field: 'campaignType', headerName: 'Campaign Type',
            minWidth: 200,
            cellRenderer: MenuGetDialMethod,
        },
        { field: 'position', headerName: 'Positions', minWidth: 100 },
        {
            field: 'callPrefix', headerName: 'Call Prefix', minWidth: 100, valueFormatter: function (params) {
                return params.value || '-';
            }
        },
        {
            field: 'createdDate', headerName: 'Created On', minWidth: 180,
            cellRenderer: DateFormatter,
            enableRowGroup: false
        },
        {
            field: 'modifiedDate',
            minWidth: 180,
            headerName: 'Modified Date',
            cellRenderer: DateFormatter,
        },
        {
            field: 'offLineMode',
            headerName: 'Offline On',
            minWidth: 120,
            cellRenderer: OflineCheckBox,
        },
        {
            field: 'allowedManual',
            headerName: 'Allowed Manual Dialling',
            enableRowGroup: false,
            cellRenderer: ManualDialCheckBox,
            minWidth: 130
        },
        {
            field: 'customerFirst',
            headerName: 'Dial Customer First',
            cellRenderer: MenuOnOutboundGridClick,
            minWidth: 120
        },
    ]

    const marketingColumnDefs = [
        {
            field: 'stop/Start',
            headerName: 'Stop / Start',
            maxWidth: 135,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            cellRenderer: StartToggleRendered

        },
        {
            field: 'campaignName',
            maxWidth: 200,
            headerName: 'Campaign Name',
            cellRenderer: GetNameRenderer,

        },
        {
            field: 'did', headerName: 'DID/Sender ID', maxWidth: 280, valueFormatter: function (params) {
                return params.value || '---';
            }, enableRowGroup: false,
        },
        { field: 'campaignType', headerName: 'Campaign Type', maxWidth: 190 },
        { field: 'position', headerName: 'Positions', maxWidth: 150 },
        {
            field: 'createdDate', headerName: 'Created On', minWidth: 170,
            cellRenderer: DateFormatter,
            enableRowGroup: false
        },
        {
            field: 'modifiedDate',
            minWidth: 170,
            headerName: 'Modified On',
            cellRenderer: MenuOnMarketingGridClick,
        },
    ]

    const restoreColumnDefs = [
        {
            field: 'campaignName',
            headerName: 'Campaign Name',
            cellRenderer: GetNameRenderer,

        },
        { field: 'did', headerName: 'DID', enableRowGroup: false, },
        {
            field: 'modifiedDate',
            minWidth: 180,
            headerName: 'Modified on',
            cellRenderer: DateFormatter,
        },
        {
            field: 'createdDate', headerName: 'Created On', minWidth: 180, enableRowGroup: false, cellRenderer: DateFormatter,
        },
        { field: 'campaignType', headerName: 'Campaign Type' },
        { field: 'hello', headerName: '', cellRenderer: RestoreClick },
    ]

    const downloadDataTabColumnDefs = [
        {
            field: 'campaignName',
            headerName: 'Campaigns',
            cellRenderer: GetNameFormatter,
            minWidth: 220
        },
        { field: 'filters', headerName: 'Filters', enableRowGroup: false, minWidth: 60, cellRenderer: FilterFormatter },
        {
            field: 'startTime', headerName: 'Created Time', minWidth: 160, enableRowGroup: false, cellRenderer: DateFormatter,
        },
        {
            field: 'startTime',
            minWidth: 160,
            headerName: 'Expiry Time',
            cellRenderer: ExpiryDateFormatterForDownloadTab,
        },
        { field: 'userName', headerName: 'User', minWidth: 90 },
        { field: 'status', headerName: 'Status', minWidth: 90 },
        { field: 'downloadUrl', headerName: 'DownloadUrl', minWidth: 90, cellRenderer: DownloadUrlClick },
    ]

    function onClickRow(rowItem) {
        let id = rowItem.data.campaignId;
        getRowDataById(id, dispositionDropdownData);
        setCampaignPageType("true");
        setShowForm({ isNew: false, show: true })
    }

    function addSelectedRowData(selectedData) {
        setCampaignList(selectedData);
    }

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment);
    };

    const handleClick = (event) => {
        setOpen(true);
        setCamapignSelect(true);
    };

    const continueClicked = (type) => {
        setOpen(false);
        // if (showEditForm === 'Inbound' && (authPage(AdminPages.INBOUND_CAMP_EDIT))) {
        //     setCampaignPageType("inbound");
        // }
        // else if (showEditForm === 'Outbound' && (authPage(AdminPages.OUTBOUND_CAMP_EDIT))) {
        //     setCampaignPageType("outbound");
        // }
        // else if (showEditForm === 'Marketing' && (authPage(AdminPages.MARKETING_CAMP_EDIT))) {
        //     setCampaignPageType("marketing");
        // }
        if (showEditForm === 'Inbound' && (newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full'))) {
            setCampaignPageType("inbound");
        }
        else if (showEditForm === 'Outbound' && (newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full'))) {
            setCampaignPageType("outbound");
        }
        else if (showEditForm === 'Marketing' && (newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full'))) {
            setCampaignPageType("marketing");
        }
        setShowForm({ isNew: true, show: true });
    }

    const dialogueCamapignSelectOutbound = () => {
        setAlignment('Outbound');
        showCampaignGridList("Outbound")
    }

    const dialogueCamapignSelectInbound = () => {
        setAlignment('Inbound');
        showCampaignGridList("Inbound");
    }

    const dialogueCamapignSelectMarketing = () => {
        setAlignment('Marketing');
        showCampaignGridList('Marketing');
    }

    const closeCampaignsForm = () => {
        setCampaignPageType("main");
        setShowForm(false);
        setShowForm({ isNew: false, show: false });
    }

    const [open, setOpen] = useState(false);

    let dialogueCampaignHighlightStyle = useMemo(
        () => ({
            justifyContent: 'flex-start',
            borderRadius: '8px',
            width: '23.375rem',
            height: '2.8125rem',
            fontSize: '1.125rem',
            color: '#3D8BF8',
            marginLeft: '1.25rem',
            backgroundColor: '#E3ECFD',
            paddingTop: '0.4375rem',
            paddingLeft: '0.5rem',
            fontWeight: '400',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
        }),
        []
    );

    let dialogueCampaignStyle = useMemo(
        () => ({
            justifyContent: 'flex-start',
            color: '#212121',
            width: '23.375rem',
            fontSize: '1.125rem',
            paddingLeft: '0.5rem',
            fontWeight: '400',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            marginLeft: '1.25rem',
            borderRadius: '8px',
        }),
        []
    );

    const CamapignSelect = () => {
        return (
            <div>
                <div style={{ fontSize: '0.755rem' }}>
                    Campaign Type
                </div>
                <div style={{ width: '25.815rem', height: '9.9375rem', border: '1px solid #D6D6D7', marginTop: '8px', borderRadius: '8px' }}>
                    <div style={{ marginTop: '0.75rem' }}>
                    {/* //(newAuthPages(NewAdminPages.OUTBOUND_CAMP) || newAuthSubAdminAccess(NewAdminPages.OUTBOUND_CAMP, 'Edit') || newAuthPages(NewAdminPages.OUTBOUND_CAMP, 'Full')) */}
                        {/* {authPage(AdminPages.INBOUND_CAMP_EDIT) && ( */}
                        {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && (
                            <Button
                                sx={(showEditForm === 'Inbound') ? dialogueCampaignHighlightStyle : dialogueCampaignStyle}
                                onClick={dialogueCamapignSelectInbound}
                            >
                                Inbound Campaign
                            </Button>
                        )}
                        {/* {authPage(AdminPages.OUTBOUND_CAMP_EDIT) && ( */}
                        {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && (
                            <Button
                                sx={(showEditForm === 'Outbound') ? dialogueCampaignHighlightStyle : dialogueCampaignStyle}
                                onClick={dialogueCamapignSelectOutbound}
                            >
                                Outbound Campaign
                            </Button>
                        )}
                        {/* {authPage(AdminPages.MARKETING_CAMP_EDIT) && ( */}
                        {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && (
                            <Button
                                sx={(showEditForm === 'Marketing') ? dialogueCampaignHighlightStyle : dialogueCampaignStyle}
                                onClick={dialogueCamapignSelectMarketing}
                            >
                                Marketing Campaign
                            </Button>
                        )}
                    </div>
                </div>
                <div style={{ fontSize: '0.875rem', borderRadius: '8px', width: '6.5rem', height: '2.75rem', marginTop: '2rem', marginLeft: '20.4375rem' }}>
                    <Button style={{ fontWeight: '400', fontSize: '14px', color: '#FFFFFF', backgroundColor: ' #3D8BF8', borderRadius: '8px' }} variant="contained" type="submit" onClick={continueClicked}>
                        Continue
                    </Button>
                </div>
            </div >
        );
    };

    const closeCampaignSelect = (props) => {
        setOpen(false);
    }

    const vertIconMenuStyle = useMemo(
        () => ({
            "&.MuiButtonBase-root": {
                color: '#212121',
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                height: '18px',
                lineHeight: "14px",
            },
            '&:hover': {
                borderRadius: "12px",
            },
        }),
        []
    );

    const toggleStyle = useMemo(
        () => ({
            color: "#212121",
            fontFamily: "Roboto",
            fontSize: " 0.75rem",
            fontWeight: 400,
            letterSpacing: "0em",
            backgroundColor: "#F5F8FA",
            minWidth: "5.8125rem",
            height: "1.875rem",
            borderRadius: 0,
            '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: '#FFFF',
            },
        }),
        []
    );

    function getFilter() {
        if (showEditForm === 'Inbound') {
            return { column: 'campaignType', options: ['InBound', 'Chat'] }
        } else if (showEditForm === 'Marketing') {
            return { column: 'campaignType', options: ['PushData', 'MissedCall', 'SMS_OUT'] }
        }
        else if (showEditForm === 'Outbound') {
            return { column: 'campaignType', options: ['Preview', 'Progressive', 'IVR', 'Predictive'] }
        } else if (showEditForm === 'DownloadData') {
            return { column: 'campaignType', options: ['InBound', 'Chat'] }
        }
    }

    function getSearchPlaceholder() {
        if (showEditForm === 'Inbound') {
            return 'DID / Camp ID'
        }
        if (showEditForm === 'Outbound' || showEditForm === 'Restore') {
            return 'DID'
        }
        if (showEditForm === 'Marketing') {
            return 'DID / Sender ID'
        }
        if (showEditForm === 'DownloadData') {
            return 'CampaignName'
        }
    }

    const bulkActionToUpdate = (type) => {
        // console.log("type", type);
        if (type === 'InBound') {
            getCampaign();
        } else if (type === 'Outbound') {
            getCampaignOutboundList();
        }
    }

    return (
        <div className="oz-admin-Campaign-container" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            {campaignPageType === "main" && (
                <div>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                        <Typography sx={{
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '28px',
                            paddingTop: '20px'
                        }} flexGrow={1} >Campaigns</Typography>

                        <PopupState variant="popover" popupId="demo-popup-menu" >
                            {(popupState) => (
                                <React.Fragment>
                                    {/* {(authPage(AdminPages.INBOUND_CAMP_EDIT) || authPage(AdminPages.OUTBOUND_CAMP_EDIT) || authPage(AdminPages.MARKETING_CAMP_EDIT)) && showEditForm !== 'Restore' &&
                                        <Button
                                            sx={buttonStyle}
                                            variant="contained"
                                            startIcon={<AddBoxOutlinedIcon />}
                                            onClick={handleClick}
                                        >
                                            Create Campaign
                                        </Button>
                                    } */}

                                    {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && showEditForm !== 'Restore' &&
                                        <Button
                                            sx={buttonStyle}
                                            variant="contained"
                                            startIcon={<AddBoxOutlinedIcon />}
                                            onClick={handleClick}
                                        >
                                            Create Campaign
                                        </Button>
                                    }

                                    {CamSelect && open &&
                                        <OZDialog
                                            hideActions
                                            title="Which Campaign to Create?"
                                            open={open}
                                            onClose={closeCampaignSelect}
                                        >
                                            <CamapignSelect />
                                        </OZDialog>
                                    }
                                </React.Fragment>

                            )}
                        </PopupState>
                    </div>
                    <div style={{ display: "flex", height: "1.875rem", marginTop: '18px' }}>
                        <TabContext value={alignment}>
                            <TabList
                                onChange={handleChange}
                                TabIndicatorProps={{
                                    style: { backgroundColor: "#008EFF", marginBottom: "1.125rem" },
                                }}
                            >
                                <OZCustomToggleButton sx={{ ...toggleStyle, borderRight: 0 }} onClick={() => showCampaignGridList('Inbound')} value="Inbound">Inbound</OZCustomToggleButton>
                                <OZCustomToggleButton sx={{ ...toggleStyle, borderRight: 0 }} onClick={() => showCampaignGridList('Outbound')} value="Outbound">Outbound</OZCustomToggleButton>
                                <OZCustomToggleButton sx={{ ...toggleStyle, borderRight: 0 }} onClick={() => showCampaignGridList('Marketing')} value="Marketing">Marketing</OZCustomToggleButton>
                                {(localStorage.getItem('parentUser') === 'null' || newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && <OZCustomToggleButton sx={{ ...toggleStyle, borderRight: 0 }} onClick={() => showCampaignGridList('Restore')} value="Restore">Restore</OZCustomToggleButton>}
                                <OZCustomToggleButton sx={{ ...toggleStyle }} onClick={() => showCampaignGridList('DownloadData')} value="DownloadData">Download Data</OZCustomToggleButton>
                            </TabList>
                        </TabContext>
                        <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                            <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6" }} />
                        </span>
                    </div>

                    <div>
                        {showEditForm !== "Marketing" &&
                            <BulkActionCampaign list={campaignList} updateBulkActions={bulkActionToUpdate} campaignPage={showEditForm} linkMain={onLinkClickBulkAction} />
                        }
                    </div>
                </div>
            )}

            {(showForm.show && showForm.isNew && campaignPageType === "inbound") ? <CampaignInboundForm updateCampaignInbound={getCampaign} onClose={closeCampaignsForm} /> : null}
            {(showForm.show && showForm.isNew && campaignPageType === "outbound") ? <CampaignOutboundForm updateCampaignOutbound={getCampaignOutboundList} onClose={closeCampaignsForm} /> : null}
            {(showForm.show && showForm.isNew && campaignPageType === "marketing") ? <MarketingCampaignForm updateCampaignMarketing={getMarketingCampaign} onClose={closeCampaignsForm} /> : null}

            {(campaignPageType === "true") && (loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <LoadingScreen />
                    </div>
                )
                :
                (
                    <div>
                        {((showEditForm === 'Inbound') && (showForm.show && !showForm.isNew)) ? <CampaignInboundForm updateCampaignInbound={getCampaign} onClose={closeCampaignsForm} data={rowDataById} /> : null}
                        {((showEditForm === 'Outbound') && (showForm.show && !showForm.isNew)) ? <CampaignOutboundForm updateCampaignOutbound={getCampaignOutboundList} onClose={closeCampaignsForm} data={rowDataById} /> : null}
                        {((showEditForm === 'Marketing') && (showForm.show && !showForm.isNew)) ? <MarketingCampaignForm updateCampaignMarketing={getMarketingCampaign} onClose={closeCampaignsForm} data={rowDataById} /> : null}
                    </div>
                )
            )}

            {(campaignPageType === 'main') && (loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <LoadingScreen />
                    </div>
                )
                :
                (
                    <div>
                        <OZGridToolbar
                            title={showEditForm === 'DownloadData' ? 'CampaignData Downloaded List' : 'List of Campaigns'}
                            showManageColumns={(showEditForm === 'Restore' || showEditForm === 'DownloadData') ? false : true}
                            // searchPlaceholder={(showEditForm === 'Marketing') ? 'DID /Sender ID /Camp ID' : 'DID'}
                            searchPlaceholder={getSearchPlaceholder()}
                            rowCount={rowData.length}
                            dataGridRef={gridRef}
                            fileName='campaigns'
                            showFilters={(showEditForm === 'Restore' || showEditForm === 'DownloadData') ? false : true}
                            filtertProps={getFilter()}
                        />
                        <OZDataGrid
                            height='23.5rem'
                            columns={columnData}
                            data={rowData}
                            rowClickCallback={(showEditForm === 'Restore' || showEditForm === 'DownloadData') ? null : onClickRow}
                            setGridRef={setGridRef}
                            onRowSelected={addSelectedRowData}>
                        </OZDataGrid>
                    </div>
                ))
            }
        </div >
    );
}
export default Campaigns;
