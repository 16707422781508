import * as React from "react";
import { useState, useMemo } from "react";
import AgentIntegration from "./AgentIntegration";
import CampaingsIntegration from "./CampaingsIntegration";
import PhoneNumberIntegration from "./PhoneNumberIntegration";
import VoicemailsIntegration from "./VoicemailsIntegration";
import { Stack, Typography, Button, Grid, Divider } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import './Integration.css';
import { OZCustomToggleButton } from "../../../components/admin/common/OZCustomToggleButton";

export default function Integration(props) {

  const [tabValue, setTabValue] = useState("1");

  const handleTabChange = (event, tabIndex) => {
    setTabValue(tabIndex);
  };

  const onBtnDisconnect = () => {
    props.setZohoIntegration(false);
  };

  const toggleStyle = useMemo(
    () => ({
      color: "#212121",
      fontSize: " 0.75rem",
      fontWeight: 400,
      letterSpacing: "0em",
      backgroundColor: "#F5F8FA",
      width: "5.8125rem",
      height: "1.875rem",
      borderRadius: 0,
      paddingBottom: "5px",
      paddingTop: "5px",
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#FFFF',
      },
    }),
    []
  );

  return (
    <div className="ozAdminIntegrationContainer">
      <Grid sx={{ marginBottom: "10px" }}>
        <Typography onClick={onBtnDisconnect} className="oz-page-header"> Settings / Integrations / Zoho settings </Typography>
      </Grid>
      <Stack direction="row" sx={{ marginBottom: "24px" }}>
        <Typography className="oz-page-heading"> Zoho Settings </Typography>
      </Stack>
      <TabContext value={tabValue}>
        <div className='disconnectButtonPosition'>
          <TabList
            TabIndicatorProps={{
              sx: { backgroundColor: "#008EFF", marginBottom: "1.125rem" },
            }}
            onChange={handleTabChange}
          >
            <OZCustomToggleButton value="1" sx={{ borderRight: "0px", ...toggleStyle }} >
              Agents
            </OZCustomToggleButton>
            <OZCustomToggleButton value="2" sx={{ borderRight: "0px", ...toggleStyle }}  >
              Phone No.
            </OZCustomToggleButton>
            <OZCustomToggleButton value="3" sx={{ borderRight: "0px", ...toggleStyle }}  >
              Campaings
            </OZCustomToggleButton>
            <OZCustomToggleButton value="4" sx={{ ...toggleStyle }}  >
              Voicemails
            </OZCustomToggleButton>
          </TabList>
          <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
            <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginTop: "-19px" }} />
          </span>
          <Button className="disconnectButton" variant="text" onClick={onBtnDisconnect}>
            Disconnect
          </Button>
        </div>
        <TabPanel value="1">
          <AgentIntegration />
        </TabPanel>
        <TabPanel value="2">
          <PhoneNumberIntegration />
        </TabPanel>
        <TabPanel value="3">
          <CampaingsIntegration />
        </TabPanel>
        <TabPanel value="4">
          <VoicemailsIntegration />
        </TabPanel>
      </TabContext>
    </div>
  );
};